function clearCookie(name: string): void {
  // Set the expiration date of the cookie to the past
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
function setCookie(name: string, value: string, daysToExpire?: number): void {
  clearCookie(name);
    let cookieString = `${name}=${encodeURIComponent(value)}`;
  
    if (daysToExpire) {
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
      cookieString += `; expires=${expirationDate.toUTCString()}`;
    }
  
    document.cookie = cookieString;
  }
  function getCookie(name: string): string | null {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';');
  
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return decodeURIComponent(cookie.substring(name.length + 1));
      }
    }
  
    return null;
  }
export {
    setCookie,
    getCookie,
}