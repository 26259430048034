import { isNullishCoalesce } from 'typescript';
import * as pointshaders from './shaders/pointshaders';
// import fragmentShader from './shaders/point.frag';
// import vertexShader from './shaders/point.vert';

const d3 = require("d3");



/**
 * Initialize the data => stage, stage => [-1, 1] transformation matrices
 * @param this Embedding object
 */
export function initWebGLMatrices(config: any) {
  if(config.xScale === null || config.yScale === null || config.svgFullSize === null){
    console.log("initWebGLMatrices config is not properly set");
    return; 
  }

  // Convert the x and y scales to a matrix (applying scale is cheaper in GPU)
  let xScale = config.xScale;
  let yScale = config.yScale;
  let svgFullSize = config.svgFullSize; // {width and height}

  // return webGLMatrices

  const xDomainMid = (xScale.domain()[0] + xScale.domain()[1]) / 2;
  const yDomainMid = (yScale.domain()[0] + yScale.domain()[1]) / 2;

  const xRangeMid = (xScale.range()[0] + xScale.range()[1]) / 2;
  const yRangeMid = (yScale.range()[0] + yScale.range()[1]) / 2;

  const xMultiplier =
    (xScale.range()[1] - xScale.range()[0]) /
    (xScale.domain()[1] - xScale.domain()[0]);

  const yMultiplier =
    (yScale.range()[1] - yScale.range()[0]) /
    (yScale.domain()[1] - yScale.domain()[0]);

  // WebGL is column-major!
  // Transform from data space to stage space (same as applying this.xScale(),
  // and this.yScale())
  const dataScaleMatrix = [
    [xMultiplier, 0, -xMultiplier * xDomainMid + xRangeMid],
    [0, yMultiplier, -yMultiplier * yDomainMid + yRangeMid],
    [0, 0, 1]
  ];
  const dataScaleMatrix1D = dataScaleMatrix.flat();

  // Transforming the stage space to the normalized coordinate
  // Note we need to flip the y coordinate
  const normalizeMatrix = [
    [2 / svgFullSize.width, 0, -1],
    [0, -2 / svgFullSize.height, 1],
    [0, 0, 1]
  ];
  const normalizeMatrix1D = normalizeMatrix.flat();

  config.webGLMatrices = {
    dataScaleMatrix: dataScaleMatrix1D,
    normalizeMatrix: normalizeMatrix1D
  };
}

export function initWebGLBuffers(config: any, create_new_buffer: any = true) {
  // config should consist of 
  /*
  gridData: {totalPointSize, groupTotalPointSizes}
  promptPoints
  timeTextureMap
  groupNames
  pointRegl
  */


  // return 
  /**
   * frontPositionBuffer
   * frontTextureCoordinateBuffer
   * frontBufferPointSize
   */

  if (config.gridData === null) {
    console.log("initWebGLBuffers GridData is null.");
    return;
    // throw Error('GridData is null.');
  }
  if(config.promptPoints === null || config.pointRegl === null){
    console.log("initWebBuffers not initialized promptPoints and pointRegl");
    return;
  }

  // Get the position and color of each point
  const positions: number[][] = [];
  const textureCoords: number[][] = [];
  const pointAlphas: number[] = [];
  for (const point of config.promptPoints) {
    positions.push([point.x, point.y]);
    let point_alpha = 1;
    // textureCoords.push([0, 0]);
    if (config.groupNames && point.groupID !== undefined) {
      textureCoords.push([point.groupID / config.groupNames.length, 0]);
    } else {
      textureCoords.push([0, 0]);
    }
    // Get the texture coordinate for this point
    if (point.dragged !== undefined) {
      if(point.dragged === true){
        point_alpha = 1;
      }else{
        point_alpha = 0.8;
      }
    } else {
      point_alpha = 0.8;
    }
    pointAlphas.push(point_alpha);

  }

  let totalPointSize = config.gridData.totalPointSize;

  if(create_new_buffer){
    config.frontPositionBuffer = config.pointRegl.buffer({
      length: totalPointSize * 4 * 2,
      type: 'float',
      usage: 'dynamic'
    });
    config.frontTextureCoordinateBuffer = config.pointRegl.buffer({
      length: totalPointSize * 4 * 2,
      type: 'float',
      usage: 'dynamic'
    });
    config.frontPointAlphaBuffer = config.pointRegl.buffer({
      length: totalPointSize * 4 * 1,
      type: 'float',
      usage: 'dynamic'
    })
    config.frontBufferPointSize = config.promptPoints.length;

  }
  config.frontPositionBuffer.subdata(positions, 0);
  config.frontTextureCoordinateBuffer.subdata(textureCoords, 0);
  config.frontPointAlphaBuffer.subdata(pointAlphas, 0);
}


/**
 * Convert the current zoom transform into a matrix
 * @param zoomTransform D3 zoom transform
 * @returns 1D matrix
 */
const getZoomMatrix = (zoomTransform: d3.ZoomTransform) => {
    // Transforming the stage space based on the current zoom transform
    const zoomMatrix = [
      [zoomTransform.k, 0, zoomTransform.x],
      [0, zoomTransform.k, zoomTransform.y],
      [0, 0, 1]
    ];
    const zoomMatrix1D = zoomMatrix.flat();
    return zoomMatrix1D;
  };

/**
 * Draw a scatter plot for the UMAP.
 */
export function drawScatterPlot(config: any) {
    // config should consist of 
    /**
     * pointRegl
     * loadedPointCount
     * svgFullSize : {width, height} // the original svg size
     * layout : {
     *          scatterDotRadius,
     *          defaultPointColorInt: [0, 0, 0],
     *          secondPointColorInt: [0, 0, 255]
     * }
     * webGLMatrices : {
     *        dataScaleMatrix,
     *        normalizeMatrix
     * }
     * curZoomTransform
     * frontPositionBuffer
     * frontTextureCoordinateBuffer
     * frontBufferPointSize
     */

    // return_config should contain: 
    /**
     * curPointWidth
     */


    if (!config.webGLMatrices) {
      console.log("drawScatterPlot webGLMatrices not initialized")
      return;
    }
    if(config.pointRegl === null || config.loadedPointCount === null || config.svgFullSize === null || 
      config.layout === null || config.curZoomTransform === null || config.frontPositionBuffer === null ||
      config.frontTextureCoordinateBuffer === null || config.frontBufferPointSize === null || config.frontPointAlphaBuffer === null
      ){
        console.log("drawScatterPlot is not well initialized.");
        return;
      }
    let pointRegl = config.pointRegl;

    pointRegl.clear({
      color: [0, 0, 0, 0],
      depth: 1
    });
  
    // Adjust point width based on the number of points to draw
    let pointCount = config.loadedPointCount;

    // Logarithmic regression by fitting the following three points
    // https://keisan.casio.com/exec/system/14059930226691
    // [(6e4, 2), (3e5, 1), [1.8e6, 0.5]]
    const a = 6.71682071;
    const b = -0.437974871;
    config.curPointWidth =
      a +
      b *
        Math.log(
          config.layout.scatterDotRadius *
            (config.svgFullSize.height / 760) *
            pointCount
        );
      config.curPointWidth = Math.min(5, config.curPointWidth);
      config.curPointWidth = Math.max(0.4, config.curPointWidth);


    const alpha = 1 / (Math.log(pointCount) / Math.log(500));
  
    // Get the current zoom
    const zoomMatrix = getZoomMatrix(config.curZoomTransform);
  
    // Create a texture array (default 3x1)
    let textureArray = [
      // first point
      config.layout.defaultPointColorInt[0],
      config.layout.defaultPointColorInt[1],
      config.layout.defaultPointColorInt[2],
      255,
      // second point
      config.layout.secondPointColorInt[0],
      config.layout.secondPointColorInt[1],
      config.layout.secondPointColorInt[2],
      255,
      // third point
      255,
      255,
      255,
      0,
    ];
    let textureSize = 3;
    // Adjust texture if there are groups
  if (config.groupNames !== null) {
    textureSize = 0;
    textureArray = [];
    let showPoints = config.showPoints;
    for(let i = 0; i < config.layout.pointColors.length; i++){

      textureArray.push(config.layout.pointColors[i][0]);
      textureArray.push(config.layout.pointColors[i][1]);
      textureArray.push(config.layout.pointColors[i][2]);
      if(showPoints[i] === true){
        textureArray.push(255);

      }else{
        textureArray.push(0);

      }

      textureSize += 1;
    }

    // Add the empty pixel for the last point
    textureArray.push(255);
    textureArray.push(255);
    textureArray.push(255);
    textureArray.push(0);
    textureSize += 1;
  }
  
    // [default color, second color, transparent, empty]
    const texture = pointRegl.texture({
      width: textureSize,
      height: 1,
      data: textureArray,
      format: 'rgba'
    });
  
    // If user specifies an alpha level, we use it to override auto-alpha
    let userAlpha = -1.0;

    const drawPoints = pointRegl({
      depth: { enable: false },
      stencil: { enable: false },
      frag: pointshaders.frag,
      vert: pointshaders.vert,
  
      attributes: {
        position: {
          buffer: config.frontPositionBuffer,
          stride: 2 * 4,
          offset: 0
        },
        textureCoord: {
          buffer: config.frontTextureCoordinateBuffer,
          stride: 2 * 4,
          offset: 0
        },
        pointAlpha: {
          buffer: config.frontPointAlphaBuffer,
          stride: 1 * 4,
          offset: 0
        }
      },
  
      uniforms: {
        // Placeholder for function parameters
        pointWidth: config.curPointWidth,
        dataScaleMatrix: config.webGLMatrices.dataScaleMatrix,
        zoomMatrix: zoomMatrix,
        normalizeMatrix: config.webGLMatrices.normalizeMatrix,
        alpha: alpha,
        userAlpha: userAlpha,
        texture: texture
      },
  
      blend: {
        enable: true,
        func: {
          srcRGB: 'one',
          srcAlpha: 'one',
          dstRGB: 'one minus src alpha',
          dstAlpha: 'one minus src alpha'
        }
      },
  
      count: config.frontBufferPointSize,
      primitive: 'points'
    });
  
    drawPoints();
  }
  