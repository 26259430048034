import React from "react";
import { Select, Modal, Button, Row, Input } from 'antd';
import { get_copyConversations } from "../../../helper/callbackHook";

const Option = Select.Option;
const { TextArea } = Input;

export interface TranslationHelperProps {
  visible: any,
  onTranslationClose: any
}

export interface TranslationHelperState {
  text: any
}

export default class TranslationHelper extends React.Component<TranslationHelperProps, TranslationHelperState> {
  constructor(props: TranslationHelperProps) {
    super(props);
    this.state = {
        text: ""
    };
}
  handleCancel = () => {
    this.props.onTranslationClose();
  }

  handleGoogleTranslate = () => {
    let textToTranslate = this.state.text;
    if(textToTranslate.length >= 300){
      textToTranslate = textToTranslate.substring(0, 300);
    }
    const encodedText = encodeURIComponent(textToTranslate);
    const url = `https://translate.google.com/?text=${encodedText}`;

    window.open(url, '_blank');
  }
  updateText = () => {
    let func = get_copyConversations();
    let text = func();
    this.setState({
      text
    })
  }
  componentDidMount(): void {
    this.updateText();

  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if(prevProps.visible !== this.props.visible && this.props.visible === true){
      this.updateText();

    }
  }
  render() {
    const { visible } = this.props;

    return (
      <Modal
        title="Translation Helper"
        visible={visible}
        onCancel={this.handleCancel}
        width="30%"
        footer={[
          <Button key="ok" onClick={this.handleCancel}>OK</Button>
        ]}
      >
        <div>
          <Row>
          <Button
            type="primary"
            style={{ marginBottom: '16px' }}
            onClick={this.handleGoogleTranslate}
          >
            Translate
          </Button>
          </Row>
        <Row>
          <TextArea rows={24} maxLength={24} value={this.state.text}
             style={{
               color: 'black' // Set the font color to black
             }}/>

        </Row>

        </div>
      </Modal>
    );
  }
}