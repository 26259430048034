import * as React from 'react';
import {ExpandAltOutlined, CloseOutlined, CopyOutlined, TranslationOutlined, BookOutlined, FolderOpenOutlined} from '@ant-design/icons';
import InstanceChartContainer from '../../../container/InstanceChartContainer'
import { Select, Button, message, Tooltip } from 'antd';
import { get_closeConversations, get_expandConversations, get_copyConversations, get_getSelectedTurnInfo, get_getDatasetName } from '../../../helper/callbackHook';
import TranslationHelperContainer from '../../../container/TranslationHelperContainer';
import BookMarker from './BookMarker';
import BookMarkerContainer from '../../../container/BookMarkerContainer';
import { type_descriptions } from '../../../helper';
const { Option } = Select;


export interface InstanceViewProps {
    dialogue_id: number,
    selected_instance: any,
    changeDialogueID: any,
    promptBookmark: any,
    highlighted_query: any

}

export interface InstanceViewState {
    translation_visible: any
    bookmark_visible: any,
    selectedTypeValue: any
}

export default class InstanceView extends React.Component<InstanceViewProps, InstanceViewState> {
    constructor(props: InstanceViewProps) {
        super(props);
        this.state = {
            translation_visible: false,
            bookmark_visible: false,
            selectedTypeValue: "N/A"
        };
    }
    handleSelectChange = (value: any) => {
        this.props.changeDialogueID(value);
    };
    onExpandConversations = () => {
        let expandConversations = get_expandConversations();
        expandConversations();
    }
    onCloseConversations = () => {
        let closeConversations = get_closeConversations();
        closeConversations();
    }
    onCopyConversations = () => {
        let copyConversations = get_copyConversations();
        copyConversations();
    }
    onTranslaitonShowUp = () => {
        this.setState({
            translation_visible: true
        })
    }
    onTranslationClose = () => {
        this.setState({
            translation_visible: false
        })
    }
    onBookMarkerOpen = () => {
        this.setState({
            bookmark_visible: true
        })
    }
    onBookMarkerClose = () => {
        this.setState({
            bookmark_visible: false
        })
    }
    onBookMarkClick = () => {
        let func = get_getSelectedTurnInfo();
        let results : any = func();
        if(results && Object.keys(results).length > 0){
            let type = this.state.selectedTypeValue;
            results["type"] = type;
            let dataset_id = results["datasetID"];
            let func:any = get_getDatasetName();
            results["dataset"] = func(dataset_id);
            this.props.promptBookmark(results);
            message.info("Save Prompt Successfully!")
        }
    }
    handleTypeSelectChange = (value: any) => {
        this.setState({
            selectedTypeValue: value
        })
    }
    componentDidMount(): void {
        if(this.props.highlighted_query.hasOwnProperty("type")){
            if(this.props.highlighted_query["type"] !== this.state.selectedTypeValue){
                this.handleTypeSelectChange(this.props.highlighted_query["type"])
            }
        }
    }
    componentDidUpdate(prevProps: Readonly<InstanceViewProps>, prevState: Readonly<InstanceViewState>, snapshot?: any): void {
        if(prevProps.highlighted_query !== this.props.highlighted_query){
            if(this.props.highlighted_query.hasOwnProperty("type")){
                if(this.props.highlighted_query["type"] !== this.state.selectedTypeValue){
                    this.handleTypeSelectChange(this.props.highlighted_query["type"])
                }
            }
        }
    }
    public render() {
        let {selected_instance, dialogue_id} = this.props;
        let type_list = Object.keys(type_descriptions);
        let generate_split_float_button:any = () =>{
            return <div style={{float:'right'}}>

                Type: 
                <Select size={"small"} style={{"width": "150px"}}
                      value={this.state.selectedTypeValue}
                      onChange={this.handleTypeSelectChange}
                >
                {type_list.map((option) => (
                    <Option key={option} value={option}>
                    {option}
                    </Option>
                ))}
                </Select>
                <Tooltip title="Collect Prompts">
                    <Button onClick={this.onBookMarkClick} size={"small"} icon={<BookOutlined />} />
                </Tooltip>
                <Tooltip title="Show Prompts Collection">

                <Button onClick={this.onBookMarkerOpen} size={"small"} icon={<FolderOpenOutlined />} />
                </Tooltip>
                <Tooltip title="Expand All">
                <Button onClick={this.onExpandConversations} size={"small"} icon={<ExpandAltOutlined />} />

                </Tooltip>
                <Tooltip title="Close All">

                <Button onClick={this.onCloseConversations} size={"small"} icon={<CloseOutlined />} />
                </Tooltip>
                <Tooltip title="Translation Helper">
                <Button onClick={this.onTranslaitonShowUp} size={"small"} icon={<TranslationOutlined />}/>

                </Tooltip>
                <TranslationHelperContainer onTranslationClose={this.onTranslationClose} visible={this.state.translation_visible}/>
                <BookMarkerContainer visible={this.state.bookmark_visible} onClose={this.onBookMarkerClose}/>
            </div>
            /*
            return <div style={{float:'right'}}>
                ID: &nbsp;
                <Select size={"small"} style={{width: "200px"}} value={dialogue_id} onChange={this.handleSelectChange}>
                {selected_instance.map((option:any) => (
                    <Option key={option} value={option}>
                    {option}
                    </Option>
                ))}
                </Select>

                <Button onClick={this.onExpandConversations} size={"small"} icon={<ExpandAltOutlined />} />
                    <Button onClick={this.onCloseConversations} size={"small"} icon={<CloseOutlined />} />
                   <Button size={"small"} icon={<TranslationOutlined />}/>


            </div>
            */
        }
        return (
            <div style={{ width: "100%", height: "100%"}}>
                <div className="ViewTitle">Instance View
                <div style={{float:'right'}}>
                    
                    {generate_split_float_button()}
                    

                </div>
                </div>
                <div className="ViewBox_fix" style={{ width: "100%", height: "95%"}}>
                    <InstanceChartContainer/>
                </div>
                {/* <div className="fixed-legend">
                    <p>Legend:</p>
                    <p>Sentiment:</p>
                </div> */}
            </div>
            
        );
    }
}
