import ProjSettingsPanel from '../components/DataRuns/ClusterView/ProjSettingsPanel'
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

//import { decrement, increment } from '../actions';
import { StoreState } from '../types';
import { changeProjLayoutConfig, changeSelectedCluster } from '../actions';


// 将 reducer 中的状态插入到组件的 props 中
const mapStateToProps = (state: StoreState) => ({
})

// 将 对应action 插入到组件的 props 中
const mapDispatchToProps = (dispatch: Dispatch) => ({
    changeProjLayoutConfig : (proj_layout_config: any) => dispatch(changeProjLayoutConfig(proj_layout_config))
})

// 使用 connect 高阶组件对 Counter 进行包裹
export default connect(mapStateToProps, mapDispatchToProps)(ProjSettingsPanel);



