
import * as React from "react";
import { Select, Button, Row, Col, Tag, Tooltip } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { message } from 'antd';

import { set_initFilterList, set_initDatasetList, set_getDatasetName } from "../../../helper/callbackHook";
import { getFilterList, getFilterInfo, getDatasetList } from '../../../service/dataService';

import ConfigPanelContainer from '../../../container/ConfigPanelContainer'

const Option = Select.Option;

export interface FilterSelectorProps {
    current_filter: number | null,
    setCurrentFilter: any
    current_dataset: number | null,
    setCurrentDataset: any
}

export interface FilterSelectorState {
    filter_list: any,
    filter_instance: number | null,
    configFlag: any,
    dataset_list: any
}

export default class FilterSelector extends React.Component<FilterSelectorProps, FilterSelectorState>{
    constructor(props: FilterSelectorProps) {
        super(props);
        this.onFilterSelectorChange = this.onFilterSelectorChange.bind(this);
        this.onDatasetSelectorChange = this.onDatasetSelectorChange.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.state = {
            filter_list: [],
            filter_instance: null,
            configFlag: false,
            dataset_list: [],
        }
    }

    public componentDidMount() {
        set_initFilterList(this.updateFilterList);
        set_initDatasetList(this.updateDatasetList);
        set_getDatasetName(this.getDatasetName);
    }

    componentDidUpdate(prevProps: FilterSelectorProps) {
        if(this.props.current_filter !== prevProps.current_filter) {
            this.updateFilterList();
            this.updateFilterInfo();
        }
    }

    updateFilterList = () => {
        getFilterList().then((results:any)=>{
        if(results["success"]){
            let data = results["results"];
            this.setState({
                filter_list: data["filter_list"]
            })
        }
        }).catch((reason:any)=>{
            message.error(""+reason);
        });
    }

    updateFilterInfo = () => {
        getFilterInfo(this.props.current_filter).then((results: any) => {
            if (results["success"]) {
                let data = results["results"];
                this.setState({
                    filter_instance: data["details"]["filtered_instances_num"]
                })
            }
        }).catch((reason: any) => {
            message.error("" + reason);
        });
    }

    updateDatasetList = () => {
        getDatasetList().then((results:any)=>{
        if(results["success"]){
            let data = results["results"];
            this.setState({
                dataset_list: data
            })
        }
        }).catch((reason:any)=>{
            message.error(""+reason);
        });
    }
    
    public onFilterSelectorChange(value: number,data:any) {
        this.props.setCurrentFilter(value);
        this.props.setCurrentDataset(this.state.filter_list[data.key]["filterInfo"]["datasetID"]);

    }


    public onDatasetSelectorChange(value: number) {
        this.props.setCurrentDataset(value);
        // console.log(this.state.filter_list[0])
    }

    
    handleButtonClick = () => {
        // Toggle the modal
        this.setState({
            configFlag: !this.state.configFlag
        });
    }

    handleCloseConfigPanel = () => {
        this.setState({ configFlag: false });
    };
    
    getDatasetName = (datasetID: any) => {
        let currentDataset = this.state.dataset_list.findIndex((filter: any) => filter.datasetID === datasetID)
        let datasetName = ""
        if (this.state.dataset_list[currentDataset]){
            datasetName = this.state.dataset_list[currentDataset]["datasetName"]
        }else{
            datasetName= ""
        }
        return datasetName;
    }
    public render() {
        let disabledFilterSelector = this.state.filter_list.length <= 0;
        let disabledDatasetSelector = this.state.dataset_list.length <= 0;
        // console.log(this.state.filter_list.length)

        let currentDataset = this.state.dataset_list.findIndex((filter: any) => filter.datasetID === this.props.current_dataset)
        let datasetName = ""
        if (this.state.dataset_list[currentDataset]){
            datasetName = this.state.dataset_list[currentDataset]["datasetName"]
        }else{
            datasetName= ""
        }
        let generateDatasetInfo = () => {
            if(datasetName){
                return <div style={{ width: '100%'}}>
                        <div style={{ width: '100%', marginTop: '4px'}}>
                        Dataset:
                        </div>
                        <div style={{ width: '100%', marginTop: '4px'}}>
                        <div className={"Count"}>{datasetName}</div>
                        </div>
                </div>
            }else{
                return <div/>
            }
        }
        let generateNumInfo = () => {
            if(this.state.filter_instance === null){
                return <div/>

            }else{
                return <div style={{ width: '100%'}}>
                <div style={{ width: '100%', marginTop: '4px'}}>
                #Filtered Instances:
                </div>
                <div style={{ width: '100%', marginTop: '4px'}}>
                <div className={"Count"}>{this.state.filter_instance}</div>

                </div>
            </div>
            }


        }
        return (
            <div style={{ width: '100%'}}>
                <Row>
                    <Col span={12}>
                    <div style={{ width: '100%', marginTop:'4px', marginBottom: '4px' }}> Filter: &nbsp;  </div>
                                    
                    <Select
                        placeholder="Select a filter"
                        value={this.props.current_filter}
                        style={{ width: '85%' , marginRight:'4px'}}
                        onChange={this.onFilterSelectorChange}
                        disabled={disabledFilterSelector}
                    >
                        {this.state.filter_list.map((d:any, index:number )=>(
                            <Option value={d.filterID} key={index}>
                                {d.filterInfo.filterName}
                            </Option>
                        ))}
                    </Select>
                    <Tooltip title="Filters Configuration Panel">
                        <Button icon={<SettingOutlined />} type="default" onClick={this.handleButtonClick}></Button>
                    </Tooltip>

                    </Col>
                    <Col span={6}>
                            {generateDatasetInfo()}
                    </Col>

                    <Col span={6}>
                            {generateNumInfo()}
                    </Col>
                </Row>

                
                {/* <div style={{ width: '100%', marginTop:'4px', marginBottom: '4px' }}> Dataset: &nbsp;  </div> */}
                {/* <Select
                    placeholder="Select a dataset"
                    value={this.props.current_dataset}
                    style={{ width: '75%' , marginRight:'4px'}}
                    onChange={this.onDatasetSelectorChange}
                    disabled={disabledDatasetSelector}
                >
                    {this.state.dataset_list.map((d:any)=>(
                        <Option value={d.datasetID} key={d.datasetID}>
                            {d.datasetName}
                        </Option>
                    ))}
                </Select> */}
                <div>
                    <ConfigPanelContainer
                        visible={this.state.configFlag}
                        onClose={this.handleCloseConfigPanel}
                    />
                </div>
            </div>
        );

    }
}

