
import * as React from "react";
import { Table, Radio, Row, Select, Col, message, Spin } from 'antd';
import { getClusterInfoFromIDs_V2 } from "../../../service/dataService";
import { get_clusterinfo, set_clusterinfo } from "../../../helper/callbackHook";
import { getBackgroundColorInInstanceView, getCircleColorInInstanceView, getHighlightColor, getSimilarityColor, hexToRgbA } from '../../../helper';
import { changeDialogueID } from "../../../actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faComments, faRobot, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { gray } from "d3";
import { RenderingManager } from "./ProjectionPlaneWebGL/RenderingManager";


const cloud = require('d3-cloud');
const d3 = require("d3");

export interface IProps {
    cluster_results: any,
    selected_cluster: any,
    changeSelectedInstances: any,
    changeDialogueID: any,
    current_dataset: number | null,
    current_dialogue: number | null
}

export interface IState {
    dimensions: { width: number; height: number };
    selected_one_cluster: any;
    alldata: any;
    radioValue: any;
    maxTurn: number;
    current_sort: string;
    rankflag: boolean; // rankflag == false : rollback
    orderflag: boolean; // orderflag == true : new order
    current_info_loading: boolean
}

export default class BrushedVisPlane extends React.Component<IProps, IState>{
    private chartRef: React.RefObject<HTMLDivElement>;
    private wordcloudSvgRef: React.RefObject<SVGSVGElement>;
    private turnSvgRef: React.RefObject<SVGSVGElement>;
    private thumbnailSvgRef: React.RefObject<SVGSVGElement>;
    constructor(props: IProps) {
        super(props);
        this.chartRef = React.createRef<HTMLDivElement>();
        this.thumbnailSvgRef = React.createRef<SVGSVGElement>();
        this.wordcloudSvgRef = React.createRef<SVGSVGElement>();
        this.turnSvgRef = React.createRef<SVGSVGElement>();
        this.state = {
            dimensions: { width: 0, height: 0 },
            selected_one_cluster: -1,
            alldata: {
                "keywords": [],
                "instances_stats": [],
                "success": []
            },
            radioValue: 'all',
            maxTurn: 0,
            current_sort: "turn",
            rankflag: false,
            orderflag: true,
            current_info_loading: false
        }
        this.getData = this.getData.bind(this);
        this.onRadioChange = this.onRadioChange.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
    }
    // First, fix the table radio selection.
    componentDidMount() {
        this.updateDimensions();
        if (this.props.selected_cluster.length !== 0) {
            this.getData("all")
        }
        window.addEventListener('resize', this.updateDimensions);
        this.highlight()
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (JSON.stringify(prevProps.selected_cluster) !== JSON.stringify(this.props.selected_cluster) && this.props.selected_cluster.length !== 0) {
            this.getData(this.state.radioValue)
        }
        if (prevProps.current_dialogue !== this.props.current_dialogue) {
            this.highlight()
        }

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        if (this.chartRef.current) {
            this.setState({
                dimensions: {
                    width: this.chartRef.current.offsetWidth,
                    height: this.chartRef.current.offsetHeight
                }
            }, () => {
                // Now that state is updated, draw the graph
            });
        }
    };

    handleRadioClick = (record: any) => {
        let ids = "" + record["instances_id"] + "_" + record["pastjailbreakprompt_id"]

        let instances_id_list = [];
        if (record["instances_id"] >= 0) {
            instances_id_list.push(record["instances_id"]);

        }
        let curr_instance_id = record["instances_id"];
        this.props.changeSelectedInstances(instances_id_list);
        this.props.changeDialogueID(curr_instance_id);
        this.setState({
            selected_one_cluster: ids
        })
        // Add your logic here for handling the radio click event
    };

    getData(value: any) {
        let array = [];
        let jailbreak = [];
        if (value === "all") {
            for (let i = 0; i < this.props.selected_cluster.length; i++) {
                if (this.props.selected_cluster[i].groupID === 1 || this.props.selected_cluster[i].groupID === 0) {
                    array.push(this.props.selected_cluster[i].instances_id);
                }
                if (this.props.selected_cluster[i].groupID === 2) {
                    jailbreak.push(this.props.selected_cluster[i].pastjailbreakprompt_id);
                }
            }
        } else if (value === "attack") {
            for (let i = 0; i < this.props.selected_cluster.length; i++) {
                if (this.props.selected_cluster[i].groupID === 1) {
                    array.push(this.props.selected_cluster[i].instances_id);
                }
            }
        } else if (value === "fail") {
            for (let i = 0; i < this.props.selected_cluster.length; i++) {
                if (this.props.selected_cluster[i].groupID === 0) {
                    array.push(this.props.selected_cluster[i].instances_id);
                }
            }
        } else if (value === "past") {
            for (let i = 0; i < this.props.selected_cluster.length; i++) {
                if (this.props.selected_cluster[i].groupID === 2) {
                    jailbreak.push(this.props.selected_cluster[i].pastjailbreakprompt_id);
                }
            }
        }
        this.setState({
            current_info_loading: true
        })
        // console.log(array,jailbreak)
        getClusterInfoFromIDs_V2(array, this.props.current_dataset, jailbreak).then((results: any) => {
            if (results["success"]) {
                let data = results;

                for (let j = 0; j < data["instances_stats"].length; j++) {
                    for (let i = 0; i < this.props.selected_cluster.length; i++) {
                        if (this.props.selected_cluster[i].instances_id === data["instances_stats"][j].ID) {
                            data["instances_stats"][j].instances_text = this.props.selected_cluster[i].instances_text
                        }
                    }
                }

                this.setState({
                    alldata: data,
                    current_info_loading: false

                })

                this.drawWordCloud(data["keywords"])
                // this.drawTurn(data["instances_stats"])
                this.handleSortChange(this.state.current_sort)
                // this.drawThumbnail(data["instances_stats"])
            }else{
                this.setState({
                    current_info_loading: false
                })
            }
        }).catch((reason: any)=>{
            message.error(""+reason);
            this.setState({
                current_info_loading: false
            })    
        })
    }

    renderTurn() {
        return <svg id="turnsvg" ref={this.turnSvgRef} style={{ width: "100%" }} />
    }

    drawTurn(data: any) {
        // data.sort((a: any, b: any) => {
        //     const sumA = a.Turns;
        //     const sumB = b.Turns;
        //     return sumB - sumA; // For descending order
        // });
        const rankflag = this.state.rankflag
        const orderflag = this.state.orderflag;
        var height = 0;
        var width = 0;
        var margin = { top: 0, right: 0, bottom: 10, left: 10 };
        if (this.turnSvgRef.current) {
            const svgElement = this.turnSvgRef.current;
            var svgelement = d3.select(svgElement);
            height = svgElement.clientHeight;
            width = svgElement.clientWidth;
        } else {
            return;
        }

        svgelement.selectAll("*").remove();

        const bandwidth = 20;
        const padding_x = 30;
        const padding_y = bandwidth / 2;
        const c_r = bandwidth * 0.35;
        const padding_between = 5;

        var width_svg = width - margin.left - margin.right,
            height_svg = bandwidth * data.length - margin.top - margin.bottom + 40;

        const svg = svgelement
            .attr("width", width_svg)
            .attr("height", height_svg + margin.bottom + margin.top)
            .attr("id", "fullsvg")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        const color = d3.scaleOrdinal()
            .domain([true, false])
            .range([getCircleColorInInstanceView(1), getCircleColorInInstanceView(0)]);

        const self = this
        // data.forEach(function (d: any, i: number) {
        //     svg.append("circle")
        //         .attr("cx", margin.left + c_r / 2)
        //         .attr("cy", bandwidth * i + padding_y - padding_between/2)
        //         .attr("r", c_r)
        //         .style("fill", color(d.QueryMalicious))
        //         .attr("cursor", "pointer")
        //         .on("click", function () {
        //             self.props.changeDialogueID(d.ID);
        //         });

        // });


        // data.forEach(function (d: any, i: number) {
        //     svg.append("circle")
        //     .attr("cx", margin.left + c_r / 2 + padding_x)
        //         .attr("cy", bandwidth * i + padding_y - padding_between/2)
        //         .attr("r", c_r)
        //         .style("fill", color(d.ResponseMalicious))
        //         .attr("cursor", "pointer")
        //         .on("click", function () {
        //             self.props.changeDialogueID(d.ID);
        //         });
        // });

        let max = 0;
        for (let i = 0; i < data.length; i++) {
            if (data[i].Turns + data[i].Turns > max) {
                max = data[i].Turns + data[i].Turns
            }
        }

        max = max / 2

        this.setState({
            maxTurn: max
        })
        let small_padding_x = 5;
        let small_padding_y = 5;
        var x = d3.scaleLinear()
            .domain([0, max])
            // .range([width_svg, padding_x * 2 + c_r]);
            // .range([width_svg * 0.25, small_padding_x]);
            .range([width_svg * 0.75, width_svg * 0.5 + small_padding_x]);


        var x2 = d3.scaleLinear()
            .domain([0, max])
            // .range([width_svg, padding_x * 2 + c_r]);
            // .range([width_svg * 0.25, width_svg * 0.5 - small_padding_x]);
            .range([width_svg * 0.75, width_svg - small_padding_x]);

        if (!rankflag) {

            x = d3.scaleLinear()
            .domain([0, max])
            .range([width_svg * 0.25, small_padding_x]);
            x2 = d3.scaleLinear()
            .domain([0, max])
            // .range([width_svg, padding_x * 2 + c_r]);
            .range([width_svg * 0.25, width_svg * 0.5 - small_padding_x]);
            // .range([width_svg * 0.75, width_svg - small_padding_x]);

        }
        
        if (rankflag || orderflag) {
            // when rankflag == false, it will not be executed.
            data.forEach(function (d: any, i: number) {
                let curr_y = bandwidth * i + small_padding_y;
                svg.append("rect")
                    .attr("class", function () {
                        return ("rect" + d.ID.toString())
                    })
                    .classed("allrect", true)
                    .attr("x", x(max))
                    .attr("y", curr_y)
                    .attr("width", x(0) - x(max))
                    .attr("height", bandwidth - padding_between)
                    .attr("fill", "white")
                    .attr("stroke", "#d5d5d5")
                    .attr("stroke-width", "1px")
                    .attr("cursor", "pointer")
                    .on("click", function () {
                        self.props.changeDialogueID(d.ID);
                    });
                svg.append("rect")
                    .attr("x", x(d.Turns))
                    .attr("y", curr_y)
                    .attr("width", x(0) - x(d.QueryMaliciousTurns))
                    .attr("height", bandwidth - padding_between)
                    .attr("fill", color(true))
                    .attr("cursor", "pointer")
                    .on("click", function () {
                        self.props.changeDialogueID(d.ID);
                    });
                svg.append("rect")
                    .attr("x", x(d.Turns - d.QueryMaliciousTurns))
                    .attr("y", curr_y)
                    .attr("width", x(0) - x(d.Turns - d.QueryMaliciousTurns))
                    .attr("height", bandwidth - padding_between)
                    .attr("fill", color(false))
                    .attr("cursor", "pointer")
                    .on("click", function () {
                        self.props.changeDialogueID(d.ID);
                    });

                svg.append("rect")
                    .attr("class", function () {
                        return ("rect" + d.ID.toString())
                    })
                    .classed("allrect", true)
                    .attr("x", x2(0))
                    .attr("y", curr_y)
                    .attr("width", x2(max) - x2(0))
                    .attr("height", bandwidth - padding_between)
                    .attr("fill", "white")
                    .attr("stroke", "#d5d5d5")
                    .attr("stroke-width", "1px")
                    .attr("cursor", "pointer")
                    .on("click", function () {
                        self.props.changeDialogueID(d.ID);
                    });
                svg.append("rect")
                    .attr("x", x2(0))
                    .attr("y", curr_y)
                    .attr("width", x2(d.Turns - d.ResponseMaliciousTurns) - x2(0))
                    .attr("height", bandwidth - padding_between)
                    .attr("fill", color(false))
                    .attr("cursor", "pointer")
                    .on("click", function () {
                        self.props.changeDialogueID(d.ID);
                    });
                svg.append("rect")
                    .attr("x", x2(d.Turns - d.ResponseMaliciousTurns))
                    .attr("y", curr_y)
                    .attr("width", x2(d.ResponseMaliciousTurns) - x2(0))
                    .attr("height", bandwidth - padding_between)
                    .attr("fill", color(true))
                    .attr("cursor", "pointer")
                    .on("click", function () {
                        self.props.changeDialogueID(d.ID);
                    });

                const fontsize = 12  
                let text_x = padding_between;
                if(orderflag){
                    text_x = x2(max) + padding_between;
                }
                svg.append("text")
                    .attr("class", function () {
                        return ("text" + d.ID.toString())
                    })
                    .classed("alltext", true)
                    .attr("x", text_x)
                    .attr("y", curr_y + fontsize)
                    .attr("cursor", "pointer")
                    .text(function () {
                        for (let i = 0; i < self.props.selected_cluster.length; i++) {
                            if (self.props.selected_cluster[i].instances_id === d.ID) {
                                return self.props.selected_cluster[i].instances_text
                            }
                        }
                    })
                    .attr("font-size", fontsize)
                    .on("click", function () {
                        self.props.changeDialogueID(d.ID);
                    });
            });
        }else{
        
            data.forEach(function (d: any, i: number) {
                let curr_y = bandwidth * i + small_padding_y;
                svg.append("rect")
                    .attr("class", function () {
                        return ("rect" + d.ID.toString())
                    })
                    .classed("allrect", true)
                    .attr("x", x(max))
                    .attr("y", curr_y)
                    .attr("width", x(0) - x(max))
                    .attr("height", bandwidth - padding_between)
                    .attr("fill", "white")
                    .attr("stroke", "#d5d5d5")
                    .attr("stroke-width", "1px")
                    .attr("cursor", "pointer")
                    .on("click", function () {
                        self.props.changeDialogueID(d.ID);
                    });
                
                // response distribution 
                svg.append("rect")
                    .attr("x", x(d.Turns))
                    .attr("y", curr_y)
                    .attr("width", x(0) - x(d.ResponseMaliciousTurns))
                    .attr("height", bandwidth - padding_between)
                    .attr("fill", color(true))
                    .attr("cursor", "pointer")
                    .on("click", function () {
                        self.props.changeDialogueID(d.ID);
                    });
                svg.append("rect")
                    .attr("x", x(d.Turns - d.ResponseMaliciousTurns))
                    .attr("y", curr_y)
                    .attr("width", x(0) - x(d.Turns - d.ResponseMaliciousTurns))
                    .attr("height", bandwidth - padding_between)
                    .attr("fill", color(false))
                    .attr("cursor", "pointer")
                    .on("click", function () {
                        self.props.changeDialogueID(d.ID);
                    });

                svg.append("rect")
                    .attr("class", function () {
                        return ("rect" + d.ID.toString())
                    })
                    .classed("allrect", true)
                    .attr("x", x2(0))
                    .attr("y", curr_y)
                    .attr("width", x2(max) - x2(0))
                    .attr("height", bandwidth - padding_between)
                    .attr("fill", "white")
                    .attr("stroke", "#d5d5d5")
                    .attr("stroke-width", "1px")
                    .attr("cursor", "pointer")
                    .on("click", function () {
                        self.props.changeDialogueID(d.ID);
                    });

                // query distribution
                svg.append("rect")
                    .attr("x", x2(0))
                    .attr("y", curr_y)
                    .attr("width", x2(d.Turns - d.QueryMaliciousTurns) - x2(0))
                    .attr("height", bandwidth - padding_between)
                    .attr("fill", color(false))
                    .attr("cursor", "pointer")
                    .on("click", function () {
                        self.props.changeDialogueID(d.ID);
                    });
                svg.append("rect")
                    .attr("x", x2(d.Turns - d.QueryMaliciousTurns))
                    .attr("y", curr_y)
                    .attr("width", x2(d.QueryMaliciousTurns) - x2(0))
                    .attr("height", bandwidth - padding_between)
                    .attr("fill", color(true))
                    .attr("cursor", "pointer")
                    .on("click", function () {
                        self.props.changeDialogueID(d.ID);
                    });


                // text
                const fontsize = 12  
                svg.append("text")
                    .attr("class", function () {
                        return ("text" + d.ID.toString())
                    })
                    .classed("alltext", true)
                    .attr("x", x2(max) + padding_between)
                    .attr("y", curr_y + fontsize)
                    .attr("cursor", "pointer")
                    .text(function () {
                        for (let i = 0; i < self.props.selected_cluster.length; i++) {
                            if (self.props.selected_cluster[i].instances_id === d.ID) {
                                return self.props.selected_cluster[i].instances_text
                            }
                        }
                    })
                    .attr("font-size", fontsize)
                    .on("click", function () {
                        self.props.changeDialogueID(d.ID);
                    });
            });}

        svg.append("line")
            .attr("x1", x2(0))
            .attr("y1", 0 + margin.top)
            .attr("x2", x2(0))
            .attr("y2", bandwidth * data.length + small_padding_y)
            .attr("stroke", "black")
            .attr("stroke-width", "2px")
            .attr("cursor", "pointer")

    }

    highlight() {
        if (this.props.current_dialogue) {
            let highlight_color = getHighlightColor();
            for (let i = 0; i < this.state.alldata["instances_stats"].length; i++) {
                if (this.props.current_dialogue === this.state.alldata["instances_stats"][i].ID) {
                    d3.selectAll('.' + 'alltext')
                        .attr("fill", "black")
                    d3.selectAll('.' + 'allrect')
                        .attr("stroke", "#d5d5d5")
                    d3.selectAll('.' + 'rect' + this.props.current_dialogue.toString())
                        .attr("stroke", highlight_color)
                    d3.select('.' + 'text' + this.props.current_dialogue.toString())
                        .attr("fill", highlight_color)
                    return;
                }
            }
            d3.selectAll('.' + 'alltext')
                .attr("fill", "black")
            d3.selectAll('.' + 'allrect')
                .attr("stroke", "#d5d5d5")
        }
    }

    renderWordCloud() {
        return <svg id="wordcloudsvg" ref={this.wordcloudSvgRef} style={{ width: "100%", height: "100%" }} />
    }

    drawWordCloud(data: any) {

        let structured_data = []
        for (let i = 0; i < data.length; i++) {
            structured_data.push({
                text: data[i][0],
                value: data[i][1]
            })
        }


        var width_svg = 100,
            height_svg = 100;

        var margin = { top: 0, right: 10, bottom: 0, left: 0 };

        if (this.wordcloudSvgRef.current) {
            const svgElement = this.wordcloudSvgRef.current;
            var svgelement = d3.select(svgElement);
            const width = svgElement.clientWidth;
            const height = svgElement.clientHeight;

            width_svg = width - margin.left - margin.right;
            height_svg = height - margin.top - margin.bottom;
        } else {
            return;
        }
        svgelement.selectAll("*").remove();


        svgelement.append("rect")
            .attr('x', margin.left)
            .attr('y', margin.top)
            .attr('width', width_svg)
            .attr('height', height_svg)
            .attr("fill", "none")
            .attr("stroke", "#d5d5d5")
            .attr("stroke-width", "1px")

        const draw = (words: any) => {
            const svg = d3.select(this.wordcloudSvgRef.current)
                .attr("width", layout.size()[0])
                .attr("height", layout.size()[1]);

            const wordCloud = svg.append("g")
                .attr("transform", "translate(" + layout.size()[0] / 2 + "," + layout.size()[1] / 2 + ")")
                .selectAll("text")
                .data(words);

            wordCloud.enter()
                .append("text")
                .style("font-size", (d: any) => d.size + "px")
                .style("font-family", "sans-serif")
                .attr("text-anchor", "middle")
                .attr("transform", (d: any) => "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")")
                .attr("fill", getSimilarityColor())
                .text((d: any) => d.text);
        };

        const sizeScale = d3.scaleLinear()
            .domain([d3.min(structured_data, (d: any) => d.value), d3.max(structured_data, (d: any) => d.value)])
            .range([10, 80]);

        const layout = cloud()
            .size([width_svg, height_svg])
            .words(structured_data.map((d: any) => {
                return { text: d.text, size: sizeScale(d.value) };
            }))
            .padding(2)
            .rotate(() => 0) // can be a function for different angles
            .font("sans-serif")
            .fontSize((d: any) => d.size)
            .on("end", draw);

        layout.start();

    }

    // renderThumbnail() {
    //     return <svg ref={this.thumbnailSvgRef} style={{ width: "100%", height: "100%" }} />;
    // }

    // drawThumbnail(data: any) {
    //     data.sort((a: any, b: any) => {
    //         const sumA = a.NotMaliciousTurns + a.MaliciousTurns;
    //         const sumB = b.NotMaliciousTurns + b.MaliciousTurns;
    //         return sumB - sumA; // For descending order
    //     });

    //     let max = 0;
    //     for (let i = 0; i < data.length; i++) {
    //         if (data[i].NotMaliciousTurns + data[i].MaliciousTurns > max) {
    //             max = data[i].NotMaliciousTurns + data[i].MaliciousTurns
    //         }
    //     }

    //     let height = 0,
    //         width = 0,
    //         fixedBrushWidth = 0,
    //         fullwidth = 0;
    //     let margin = { top: 0, right: 0, bottom: 5, left: 0 };
    //     if (this.thumbnailSvgRef && this.thumbnailSvgRef.current && this.turnSvgRef && this.turnSvgRef.current ) {
    //         const svgElement = this.thumbnailSvgRef.current;
    //         var svgelement = d3.select(svgElement);
    //         fixedBrushWidth = this.thumbnailSvgRef.current.clientWidth/this.turnSvgRef.current.clientWidth * this.thumbnailSvgRef.current.clientWidth;
    //         fullwidth = this.turnSvgRef.current.clientWidth;

    //         height = svgElement.clientHeight - margin.top - margin.bottom;
    //         width = svgElement.clientWidth - margin.left - margin.right;

    //         if (svgElement.clientWidth - margin.top - margin.bottom > this.turnSvgRef.current.clientWidth){
    //             width = this.turnSvgRef.current.clientWidth - margin.left - margin.right;
    //             fixedBrushWidth = width
    //         }
    //     }

    //     svgelement.selectAll("*").remove();
    //     const svg = svgelement
    //         .append('g')
    //         .attr('transform', `translate(${margin.left},${margin.top})`);

    //     svg.append("rect")
    //         .attr('x', margin.left)
    //         .attr('y', margin.top)
    //         .attr('width', width - margin.left - margin.right)
    //         .attr('height', height - margin.top - margin.bottom)
    //         .attr("fill", "none")
    //         .attr("stroke", "#d5d5d5")
    //         .attr("stroke-width", "1px")

    //     // Define scales based on your data
    //     const bandwidth = d3.min([width / data.length, 20])
    //     // const y = d3.scaleLinear().range([height, 0]).domain([0, max]);

    //     const color = d3.scaleOrdinal()
    //         .domain([true, false])
    //         .range([getCircleColorInInstanceView(1), getCircleColorInInstanceView(0)]);

    //     // Define brush
    //     const brush = d3.brushX()
    //         .extent([[0, 0], [width, height]])
    //         .on("brush", brushed);

    //     // Draw bars (this needs to be adapted to your data)
    //     // svg.selectAll('.bar')
    //     //     .data(data)
    //     //     .enter().append('rect')
    //     //     .attr('class', 'bar')
    //     //     .attr('x', (d: any, i: any) => bandwidth * i)
    //     //     .attr('y', (d: any) => y(d.MaliciousTurns + d.NotMaliciousTurns))
    //     //     .attr('width', bandwidth)
    //     //     .attr('height', (d: any) => y(0) - y(d.MaliciousTurns))
    //     //     .attr("fill", color(true));

    //     // svg.selectAll('.bars')
    //     //     .data(data)
    //     //     .enter().append('rect')
    //     //     .attr('class', 'bar')
    //     //     .attr("x", (d: any, i: any) => bandwidth * i)
    //     //     .attr("y", (d: any) => y(d.NotMaliciousTurns))
    //     //     .attr("width", bandwidth)
    //     //     .attr("height", (d: any) => y(0) - y(d.NotMaliciousTurns))
    //     //     .attr("fill", color(false));


    // const stack = d3.stack()
    //     .keys(["NotMaliciousTurns", "MaliciousTurns"]) // 根据实际属性调整
    //     .order(d3.stackOrderNone)
    //     .offset(d3.stackOffsetNone); // 使用 wiggle 偏移来创建河流效果

    // const layers = stack(data);

    // const area = d3.area()
    //     .x((d: any, i: any) => bandwidth * i)
    //     .y0((d: any) => y(d[0]))
    //     .y1((d: any) => y(d[1]))
    //     .curve(d3.curveLinear); 

    // const y = d3.scaleLinear()
    //     .domain([0, d3.max(layers, (layer: any) => d3.max(layer, (d: any) => d[1]))])
    //     .range([height - margin.bottom - margin.top, 0]);

    //     // 绘制每个层次的路径
    //     svg.selectAll('.layer')
    //         .data(layers)
    //         .enter().append('path')
    //         .attr('class', 'layer')
    //         .attr('d', area)
    //         .attr('fill', (d:any, i:number) => getCircleColorInInstanceView(i)); 

    //     // Append brush to the SVG
    //     svg.append('g')
    //         .attr('class', 'brush')
    //         .attr("id", "mybrush")
    //         .call(brush);
    //     d3.select("#mybrush")
    //         .call(brush.move, [0, fixedBrushWidth]);

    //     // Define brushed function
    //     function brushed(event: any) {
    //         if (!event.sourceEvent) return;
    //         const fullelement = d3.select("#fullsvg")
    //         const translateRegex = /translate\(([^,]+),([^,]+)\)/;
    //         const matches = fullelement.attr("transform").match(translateRegex);

    //         const translateX = parseFloat(matches[1]);
    //         const translateY = parseFloat(matches[2]);

    //         var [x0, x1] = event.selection;
    //         var center = (x0 + x1) / 2;
    //         var newSelection = [
    //             center - fixedBrushWidth / 2,
    //             center + fixedBrushWidth / 2
    //         ];

    //         var ratio = x0/width;
    //         var newtranslate = fullwidth * ratio
    //         fullelement
    //             .attr("transform", "translate(" + ( 0 - newtranslate) + "," + translateY+ ")");

    //         if (event.selection) {
    //             const currentBrushWidth = x1 - x0;
    //             if (currentBrushWidth !== fixedBrushWidth) {
    //                 center = (x0 + x1) / 2;
    //                 if ((center + fixedBrushWidth/2) >=  width){
    //                     newSelection = [
    //                         width - fixedBrushWidth,
    //                         width
    //                     ];
    //                 }else if ((center - fixedBrushWidth/2) < 0){
    //                     newSelection = [
    //                         0,
    //                         fixedBrushWidth
    //                     ];
    //                 }else{
    //                     newSelection = [
    //                         center - fixedBrushWidth/2,
    //                         center + fixedBrushWidth/2
    //                     ];
    //                 }
    //                 d3.select("#mybrush").call(brush.move, newSelection);
    //             }
    //         }
    //     }
    // }

    public onRadioChange(e: any) {
        this.getData(e.target.value)
        this.setState({ radioValue: e.target.value });
    }

    handleSortChange(e: any) {
        this.setState({ current_sort: e });

        var newdata = []
        const olddata = this.state.alldata["instances_stats"]

        console.log("sorting..", olddata)

        if (e === "prefix") {
            newdata = [...olddata].sort((a, b) => {
                if (a.instances_text < b.instances_text) return -1;
                if (a.instances_text > b.instances_text) return 1;

                if (a.Turns > b.Turns) return -1;
                if (a.Turns < b.Turns) return 1;

                if (a.MaliciousResponseTurns > b.MaliciousResponseTurns) return -1;
                if (a.MaliciousResponseTurns < b.MaliciousResponseTurns) return 1;

                if (a.QueryMaliciousTurns > b.QueryMaliciousTurns) return -1;
                if (a.QueryMaliciousTurns < b.QueryMaliciousTurns) return 1;

                return 0;
            });
        } else {
            newdata = [...olddata].sort((a, b) => {
                if (a.Turns > b.Turns) return -1;
                if (a.Turns < b.Turns) return 1;

                if (a.MaliciousResponseTurns > b.MaliciousResponseTurns) return -1;
                if (a.MaliciousResponseTurns < b.MaliciousResponseTurns) return 1;

                if (a.QueryMaliciousTurns > b.QueryMaliciousTurns) return -1;
                if (a.QueryMaliciousTurns < b.QueryMaliciousTurns) return 1;

                return 0;
            });
        }
        this.drawTurn(newdata)
    }

    public render() {
        let attackNumber = 0;
        let failNumber = 0;
        let pastNumber = 0;
        let allnumber = this.props.selected_cluster.length;

        let showflag = false;
        if (this.props.selected_cluster.length !== 0) {
            showflag = true
        }

        for (let i = 0; i < this.props.selected_cluster.length; i++) {
            if (this.props.selected_cluster[i].groupID === 1) {
                attackNumber = attackNumber + 1
            }
            if (this.props.selected_cluster[i].groupID === 0) {
                failNumber = failNumber + 1
            }
            if (this.props.selected_cluster[i].groupID === 2) {
                pastNumber = pastNumber + 1
            }
        }

        let maxTurn = this.state.maxTurn
        const rankflag = this.state.rankflag
        const orderflag = this.state.orderflag
        let generateLoading = () => {
            if(this.state.current_info_loading){
                return <Spin size="small" />
            }else{
                return ""
            }
            
       }
        return (
            <div className="clusterchartdiv" style={{ width: "100%", height: "100%", borderTop: "2px dashed #ccc", overflowX: "hidden" }} ref={this.chartRef}>
                {showflag && <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center" }}>
                    <div style={{ width: "18%", height: "100%", padding: "10px", overflowY: "auto" }}>
                        <Radio.Group size={"small"} value={this.state.radioValue} onChange={this.onRadioChange}>
                            <Radio value="all">
                                All

                            </Radio>
                            <div className="Count">&nbsp; &nbsp; {allnumber}</div>

                            <Radio value="fail">
                                Attack Fail

                            </Radio>
                            <div className="Count">&nbsp; &nbsp; {failNumber}</div>


                            <Radio value="attack">
                                Attack Success

                            </Radio>
                            <div className="Count">&nbsp; &nbsp; {attackNumber}</div>

                            <Radio value="past">
                                Past Jailbreak Prompts

                            </Radio>
                            <div className="Count">&nbsp; &nbsp; {pastNumber}</div>

                        </Radio.Group>
                    </div>
                    <div style={{ width: "32%", height: "95%" }}>
                        {this.renderWordCloud()}
                    </div>
                    <div style={{ width: "50%", height: "100%", borderLeft: "2px dashed #ccc" }}>
                        {rankflag && (<div style={{ width: "100%", height: "5%", display: "flex", marginBottom: '10px', marginLeft: '10px', alignItems: 'center' }}>
                            {/** when rankflag == false, it will not display. */}
                            <div style={{ width: "50%", height: "100%", display: "flex", marginTop: "10px", alignItems: 'center', justifyContent: 'center', fontSize: "12px", fontWeight: "600" }}>
                                Sorted by:
                                <Select size={"small"} className="sortselector brushedinfoselector" value={this.state.current_sort} onChange={this.handleSortChange} style={{ marginLeft: "5px" }}>
                                    <option value="turn">Turns</option>
                                    <option value="prefix">Prefix</option>
                                </Select>
                            </div>
                            <div style={{ width: "50%", height: "100%", display: "flex", alignItems: 'flex-end' }}>
                                <div style={{ width: "20%" }}> {maxTurn} </div>
                                <FontAwesomeIcon icon={faUser} style={{ width: "20%" }} />
                                <div style={{ width: "20%", display: 'flex', justifyContent: 'center' }}> 0 </div>
                                <FontAwesomeIcon icon={faRobot} style={{ width: "20%" }} />
                                <div style={{ width: "20%", display: 'flex', justifyContent: 'flex-end' }}> {maxTurn} </div>
                            </div>
                        </div>)}
                        {!rankflag && (<div style={{ width: "100%", height: "5%", display: "flex", marginBottom: '10px', marginLeft: '10px', alignItems: 'center' }}>
                            {orderflag && <div style={{ width: "50%", height: "100%", display: "flex", alignItems: 'flex-end' }}>
                                <div style={{ width: "20%" }}> {maxTurn} </div>
                                <FontAwesomeIcon icon={faUser} style={{ width: "20%" }} />
                                <div style={{ width: "20%", display: 'flex', justifyContent: 'center' }}> 0 </div>
                                <FontAwesomeIcon icon={faRobot} style={{ width: "20%" }} />
                                <div style={{ width: "20%", display: 'flex', justifyContent: 'flex-end' }}> {maxTurn} </div>
                            </div>}
                            
                            {!orderflag && <div style={{ width: "50%", height: "100%", display: "flex", alignItems: 'flex-end' }}>
                                <div style={{ width: "20%" }}> {maxTurn} </div>
                                <FontAwesomeIcon icon={faRobot} style={{ width: "20%" }} />
                                <div style={{ width: "20%", display: 'flex', justifyContent: 'center' }}> 0 </div>
                                <FontAwesomeIcon icon={faUser} style={{ width: "20%" }} />
                                <div style={{ width: "20%", display: 'flex', justifyContent: 'flex-end' }}> {maxTurn} </div>
                            </div>}
                            <div style={{ width: "50%", height: "100%", display: "flex", marginTop: "10px", alignItems: 'center', justifyContent: 'center', fontSize: "12px", fontWeight: "600" }}>
                                Sorted by:
                                <Select size={"small"} className="sortselector brushedinfoselector" value={this.state.current_sort} onChange={this.handleSortChange} style={{ marginLeft: "5px" }}>
                                    <option value="turn">Turns</option>
                                    <option value="prefix">Prefix</option>
                                </Select>
                                
                                
                            </div>

                            
                            
                        </div>)}
                        <div style={{ width: "100%", height: "80%", overflowY: "hidden", borderTop: "2px dashed #eee" }}>
                            <div style={{ width: "100%", height: "100%", overflowY: "auto", overflowX: "hidden" }}>
                                {this.renderTurn()}
                            </div>
                            {/* <div style={{ width: "100%", height: "20%" }}>
                                {this.renderThumbnail()}
                            </div> */}
                        </div>
                        <div style={{ width: "100%", height: "10%", paddingBottom: "10px", borderTop: "2px dashed #eee" }}>
                            <Row>
                                <Col span={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <div style={{ marginLeft: "10px", border: "1px solid #bbbbbb" }} >
                                        &nbsp;
                                        <span style={{ position: "relative", display: "inline-block" }}>
                                            <span
                                                style={{
                                                    width: "10px",
                                                    height: "10px",
                                                    borderRadius: "50%",
                                                    background: getCircleColorInInstanceView(0),
                                                    display: "inline-block",
                                                    marginRight: "5px",
                                                    position: "absolute",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                }}
                                            ></span>
                                            <span style={{ marginLeft: "15px" }}>Normal &nbsp;</span>
                                        </span>
                                        &nbsp;&nbsp;
                                        <span style={{ position: "relative", display: "inline-block" }}>
                                            <span
                                                style={{
                                                    width: "10px",
                                                    height: "10px",
                                                    borderRadius: "50%",
                                                    background: getCircleColorInInstanceView(1),
                                                    display: "inline-block",
                                                    marginRight: "5px",
                                                    position: "absolute",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                }}
                                            ></span>
                                            <span style={{ marginLeft: "15px" }}>Malicious</span>
                                            &nbsp;
                                        </span>
                                    </div>
                                </Col>
                                <Col span={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {generateLoading()}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}

