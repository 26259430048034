import ChatGPTPanel from '../components/DataRuns/ChatGPTPanel'
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { StoreState } from '../types';


const mapStateToProps = (state: StoreState) => ({
    dialogue_id: state.dialogue_id
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
})


export default connect(mapStateToProps, mapDispatchToProps)(ChatGPTPanel);



