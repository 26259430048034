import * as React from "react";
import { Select, Button, Modal, Input, Checkbox, Slider, InputNumber, message, Row, Col, Spin , Tooltip } from 'antd';
import {
  SettingOutlined,
  GatewayOutlined
   } from '@ant-design/icons';


const Option = Select.Option;

export interface ProjSettingsPanelProps {
    visible: any,
    onClose: any,
    changeProjLayoutConfig: any,
    brush_mode: any, 
    onBrushButtonClick: any, 
    onSettingsButtonClick: any,
    current_projection_loading: any
}

export interface ProjSettingsPanelState {
    showGrid: any,
    gridValue: number,
    checkedPoints: any,
    checkedContours: any,
    showLegend: any
}

export default class ProjSettingsPanel extends React.Component<ProjSettingsPanelProps, ProjSettingsPanelState>{
    public default_checked_values : any;
    constructor(props: ProjSettingsPanelProps) {
        super(props);
        this.default_checked_values = ["attackFail", "attackSuccess", "pastJailbreakPrompts"]

        this.state = {
            showGrid: true,
            gridValue: 20,
            checkedPoints: this.default_checked_values,
            checkedContours: this.default_checked_values,
            showLegend: true
        }
    }
    handleShowGridChange = (e: any) => {
        console.log("handleShowGridChange", e);
        const { checked } = e.target;
        this.setState({ showGrid: checked});
      };
    
    handleGridValueChange = (value: any) => {
        this.setState({ gridValue: value });
    };
    handlePointsChange = (checkedValues: any) => {
        this.setState({ checkedPoints: checkedValues });
    };

    handleContoursChange = (checkedValues: any) => {
        this.setState({ checkedContours: checkedValues });
    };
    handleShowLegendChange = (e: any) => {
        const { checked } = e.target;
        this.setState({ showLegend: checked });
      };
    getProjLayoutConfig() {
        let layout_config = {...this.state};
        return layout_config
    }
    public componentDidMount() {
        // console.log("projSettings componentDidMount", this.getProjLayoutConfig());
        this.props.changeProjLayoutConfig(this.getProjLayoutConfig());
    }
    public componentDidUpdate(prevProps: any, prevState: any) {
        if(prevState !== this.state){
            this.props.changeProjLayoutConfig(this.getProjLayoutConfig());
        }        
    }
    handleCancel = () =>{
        this.props.onClose();
    }

    handleSave = () => {
        // Handle save action here
        // this.props.onClose();

    };
    public render() {
        const { showGrid, gridValue, checkedPoints, checkedContours, showLegend } = this.state;
        let {brush_mode, onBrushButtonClick, onSettingsButtonClick} = this.props;
        let generateLoading = () => {
          if(this.props.current_projection_loading){
              return <Spin size="small" />
          }else{
              return ""
          }
          
     }
        let generateControls = () => {
          let brush_button_type : any= "default";
          if(brush_mode === 1){
              brush_button_type = "primary";
          }
          return <div style={{float: "right"}}>
                  {generateLoading()}
                  &nbsp;
                  <Tooltip title="Brush">
                  <Button onClick={onBrushButtonClick} size={"small"} icon={<GatewayOutlined />} type={brush_button_type}/>
                    </Tooltip>

                  &nbsp;
                  <Tooltip title="Projection Settings">

                  <Button onClick={onSettingsButtonClick} size={"small"} icon={<SettingOutlined />} />
                  </Tooltip>
          </div>
     }
     let generateModal = () =>{
      return (
        <Modal
            title="Projection Settings" 
            visible={this.props.visible}
            onCancel={this.handleCancel}
            width="30%"
            footer={[
               
              ]}>

            <div>
                  <h3>Points:</h3>
                  <Checkbox.Group value={checkedPoints} onChange={this.handlePointsChange}>
                    <Checkbox value="attackFail">Attack Fail</Checkbox>
                    <Checkbox value="attackSuccess">Attack Success</Checkbox>
                    <Checkbox value="pastJailbreakPrompts">Past Jailbreak Prompts</Checkbox>
                  </Checkbox.Group>
                </div>
                <div>
                  <h3>Contours:</h3>
                  <Checkbox.Group value={checkedContours} onChange={this.handleContoursChange}>
                    <Checkbox value="attackFail">Attack Fail</Checkbox>
                    <Checkbox value="attackSuccess">Attack Success</Checkbox>
                    <Checkbox value="pastJailbreakPrompts">Past Jailbreak Prompts</Checkbox>
                  </Checkbox.Group>
                </div>

                <div>
                  <h3>Grid:</h3>
                  <Checkbox checked={showGrid} onChange={this.handleShowGridChange}>
                    Show Grid
                  </Checkbox>
                  <br />
                  <Row>
                    <Col span={18}>
                      <Slider
                        min={1}
                        max={30}
                        disabled={!showGrid}
                        value={gridValue}
                        onChange={this.handleGridValueChange}
                      />
                    </Col>
                    <Col span={6}>
                      <InputNumber
                        min={1}
                        max={30}
                        disabled={!showGrid}
                        value={gridValue}
                        onChange={this.handleGridValueChange}
                      />
                    </Col>
                  </Row>
                </div>
                <div>
                  <h3>Show Legend:</h3>
                  <Checkbox checked={showLegend} onChange={this.handleShowLegendChange}>
                    Show Legend
                  </Checkbox>
                </div>
                
              </Modal>
                );
          }

          return [generateControls(), generateModal()];

    }
}

/**
 *  <Button key="cancel" onClick={this.handleCancel}>
                      Cancel
                    </Button>,
                    <Button key="save" type="primary" onClick={this.handleSave}>
                      Save
                    </Button>,
 */