import * as React from 'react';
import { message, Collapse, Tag, Flex, Row, Col } from 'antd';
import { getInstanceInfo, getInstanceAuxInfo } from "../../../service/dataService";
import Chat, { Bubble } from '@chatui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faComments, faRobot, faLanguage, faFile } from '@fortawesome/free-solid-svg-icons';
import { getBackgroundColorInInstanceView, getCircleColorInInstanceView, getHighlightColor, getSimilarityColor, getTagColorInInstanceView, hexToRgbA } from '../../../helper';
import { set_expandConversations, set_closeConversations, set_copyConversations, set_getSelectedTurnInfo, set_updateCurrentTurn } from '../../../helper/callbackHook';
import { renderToString } from "react-dom/server";
import ReactDOM from 'react-dom';

const d3 = require("d3");
const { Panel } = Collapse;

export interface InstanceChartProps {
    dialogue_id: number,
    dataset_id: number | null,
    turn_id: number,
    setCurrentTurn: any,
    highlighted_query: any

}

interface ConversationItem {
    content?: string;
}

interface DialogueResultItem {
    content: string;
    role: string;
}


export interface InstanceChartState {
    dialogue_results: any,
    conversation_list: any,
    messages: any,
    ID: any,
    model_name: any,
    turns: any,
    language: any,
    typing: boolean,
    dimensions: { width: number; height: number },
    auxinfo: any,
    showLegends: boolean,
    activeKey: string[],
    clickedKey: string,
    selectedBubbleIndex: number,
    rankflag: boolean,
    orderflag: boolean,
    bubbleorderflag: boolean
}


class TypesManager {
    public types: { [key: string]: string[] };
    public inverted_types: { [key: string]: string };
  
    constructor() {
      this.types = {
        "sexual": ["sexual", "sexual/minors"],
        "harassment": ["harassment", "harassment/threatening"],
        "hate": ["hate", "hate/threatening"],
        "self-harm": ["self-harm", "self-harm/instructions", "self-harm/intent"],
        "violence": ["violence", "violence/graphic"],
      };
      this.inverted_types = this.initializeInvertedTypes(this.types);
    }
  
    initializeInvertedTypes(types: { [key: string]: string[] }): { [key: string]: string } {
      const invertedTypes: { [key: string]: string } = {};
      for (const key in types) {
        const typeList = types[key];
        for (const item of typeList) {
          invertedTypes[item] = key;
        }
      }
      return invertedTypes;
    }

    getFlaggedInfo(openai_moderation: any) {
        let real_categories: any= {};
        if(openai_moderation["flagged"]){

        }else{
            return [];
        }
        Object.keys(openai_moderation["categories"]).forEach((e: any)=>{
            if(openai_moderation["categories"][e]){
                let type = e;
                if(this.inverted_types.hasOwnProperty(e)){
                    type = this.inverted_types[e];
                }
                real_categories[type] = true;
            }
        })

        return Object.keys(real_categories);
      }
  }

export default class InstanceChart extends React.Component<InstanceChartProps, InstanceChartState> {
    private instanceRef: React.RefObject<HTMLDivElement>;
    private svgRef: React.RefObject<SVGSVGElement>;
    private svgSRef: React.RefObject<SVGSVGElement>;
    private types_manager: TypesManager = new TypesManager();
    constructor(props: InstanceChartProps) {
        super(props);
        this.instanceRef = React.createRef<HTMLDivElement>();
        this.svgRef = React.createRef<SVGSVGElement>();
        this.svgSRef = React.createRef<SVGSVGElement>();
        this.state = {
            dialogue_results: {},
            conversation_list: [],
            messages: [],
            ID: 0,
            model_name: "",
            turns: 0,
            language: "",
            typing: false,
            dimensions: { width: 0, height: 0 },
            auxinfo: [],
            showLegends: false,
            activeKey: [],
            clickedKey: "",
            selectedBubbleIndex: 0,
            rankflag: false,
            orderflag: true,
            bubbleorderflag: false
        }
        this.setActiveKey = this.setActiveKey.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    public componentDidMount() {
        /// Testing APIs
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);

        set_expandConversations(this.expandConversations);
        set_closeConversations(this.closeConversations);
        set_copyConversations(this.copyConversations);
        set_getSelectedTurnInfo(this.getSelectedTurnInfo);
        set_updateCurrentTurn(this.updateCurrentTurn);

    }

    updateCurrentTurn = (turn_id: any) => {
        this.handleSelectBubble(turn_id);
    }
    getSelectedTurnInfo = () => {
        let datasetID = this.props.dataset_id;
        let dialogue_id = this.props.dialogue_id;
        let turn_id = this.props.turn_id;
        if(dialogue_id >= 0 && turn_id >= 0){
            let conversation_index = turn_id * 2;
            if(conversation_index>=0 && conversation_index < this.state.conversation_list.length){
                let prompt = this.state.conversation_list[conversation_index]["content"];
                return {
                    "datasetID": datasetID,
                    "instanceID": dialogue_id,
                    "turnID": turn_id,
                    "prompt": prompt
                }
            }
        }else{
            return {};
        }
    }
    expandConversations = () => {
        console.log("conversations_list", this.state.conversation_list);
        let index_list = this.state.conversation_list.filter((d: any, i: any) => {
            if (i % 2 === 0) {
                return true;
            } else {
                return false;
            }
        }).map((d: any, i: any) => {
            return i * 2;
        })
        this.setActiveKey(index_list);
    }
    closeConversations = () => {
        this.setActiveKey([]);
    }
    public getText(messages: any) {
        let str = "";
        for (let i = 0; i < messages.length; i++) {
            let item = messages[i];
            if(i % 2 == 0){
                str += "Turn: " + (Math.floor( i / 2) + 1) + "\n";
            }
            str += "Role: " + item["role"] + "\n" + "Content: " + "\n" + item["content"] + "\n\n"
        }
        return str;
    }
    copyConversations = () => {
        let conversations_text = this.getText(this.state.conversation_list);
        return conversations_text;
        /*
        console.log("conversation_list", this.state.conversation_list);
        // Check if the Clipboard API is supported
        if (navigator.clipboard) {
            const textToCopy = conversations_text;

            // Write the text to the clipboard
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    message.info('Copy Successfully!');
                })
                .catch((error) => {
                    message.error('Error copying text to clipboard:', error);
                });
        } else {
            // Clipboard API not supported
            message.error('Clipboard API not available.');
        }*/
    }
    public componentDidUpdate(prevProps: InstanceChartProps, prevState: InstanceChartState) {
        if (prevProps.dialogue_id !== this.props.dialogue_id || prevProps.dataset_id !== this.props.dataset_id) {
            if (this.props.dialogue_id >= 0) {
                this.updateDialogueInfo();
                this.setState({
                    showLegends: true,
                    activeKey: [],
                    clickedKey: ""
                })
            }

        }

    }

    //get data
    updateDialogueInfo = () => {
        getInstanceInfo(this.props.dataset_id, this.props.dialogue_id).then((results: any) => {
            if (results["success"]) {
                let dialogue_info = results["results"];
                let conversation = dialogue_info["conversation"];
                getInstanceAuxInfo(this.props.dataset_id, this.props.dialogue_id).then((results: any) => {

                    if (results["success"]) {
                        let auxinfo = results["results"];

                        console.log("auxinfo~", auxinfo);
                        let config_info = {
                            auxinfo: auxinfo,
                            dialogue_results: dialogue_info,
                            conversation_list: conversation
                        }
                        this.messageInfo(config_info)
                    }
                });
                /*.catch((reason: any) => {
                    message.error("" + reason);
                });*/
            }
        })
        //.catch((reason: any) => {
        //    message.error("" + reason);
        //});
    }

    updateDimensions = () => {
        if (this.instanceRef.current) {
            this.setState({
                dimensions: {
                    width: this.instanceRef.current.offsetWidth,
                    height: this.instanceRef.current.offsetHeight
                }
            }, () => {
                this.drawAllInstances();
            });
        }
    };

    // collapse charts
    drawAllInstances() {
        // 绘制每个会话实例的图表
        this.state.conversation_list.forEach((item: DialogueResultItem, index: number) => {
            this.drawInstance(index);
        });
    }
    getHTMLStr(user_iconElement: any, positionX: any, width: any=13){
        // Convert the React element to an HTML string
        const iconHtmlString = renderToString(user_iconElement);

        // Create a temporary container element to hold the icon
        const tempContainer = document.createElement("div");
        tempContainer.innerHTML = iconHtmlString;

        // Get the first child of the container, which is the SVG element representing the icon
        const iconSvgElement = tempContainer.firstChild as SVGElement;
        // Set the width of the iconSvgElement
        const widthValue = width; // Replace with the desired width value
        iconSvgElement.setAttribute("width", `${widthValue}px`);
        // Set the position of the icon within the SVG element
        // const positionX =  width_svg / 2 * (1 + transfer_ratio) + 5; // Replace with the desired X position
        const positionY = 0; // Replace with the desired Y position
        iconSvgElement.setAttribute("x", positionX.toString());
        iconSvgElement.setAttribute("y", positionY.toString());
        return iconSvgElement;
    }
    public drawInstance(index: number) {
        if (index % 2 !== 0) {
            return
        }

        const rankflag = this.state.rankflag
        const orderflag = this.state.orderflag;
        const data = this.state.dialogue_results
        const auxdata = this.state.auxinfo
        const svgId = `instancesvg-${index.toString()}`;
        const divId = `instancediv-${index.toString()}`;
        // console.log("aux",auxdata)
        d3.select(`#${divId}`).selectAll("*").remove();

        d3.select(`#${divId}`).append("svg").attr("id", svgId);
        // d3.select(`#${svgId}`).selectAll("*").remove();
        // console.log('draw instance graph')

        const { width } = this.state.dimensions;
        const height = 20;

        // set the dimensions and margins of the graph
        var margin = { top: 0, right: 20, bottom: 2, left: 20 },
            width_svg = width - margin.left - margin.right,
            height_svg = height - margin.top - margin.bottom;

        // console.log(width_svg, height_svg)
        // let conversation_data = data.conversation
        let conversation_data: ConversationItem[] = Array.isArray(data.conversation) ? data.conversation : [];
        let maxLength = 0;

        conversation_data.forEach((item: ConversationItem) => {
            if (item.content) {
                maxLength = Math.max(maxLength, item.content.length);
            }
        });

        // console.log(maxLength);


        // var svg_n = d3.select(`#instancesvg-${index}`)
        //     .attr("width", width_svg + margin.left + margin.right)
        //     .attr("height", height_svg + margin.top + margin.bottom)
        //     .append("g")
        //     .attr("transform",
        //         "translate(" + margin.left + "," + margin.top + ")");

        // const padding = width_svg * 0.2

        var svg = d3.select(`#instancesvg-${index}`)
            .attr("width", width_svg + margin.left + margin.right)
            .attr("height", height_svg + margin.top + margin.bottom)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        var transfer_ratio = 0.05
        // X axis: scale and draw:

        let turn_num = index / 2 + 1
        svg.append("circle")
            .attr("cx", width_svg * transfer_ratio * 0.5)
            .attr("cy", height_svg / 2)
            .attr("r", height_svg / 2 - 1)
            // .style("fill", color(user_senti["label"]));
            .style("fill", "none")
            .attr("stroke", "gray")
            .attr("stroke-width", "1px")
            .classed("highlight" + turn_num.toString(), true)

        svg.append("text")
            .attr("x", width_svg * transfer_ratio * 0.5)
            .attr("y", height_svg / 2)
            .text(turn_num)
            .attr('text-anchor', 'middle')
            .attr('dominant-baseline', 'central')
            .style('font-size', '12px')
            .attr('fill', "gray")
            .classed("highlight" + turn_num.toString(), true)

        var x = d3.scaleLinear()
            .domain([0, maxLength])
            .range([width_svg / 2 * (1 + transfer_ratio), width_svg]);

        var x2 = d3.scaleLinear()
            .domain([0, maxLength])
            .range([width_svg / 2 * (1 + transfer_ratio), width_svg * transfer_ratio]);

        var color = d3.scaleOrdinal()
            .domain([true, false])
            .range([getCircleColorInInstanceView(1), getCircleColorInInstanceView(0)]);
            var color2 = d3.scaleOrdinal()
            .domain([true, false])
            .range([getTagColorInInstanceView(1), getTagColorInInstanceView(0)]);

        const user_data = data["conversation"][index]
        const assist_data = data["conversation"][index + 1]

        const c_r = 5;

        svg.append("line")
            .attr("x1", width_svg / 2 * (1 + transfer_ratio))
            .attr("x2", width_svg / 2 * (1 + transfer_ratio))
            .attr("y1", height_svg * 0.2)
            .attr("y2", height_svg * 0.8)
            .attr("stroke", "lightgrey")
            .attr("stroke-width", 1);

        svg.append("line")
            .attr("x1", width_svg * transfer_ratio)
            .attr("x2", width_svg)
            .attr("y1", height_svg + 2)
            .attr("y2", height_svg + 2)
            .attr("stroke", "lightgrey")
            .attr("stroke-width", 1);
        if(!data.hasOwnProperty("openai_moderation")){
            return;
        }
        let user_openai_moderation = data["openai_moderation"][index];
        let assist_openai_moderation = data["openai_moderation"][index + 1];

        let user_openai_moderation_tags = this.types_manager.getFlaggedInfo(user_openai_moderation);
        let assist_openai_moderation_tags = this.types_manager.getFlaggedInfo(assist_openai_moderation);

        let user_flagged = user_openai_moderation["flagged"];
        let assist_flagged = assist_openai_moderation["flagged"];

        let user_start_x = width_svg / 2 * (1 + transfer_ratio) + 50;
        let user_width = width_svg - user_start_x;

        let assist_start_x = width_svg * transfer_ratio + 50;
        let assist_width = width_svg / 2 * (1 + transfer_ratio) - assist_start_x;

        if (rankflag || orderflag){
            user_start_x = width_svg * transfer_ratio + 50;
            user_width = width_svg / 2 * (1 + transfer_ratio) - assist_start_x;
            assist_start_x = width_svg / 2 * (1 + transfer_ratio) + 50;
            assist_width = width_svg - user_start_x;
        }

        if(!user_flagged){
            user_openai_moderation_tags = ["normal"];
        }
        if(!assist_flagged){
            assist_openai_moderation_tags = ["normal"];
        }
        let user_data_contents = data["conversation"][index]["content"]
        let assist_data_contents = data["conversation"][index + 1]["content"]
        if(user_data_contents.length > 30){
            user_data_contents = user_data_contents.substring(0, 30) + "...";
        }
        if(assist_data_contents.length > 30){
            assist_data_contents = assist_data_contents.substring(0, 30) + "...";
        }
        let user_elements_list = user_openai_moderation_tags.map((d: any)=>{
            let mycolor = color2(user_flagged);
            return <Tag key={d} color={mycolor}>
                {d}
            </Tag>
        });
        user_elements_list.push(<span>{user_data_contents}</span>)


        let assist_elements_list = assist_openai_moderation_tags.map((d: any)=>{
            return <Tag key={d} color={color2(assist_flagged)}>
                {d}
            </Tag>
        });
        assist_elements_list.push(<span>{assist_data_contents}</span>)

        let div1 = d3.select(`#${divId}`)
        .append("div")
        .style("position", "absolute")
        .style("width", ""+user_width+"px")
        .style("height", "23px")
        .style("left", ""+user_start_x+"px")
        .style("top", "0px")
        .style("overflow", "hidden") // Apply clipping

        .html(`<div id=${divId+"-"+"userTagContainer"}></div>`);
        ReactDOM.render(user_elements_list, document.getElementById(divId+"-"+"userTagContainer"));

        
        let div2 = d3.select(`#${divId}`)
        .append("div")
        .style("position", "absolute")
        .style("width", ""+assist_width+"px")
        .style("height", "23px")
        .style("left", ""+assist_start_x+"px")
        .style("top", "0px")
        .style("overflow", "hidden") // Apply clipping

        .html(`<div id=${divId+"-"+"assistTagContainer"}></div>`);
        ReactDOM.render(assist_elements_list, document.getElementById(divId+"-"+"assistTagContainer"));


        const user_avatarIcon = faUser;
        const assist_avatarIcon = faRobot;
        const user_iconElement = <FontAwesomeIcon icon={user_avatarIcon} style={{ padding: "5px"}}/>;
        const assist_iconElement = <FontAwesomeIcon icon={assist_avatarIcon} style={{ padding: "5px"}}/>;

        let user_iconSvgElement
        let assist_iconSvgElement
        if (rankflag || orderflag){
            user_iconSvgElement = this.getHTMLStr(user_iconElement, width_svg * transfer_ratio);
            assist_iconSvgElement = this.getHTMLStr(assist_iconElement, width_svg / 2 * (1 + transfer_ratio) + 5, 18); 
        }else{
            user_iconSvgElement = this.getHTMLStr(user_iconElement, width_svg / 2 * (1 + transfer_ratio) + 5);
            assist_iconSvgElement = this.getHTMLStr(assist_iconElement, width_svg * transfer_ratio, 18); 
        }

        // Apply scale transform to the iconSvgElement

        // Select the SVG element using d3 and append the icon SVG element
        svg.node()?.appendChild(user_iconSvgElement);
        svg.node()?.appendChild(assist_iconSvgElement);


    }
    public drawInstance_backup(index: number) {
        if (index % 2 !== 0) {
            return
        }
        const data = this.state.dialogue_results
        const auxdata = this.state.auxinfo
        const svgId = `instancesvg-${index.toString()}`;
        // console.log("aux",auxdata)
        d3.select(`#${svgId}`).selectAll("*").remove();
        // console.log('draw instance graph')

        const { width } = this.state.dimensions;
        const height = 20;

        // set the dimensions and margins of the graph
        var margin = { top: 0, right: 20, bottom: 2, left: 20 },
            width_svg = width - margin.left - margin.right,
            height_svg = height - margin.top - margin.bottom;

        // console.log(width_svg, height_svg)
        // let conversation_data = data.conversation
        let conversation_data: ConversationItem[] = Array.isArray(data.conversation) ? data.conversation : [];
        let maxLength = 0;

        conversation_data.forEach((item: ConversationItem) => {
            if (item.content) {
                maxLength = Math.max(maxLength, item.content.length);
            }
        });

        // console.log(maxLength);


        // var svg_n = d3.select(`#instancesvg-${index}`)
        //     .attr("width", width_svg + margin.left + margin.right)
        //     .attr("height", height_svg + margin.top + margin.bottom)
        //     .append("g")
        //     .attr("transform",
        //         "translate(" + margin.left + "," + margin.top + ")");

        // const padding = width_svg * 0.2

        var svg = d3.select(`#instancesvg-${index}`)
            .attr("width", width_svg + margin.left + margin.right)
            .attr("height", height_svg + margin.top + margin.bottom)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        var transfer_ratio = 0.05
        // X axis: scale and draw:

        let turn_num = index / 2 + 1
        svg.append("circle")
            .attr("cx", width_svg * transfer_ratio * 0.5)
            .attr("cy", height_svg / 2)
            .attr("r", height_svg / 2 - 1)
            // .style("fill", color(user_senti["label"]));
            .style("fill", "none")
            .attr("stroke", "gray")
            .attr("stroke-width", "1px")
            .classed("highlight" + turn_num.toString(), true)

        svg.append("text")
            .attr("x", width_svg * transfer_ratio * 0.5)
            .attr("y", height_svg / 2)
            .text(turn_num)
            .attr('text-anchor', 'middle')
            .attr('dominant-baseline', 'central')
            .style('font-size', '12px')
            .attr('fill', "gray")
            .classed("highlight" + turn_num.toString(), true)

        var x = d3.scaleLinear()
            .domain([0, maxLength])
            .range([width_svg / 2 * (1 + transfer_ratio), width_svg]);

        var x2 = d3.scaleLinear()
            .domain([0, maxLength])
            .range([width_svg / 2 * (1 + transfer_ratio), width_svg * transfer_ratio]);

        // var color = d3.scaleOrdinal()
        //     .domain(["NEG", "NEU", "POS"])
        //     .range(["#2c7fb8","#ffeda0","#f03b20"]);

        var color = d3.scaleOrdinal()
            .domain([true, false])
            .range([getCircleColorInInstanceView(1), getCircleColorInInstanceView(0)]);

        const user_data = data["conversation"][index]
        const assist_data = data["conversation"][index + 1]

        // console.log("why", index, auxdata["details"]["sentiments_info"]["query"][index/2], auxdata["details"]["sentiments_info"]["response"][index/2])

        // const user_senti = auxdata["details"]["sentiments_info"]["query"][index / 2]
        // const assis_senti = auxdata["details"]["sentiments_info"]["response"][index / 2]

        const c_r = 5;

        // console.log("user conversation:", user_data.content.length, user_data)
        // console.log("assist conversation:", assist_data.content.length, assist_data)

        svg.append("line")
            .attr("x1", width_svg / 2 * (1 + transfer_ratio))
            .attr("x2", width_svg / 2 * (1 + transfer_ratio))
            .attr("y1", height_svg * 0.2)
            .attr("y2", height_svg * 0.8)
            .attr("stroke", "lightgrey")
            .attr("stroke-width", 1);

        svg.append("line")
            .attr("x1", width_svg * transfer_ratio)
            .attr("x2", width_svg)
            .attr("y1", height_svg / 2)
            .attr("y2", height_svg / 2)
            .attr("stroke", "lightgrey")
            .attr("stroke-width", 1);

        svg.append("circle")
            .attr("cx", x(user_data.content.length))
            .attr("cy", height_svg / 2)
            .attr("r", c_r)
            // .style("fill", color(user_senti["label"]));
            .style("fill", color(data["openai_moderation"][index]["flagged"]))

        svg.append("circle")
            .attr("cx", x2(assist_data.content.length))
            .attr("cy", height_svg / 2)
            .attr("r", c_r)
            .style("fill", color(data["openai_moderation"][index + 1]["flagged"]))
    }

    renderInstance(item: DialogueResultItem, index: number) {
        const svgId = `instancesvg-${index.toString()}`;
        const divId = `instancediv-${index.toString()}`;
        // console.log(index,svgId)
        // return <svg id={svgId}></svg>;
        return <div id={divId}>
        </div>
    }

    constructHighlightedText(turnID: any) {
        let highlight_query = this.props.highlighted_query;
        if (highlight_query.hasOwnProperty("turnID")) {
            if (highlight_query["turnID"] === turnID &&
                highlight_query["datasetID"] === this.props.dataset_id &&
                highlight_query["instanceID"] === this.props.dialogue_id) {
                let highlight = highlight_query["highlight"];
                let defaultColor = hexToRgbA("#ffffff", 0);

                let element_list: any[] = highlight.map((d: any) => {
                    let cutted_text = d["text"];
                    let color = defaultColor;
                    if (d["highlight"]) {
                        color = hexToRgbA(getSimilarityColor(), 0.3);
                    }

                    return (<span style={{ "background": color }}>
                        {cutted_text}
                    </span>
                    )
                });
                return {
                    "text": element_list,
                    "success": true
                }
                    ;

            }
        }
        console.log("failed ", highlight_query, turnID, highlight_query["turnID"] === turnID,
            highlight_query["datasetID"] === this.props.dataset_id, highlight_query["instanceID"] === this.props.dialogue_id);
        return {
            "text": "",
            "success": false
        };

    }

    constructResponseHighlightedText(turnID: any){
        let auxdata = this.state.auxinfo;
        if(auxdata.hasOwnProperty("details") && auxdata["details"].hasOwnProperty("malicious_response_info")
            && auxdata["details"]["malicious_response_info"].hasOwnProperty("response")){
        }else{
            return {
                "text": "",
                "success": false
            };
        }
        let response_list = auxdata["details"]["malicious_response_info"]["response"];
        if(turnID >= 0 && turnID < response_list.length){
            let highlighted_response = response_list[turnID]["highlight"];
            let defaultColor = hexToRgbA("#ffffff", 0);

            let element_list: any[] = highlighted_response.map((d: any) => {
                let cutted_text = d["text"];
                let color = defaultColor;
                if (d["highlight"]) {
                    color = hexToRgbA(getCircleColorInInstanceView(1), 0.3);
                }

                return (<span style={{ "background": color }}>
                    {cutted_text}
                </span>
                )
            });
            return {
                "text": element_list,
                "success": true
            }

        }else{
            return {
                "text": "",
                "success": false
            };
        }
    }

    // chat bubbles
    public renderMessageContent(msg: any) {
        const { message, index, position } = msg;
        // const { content } = msg;
        let role = position
        const { content } = message;
        // console.log("mymsg",index,content)
        const flagged = this.state.dialogue_results["openai_moderation"] &&
            this.state.dialogue_results["openai_moderation"][index] &&
            this.state.dialogue_results["openai_moderation"][index]["flagged"];
        let backgroundColor = ""
        if (flagged) {
            backgroundColor = getBackgroundColorInInstanceView(1); // "#FEEBDA"
        } else {
            backgroundColor = getBackgroundColorInInstanceView(0);//"#F2FAF0"
        }
        // return <Bubble content={content.text} style={{ backgroundColor, float: position }} />;
        // Define a class or style for the message container based on the role
        const rankflag = this.state.rankflag
        const orderflag = this.state.bubbleorderflag
        

        let userflag = role === "right";
        let assistantflag = role === "left";
        if(rankflag || orderflag) {
            userflag = role === "left";
            assistantflag = role === "right";

        }
        var avatarIcon = userflag ? faUser : faRobot;;
        var justify = userflag? 'flex-end' : 'flex-start';
        /*if (rankflag || orderflag){
            avatarIcon = role === 'left' ? faUser : faRobot;
            justify = role === 'assistant' ? 'flex-end' : 'flex-start';
        }else{
            avatarIcon = role === 'right' ? faUser : faRobot;
            justify = role === 'user' ? 'flex-end' : 'flex-start';
        } */

        let checked_status = this.state.selectedBubbleIndex === Math.floor(index / 2);
        let display_text = content.text;
        if (checked_status) {
            if(userflag){
                let results2 = this.constructHighlightedText(Math.floor(index / 2));
                if (results2["success"]) {
                    display_text = results2["text"];
                }
            } 

        }
        /*if(assistantflag){
            let results2 = this.constructResponseHighlightedText(Math.floor(index / 2));
            if (results2["success"]) {
                display_text = results2["text"];
            }   
        }*/


        //    console.log("checked input, ", index / 2, this.state.selectedBubbleIndex, this.state.selectedBubbleIndex === Math.round(index / 2))
        return (
            <div style={{ display: 'flex', justifyContent: justify }}>
                {role === 'left' && (
                    <div>
                        {userflag && (<input
                            type="radio"
                            name="selectedBubbleIndex"
                            value={Math.round(index / 2)}
                            onChange={() => this.handleSelectBubble(Math.round(index / 2))}
                            checked={this.state.selectedBubbleIndex === Math.round(index / 2)}
                            style={{ marginRight: "5px" }}
                        />)}
                        <FontAwesomeIcon icon={avatarIcon} style={{ padding: "5px", marginTop: "10px" }} />
                        
                    </div>
                )}

                <Bubble content={display_text} style={{ backgroundColor }} />
                {role === 'right' && (
                    <div style={{ display: 'flex', justifyContent: justify, alignItems: 'flex-start' }}>
                        {orderflag && <FontAwesomeIcon icon={avatarIcon} style={{ padding: "5px", marginTop: "10px" }} />}
                        {!orderflag &&  <FontAwesomeIcon icon={avatarIcon} style={{ padding: "5px", marginTop: "0px" }} />}
                        {userflag && (<input
                            type="radio"
                            name="selectedBubbleIndex"
                            value={Math.round(index / 2)}
                            onChange={() => this.handleSelectBubble(Math.round(index / 2))}
                            checked={this.state.selectedBubbleIndex === Math.round(index / 2)}
                            style={{ marginLeft: "5px" }}
                        />)}
                    </div>
                )}
            </div>
        );
    }

    public handleSelectBubble(index: number) {
        this.setState({
            selectedBubbleIndex: index
        });
        this.props.setCurrentTurn(index)
        console.log(this.props.turn_id)
    }

    public handleSend(type: any, val: any) {

    }

    public findExtraElement(arr1: any, arr2: any) {
        // 先将数组排序
        const sortedArr1 = arr1.slice().sort();
        const sortedArr2 = arr2.slice().sort();

        // 比较长度
        const longer = sortedArr1.length > sortedArr2.length ? sortedArr1 : sortedArr2;
        const shorter = sortedArr1.length <= sortedArr2.length ? sortedArr1 : sortedArr2;

        // 查找多余的元素
        for (let i = 0; i < longer.length; i++) {
            if (longer[i] !== shorter[i]) {
                return longer[i];
            }
        }

        // 如果所有元素都匹配，那么多余的元素是最后一个
        return longer[longer.length - 1];
    }
    onClickThumbnail(key: string) {
        if (this.state.activeKey.includes(key)) {
            // only scroll to panel
            this.setState({ clickedKey: key }, this.scrollToPanel);
        } else {
            this.setActiveKey(key);
        }
        let keyint = Math.round(parseInt(key) / 2);
        if (this.state.selectedBubbleIndex !== keyint) {
            this.handleSelectBubble(keyint);

        }
    }
    setActiveKey(key: string | string[] | null) {
        console.log("key", key)
        if (Array.isArray(key)) {
            this.setState({ 
                clickedKey: this.findExtraElement(this.state.activeKey, key),
                activeKey: key 
            }, this.scrollToPanel);
            return
        }
        if (key === null || (Array.isArray(key) && key.length === 0)) {
            this.setState({ 
                clickedKey: this.state.activeKey[0],
                activeKey: [] 
            }, this.scrollToPanel);
        } else {
            // 处理字符串或字符串数组输入
            const keyString = Array.isArray(key) ? key[key.length - 1] : key;

            this.setState({ clickedKey: key })
            this.setState(prevState => {
                // 检查当前的activeKey数组中是否已经包含了这个key。
                const keyIndex = prevState.activeKey.indexOf(keyString);

                let newActiveKey;
                if (keyIndex === -1) {
                    // 如果activeKey数组中没有这个key，那么添加它。
                    newActiveKey = [keyString, ...prevState.activeKey];
                } else {
                    // 如果已经有了这个key，那么从数组中移除它。
                    newActiveKey = prevState.activeKey.filter(k => k !== keyString);
                }

                console.log("newActiveKey", newActiveKey)
                return { activeKey: newActiveKey };
            }, this.scrollToPanel
            );
        }
    }

    scrollToPanel() {
        setTimeout(() => {
            const { clickedKey } = this.state;
            if (clickedKey === "") return;

            const activePanel = document.getElementById("instancesvg-" + clickedKey);
            const container = document.getElementById('instance');

            if (container && activePanel) {
                const containerRect = container.getBoundingClientRect();
                const activePanelRect = activePanel.getBoundingClientRect();
                const offsetTop = activePanelRect.top - containerRect.top + container.scrollTop;

                const headerOffset = 0;
                const offsetPosition = offsetTop - headerOffset;

                container.scrollTop = offsetPosition;
            }
        }, 100);
    }

    messageInfo(config_info: any) {
        let { auxinfo, dialogue_results, conversation_list } = config_info;
        // console.log("messageinfo", config_info);
        let message: any = []
        let conv_data = conversation_list
        const rankflag = this.state.rankflag
        const orderflag = this.state.bubbleorderflag
        for (let i = 0; i < conv_data.length; i++) {
            let oneconversation = conv_data[i];
            if (rankflag || orderflag){
                if (oneconversation["role"] == "assistant") {
                    message.push({
                        role: 'assistant',
                        type: 'text',
                        content: { text: oneconversation["content"] },
                        position: 'right',
                    })
                } else if (oneconversation["role"] == "user") {
                    message.push({
                        role: 'user',
                        type: 'text',
                        content: { text: oneconversation["content"] },
                        position: 'left',
                    })
                }
            }else{
                if (oneconversation["role"] == "user") {
                    message.push({
                        role: 'user',
                        type: 'text',
                        content: { text: oneconversation["content"] },
                        position: 'right',
                    })
                } else if (oneconversation["role"] == "assistant") {
                    message.push({
                        role: 'assistant',
                        type: 'text',
                        content: { text: oneconversation["content"] },
                        position: 'left',
                    })
                }
            }
        }
        let ID = dialogue_results["ID"];
        let model_name = dialogue_results["model"];
        let turn = dialogue_results["turn"];
        let language = dialogue_results["language"];
        this.setState({
            auxinfo: auxinfo,
            conversation_list: conversation_list,
            dialogue_results: dialogue_results,
            messages: message,
            ID: ID,
            model_name: model_name,
            turns: turn,
            language: language
        }, () => {
            this.drawAllInstances();
            this.handleSelectBubble(0);
        })
    }

    theMessage(index: number) {
        let data: any = []
        // data.push(this.state.messages[index])
        // data.push(this.state.messages[index+1])
        const rankflag = this.state.rankflag
        const orderflag = this.state.bubbleorderflag;
        if (rankflag || orderflag){
            data.push({ message: this.state.messages[index], index: index, position: "left" })
            data.push({ message: this.state.messages[index + 1], index: index + 1, position: "right" })
        }else{
            data.push({ message: this.state.messages[index], index: index, position: "right" })
            data.push({ message: this.state.messages[index + 1], index: index + 1, position: "left" })
        }
        return data
    }

    renderChat(item: DialogueResultItem, index: number) {
        return <Chat
            messages={this.theMessage(index)}
            renderMessageContent={(msg) => this.renderMessageContent(msg)}
            onSend={this.handleSend}
            locale={"en-US"}
        />
    }

    // static charts (left)
    renderThumnail() {
        // const svg = d3.select("thumnailsvg")
        if (this.svgRef.current) {
            this.drawIcons()
        }
        return <svg ref={this.svgRef} id="thumnailsvg" style={{ width: "40%" }} />
    }

    drawIcons() {
        var width_svg = 100,
            height_svg = 100
        var margin = { top: 0, right: 0, bottom: 0, left: 5 };
        var width_padding = 10
        const rankflag = this.state.rankflag
        const orderflag = this.state.orderflag
        if (this.svgRef.current) {
            const svgElement = this.svgRef.current;
            var svgelement = d3.select(svgElement);
            const width = svgElement.clientWidth;
            const height = svgElement.clientHeight;

            width_padding = width * 0.25

            // margin = { top: 0, right: 0, bottom: 0, left: width_padding }
            // console.log("纳尼",width * 0.1)
            width_svg = width - margin.left - margin.right;
            height_svg = height - margin.top - margin.bottom;
        }

        const dialogue_results = this.state.dialogue_results
        // const auxdata = this.state.auxinfo
        console.log("dialogue_results", dialogue_results);
        if(dialogue_results.hasOwnProperty("conversation") && dialogue_results.hasOwnProperty("openai_moderation")){

        }else{
            return ;
        }

        // if (clickedKey === "") return;

        const conversation_data = dialogue_results["conversation"]
        const flag_data = dialogue_results["openai_moderation"]
        // const similarity_data = auxdata["details"]["similarity_to_past_jailbreak_patterns_info"]

        // console.log("drawIcons", dialogue_results, auxdata, conversation_data,flag_data,similarity_data)

        svgelement.selectAll("*").remove();

        const svg = svgelement
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        // const iconratio = 0.3

        const turn_width = 0.2 * width_svg
        /*const x = d3.scaleBand()
            .domain(['turnnum', 'assistant', 'user'])
            .range([3, width_svg]);*/

        let textWidth = width_svg * (1/3);
        let circleWidth = (width_svg - textWidth) / 2;
        let textRadius = circleWidth * 0.5;
        let circleRadius = circleWidth * 0.45;
        
        var x = d3.scaleOrdinal()
            .domain(['turnnum', 'assistant', 'user'])
            .range([textWidth / 2 ,textWidth + circleWidth / 2, textWidth + circleWidth + circleWidth / 2])

        var x2 = d3.scaleBand()
        .domain(['assistant', 'user'])
        .range([0, width_svg]);

        if (rankflag){
            x = d3.scaleOrdinal()
                .domain(['user','assistant','turnnum'])
                .range([textWidth / 2 ,textWidth + circleWidth / 2, textWidth + circleWidth + circleWidth / 2])
            x2 = d3.scaleBand()
                .domain(['user','assistant'])
                .range([0, width_svg]);
        }
        if (orderflag){
            x = d3.scaleOrdinal()
                .domain(['turnnum', 'user','assistant'])
                .range([textWidth / 2 ,textWidth + circleWidth / 2, textWidth + circleWidth + circleWidth / 2])
            x2 = d3.scaleBand()
                .domain(['user','assistant'])
                .range([0, width_svg]);
        }

        const radius = x2.bandwidth() * 0.2;
        textRadius = Math.min(radius + 1, textRadius);
        circleRadius = Math.min(radius, circleRadius);
        // const radius = Math.max(textRadius, circleRadius);

        const color = d3.scaleOrdinal()
            .domain([true, false])
            .range([getCircleColorInInstanceView(1), getCircleColorInInstanceView(0)]);

        // 24 comes from the collapes header
        const padding = 24 - radius * 2
        const self = this;

        let layout_mode = 2; // Icon --> 1, Circle ---> 2
        const exclamationIconSVG = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/>
            </svg>
            `;

        const { selectedBubbleIndex } = this.state;
        let keyint = selectedBubbleIndex;
        let highlight_color = getHighlightColor();
        let text_x = x("turnnum");
        conversation_data.forEach(function (d: any, i: number) {
            let turn_num = ((i + 2) >> 1)
            let keyint2 = turn_num - 1;
            svg.append("circle")
                .attr("cx", text_x)
                .attr("cy", (turn_num * radius * 2) + padding * (i >> 1))
                .attr("r", textRadius * 1)
                .style("fill", "none")
                .attr("stroke", (keyint === keyint2) ? highlight_color: "gray")
                .attr("stroke-width", "1px")
                .classed("highlight" + turn_num.toString(), true)

            svg.append("text")
                .attr("x", text_x)
                .attr("y",(turn_num * radius * 2) + padding * (i >> 1))
                .text(turn_num)
                .attr('text-anchor', 'middle')
                .attr('dominant-baseline', 'central')
                .style('font-size', '12px')
                .attr('fill', (keyint === keyint2) ? highlight_color: "gray")
                .classed("highlight" + turn_num.toString(), true)
            if(layout_mode === 1){
                if (flag_data[i].flagged){
                        svg.append('svg')
                        .html(exclamationIconSVG)
                        .select('svg')
                        .attr('width', 2 * radius)  
                        .attr('height', 2 * radius) 
                        .attr('x', (x(d.role) + width_padding)- radius)
                        .attr('y', (((i + 2) >> 1) * radius * 2) + padding * (i >> 1) - radius)
                    }
            }
            else{
                svg.append("circle")
                .attr("cx", (x(d.role)))
                .attr("cy", (turn_num * radius * 2) + padding * (i >> 1))
                .attr("r", circleRadius)
                .attr("fill", color(flag_data[i].flagged))
                .attr("class", "turnElement")
                .on("click", function () {
                    // 点击时调用setActiveKey方法，将key设置为相应的Panel的index
                    self.onClickThumbnail(((i >> 1) * 2).toString());
                });
            }
            
        });
        if (this.svgRef.current) {
            const svgElement = this.svgRef.current;
            var svgelement = d3.select(svgElement);
            let total_length = conversation_data.length >> 1;
            let estimated_height = (total_length * radius * 2) + padding * total_length + radius;
            svgelement.attr("height", estimated_height);
        }
    }

    renderSimilarity() {

        if (this.svgSRef.current) {
            this.drawSimilarity()
        }
        return <svg ref={this.svgSRef} id="similaritysvg" style={{ width: "60%" }} />

    }

    drawSimilarity() {

        var width_svg = 0,
            height_svg = 0
        var margin = { top: 0, right: 0, bottom: 0, left: 0 };
        var width_padding = 10;


        if (this.svgRef.current) {
            const svgElement = this.svgRef.current;
            var svgelement = d3.select(svgElement);
            const width = svgElement.clientWidth;
            const height = svgElement.clientHeight;
            width_svg = width - margin.left - margin.right;
            height_svg = height - margin.top - margin.bottom;
        }

        const x = d3.scaleBand()
            .domain(['assistant', 'user'])
            .range([0, width_svg]);

        const radius = x.bandwidth() * 0.2;
        const padding = 24 - radius * 2
        const self = this;

        if (this.svgSRef.current) {
            const svgElement = this.svgSRef.current;
            var svgelement = d3.select(svgElement);
            const width = svgElement.clientWidth;
            const height = svgElement.clientHeight;

            // width_padding = width * 0.25
            margin.left = 10
            margin.right = 10
            width_svg = width - margin.left - margin.right;
            height_svg = height - margin.top - margin.bottom;
            console.log(margin.left, width_svg)
        }

        const dialogue_results = this.state.dialogue_results
        const auxdata = this.state.auxinfo
        console.log("auxdata", auxdata);
        if(dialogue_results.hasOwnProperty("conversation") && dialogue_results.hasOwnProperty("openai_moderation")){

        }else{
            return;
        }
        if(auxdata.hasOwnProperty("details") && auxdata["details"].hasOwnProperty("query_level_similarity_to_past_jailbreak_prompts_info")
            && auxdata["details"]["query_level_similarity_to_past_jailbreak_prompts_info"].hasOwnProperty("turn_level_topk_info")){
        }else{
            return;
        }
        const conversation_data = dialogue_results["conversation"]
        const flag_data = dialogue_results["openai_moderation"]
        const similarity_data = auxdata["details"]["query_level_similarity_to_past_jailbreak_prompts_info"]["turn_level_topk_info"]

        svgelement.selectAll("*").remove();

        const svg = svgelement
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");


        const x2 = d3.scaleLinear()
            .domain([0, 1])
            .range([0, width_svg]);

        similarity_data.forEach(function (d: any, i: number) {
            svg.append("rect")
                .attr("x", 0)
                .attr("y", (i * radius * 2) + padding * i + radius)
                .attr("width", x2(1))
                .attr("height", radius * 2)
                .attr("stroke", getSimilarityColor())
                .attr("stroke-width", '2px')
                .attr("fill", "white")
                .attr("class", "turnElement")
                .on("click", function () {
                    self.onClickThumbnail((i * 2).toString());
                    //console.log("干嘛啊", "." + "highlight" + (i+1).toString(), d3.selectAll("." + "highlight" + (i+1).toString()))
                    //d3.selectAll("." + "highlight" + (i+1).toString())
                    //    .attr("stroke", "blue")
                });
            svg.append("rect")
                .attr("x", 0)
                .attr("y", (i * radius * 2) + padding * i + radius)
                .attr("width", x2(d.similarity))
                .attr("height", radius * 2)
                .attr("fill", getSimilarityColor())
                .attr("class", "turnElement")
                .on("click", function () {
                    self.onClickThumbnail((i * 2).toString());
                    //console.log("干嘛啊", "." + "highlight" + (i+1).toString(), d3.selectAll("." + "highlight" + (i+1).toString()))
                    //d3.selectAll("." + "highlight" + (i+1).toString())
                    //    .attr("stroke", "blue")
                });
            svg.append("text")
                .attr("x", x2(0.5))
                .attr("y", (i * radius * 2) + padding * i + radius * 2)
                .text(d.similarity.toFixed(2))
                .attr('text-anchor', 'middle')
                .attr('dominant-baseline', 'central')
                .style('font-size', '12px')
                .attr('fill', "gray")

        });

        if (this.svgSRef.current) {
            const svgElement = this.svgSRef.current;
            var svgelement = d3.select(svgElement);
            let total_length = similarity_data.length;
            let estimated_height = (total_length * radius * 2) + padding * total_length + radius;
            svgelement.attr("height", estimated_height);
        }
    }


    public render() {
        const { conversation_list } = this.state;
        const { showLegends } = this.state;
        let generateThumbnailChart = () => {
            const rankflag = this.state.rankflag
            const orderflag = this.state.orderflag
            if (showLegends) {
                return <div className="thumbnailChart" style={{ width: "100%", height: "70%" }}>
                    {rankflag && (<div style={{ width: "100%", "height": "30px", display: "flex" }}>
                        <div style={{ width: "60%", fontSize: "14px",  display: "flex", justifyContent: "center", marginTop: "0px" }}>
                            <span>Similarity</span>
                        </div>
                        <div style={{ width: "40%", display: "flex" }}>
                            <div style={{ width: "67%", display: "flex" }}>
                                <FontAwesomeIcon icon={faUser} style={{ width: "50%" }} />
                                <FontAwesomeIcon icon={faRobot} style={{ width: "50%" }} />
                            </div>
                            <div style={{ width: "33%" }}>
                            </div>
                        </div>
                    </div>)}
                    {!rankflag && (<div style={{ width: "100%", "height": "30px", display: "flex" }}>
                        <div style={{ width: "40%", display: "flex" }}>
                            <div style={{ width: "33%" }}>
                            </div>
                            {!orderflag && (
                                <div style={{ width: "67%", display: "flex" }}>
                                <FontAwesomeIcon icon={faRobot} style={{ width: "50%" }} />
                                <FontAwesomeIcon icon={faUser} style={{ width: "50%" }} />
                            </div>
                            )}
                            {orderflag && (
                                <div style={{ width: "67%", display: "flex" }}>
                                <FontAwesomeIcon icon={faUser} style={{ width: "50%" }} />
                                <FontAwesomeIcon icon={faRobot} style={{ width: "50%" }} />
                            </div>
                            )}
                        </div>
                        <div style={{ width: "60%", fontSize: "14px",  display: "flex", justifyContent: "center", marginTop: "0px" }}>
                            <span>Similarity</span>
                        </div>
                    </div>)}
                    {!rankflag && (<div style={{ "width": "100%", "height": "calc(100% - 30px)", overflowY: "auto", borderTop: "2px dashed #eeeeee" }}>
                        {this.renderThumnail()}
                        {this.renderSimilarity()}
                    </div>)}
                    {rankflag && (<div style={{ "width": "100%", "height": "calc(100% - 30px)", overflowY: "auto", borderTop: "2px dashed #eeeeee" }}>
                        {this.renderSimilarity()}
                        {this.renderThumnail()}
                    </div>)}

                </div>
            } else {
                return <div className="thumbnailChart" style={{ width: "100%", height: "90%" }}>
                </div>
            }

        }
        let borderRight = "2px dashed #eeeeee";
        if(showLegends)
        {

        }else{
            borderRight = ""
        }
        return (
            <div style={{ width: "100%", height: "100%"}}>
                <Row style={{width: "100%", height: "100%"}}>
                <div style={{ width: "20%", height: "100%", borderRight: borderRight}}>
                    {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", height: "10%", padding: "10px" }}>
                        {showLegends && (<div style={{ flex: '0 1 0%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <FontAwesomeIcon icon={faComments} style={{ padding: "3px" }} />
                            Turns: <Tag>{this.state.turns}</Tag>
                        </div>)}
                    </div> */}
                    {generateThumbnailChart()}

                    {showLegends && (
                        <div style={{ width: "100%", height: "30%", padding: "0px", borderTop: "2px dashed #eeeeee", overflowY: "auto" }}>
                            <div style={{width: "100%", marginTop: "5px"}}>
                            <div style={{ width: "100%", fontSize: "16px"}}>
                                <FontAwesomeIcon icon={faFile}  style={{ width: "10%" }}/> ID: <Tag style={{ margin: "5px" }}>{this.props.dialogue_id}</Tag>
                            </div>
                            <div style={{ width: "100%", fontSize: "16px"}}>
                                <FontAwesomeIcon icon={faComments} style={{ width: "10%" }} /> Turns: <Tag style={{ margin: "5px" }}>{this.state.turns}</Tag>
                            </div>
                            <div style={{ width: "100%", fontSize: "16px"}}>
                                <FontAwesomeIcon icon={faUser}  style={{ width: "10%" }}/> Language: <Tag style={{ margin: "5px" }}>{this.state.language}</Tag>
                            </div>
                            <div style={{ width: "100%", fontSize: "16px"}}>
                                <FontAwesomeIcon icon={faRobot}  style={{ width: "10%" }}/> Model: <Tag style={{ margin: "5px" }}>{this.state.model_name}</Tag>
                            </div>
                            
                            </div>

                        </div>
                    )}
                </div>
                <div style={{ width: "80%", height: "100%" }}>
                    <div className="instanceCollapse" style={{ width: "100%", height: "100%" }} id='instance'>
                        {/* {showLegends && <span className="legends" style={{ width: "100%", fontSize: "18px", display: 'flex' }}>
                                <div style={{ width: "50%", textAlign: "right" }}>Response&nbsp;&nbsp;</div>
                                <div style={{ width: "50%", textAlign: "left" }}>&nbsp;&nbsp;Query</div>
                            </span>} */}
                        <div style={{ width: "100%", marginBottom: "5px" }}>&nbsp;&nbsp;</div>
                        <div style={{ width: "100%", display: 'flex', justifyContent: 'flex-start' }}>
                            <Collapse ghost style={{ width: "100%" }} activeKey={this.state.activeKey} onChange={this.setActiveKey} ref={this.instanceRef}>
                                {conversation_list.map((item: DialogueResultItem, index: number) => (
                                    index % 2 === 0 ? <Panel header={this.renderInstance(item, index)} key={index.toString()}>
                                        {this.renderChat(item, index)}
                                    </Panel> : null
                                ))}
                            </Collapse>
                        </div>
                        {/* {showLegends && <span className="legends" style={{ width: "100%", fontSize: "18px", display: 'flex' }}>
                                <div style={{ width: "40%", textAlign: "right" }}>Length&nbsp;&nbsp;</div>
                                <div style={{ width: "40%", textAlign: "center" }}>&nbsp; 0 &nbsp;</div>
                                <div style={{ width: "40%", textAlign: "left" }}>&nbsp;&nbsp;Length</div>
                            </span>} */}
                    </div>
                </div>
                </Row>

            </div>
        );
    }
}
