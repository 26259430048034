
import { render } from "@testing-library/react";
import { PointDrawer, DataInitalize, ZoomManager, BrushManager, ContourManager, TopicGridManager, HoverManager } from "./RenderingExecutorModule";
import {groupcolors, colorMap, colorMapInt} from '../../../../helper';

const d3 = require("d3");

/**
 * 
 * @returns 
 * [
        [127, 201, 127], // Attack Fail
        [253, 192, 134], // Attack Success
        [190, 174, 212]  // Past Jailbreak Prompt
      ]

      [
        "#7fc97f",
        "#fdc086",
        "#beaed4"
      ]
 */

// https://www.rapidtables.com/web/color/RGB_Color.html
function getRenderingLayoutConfig(){
    let layout: any = {
      scatterDotRadius: 1,
      defaultPointColorInt: [48, 65, 159],
      secondPointColorInt: [194, 24, 92],
      pointColors: colorMapInt,
      zoomScale: [0.5, 1000],
      contourLevels: 12,
      groupColors: [groupcolors['light-blue-800'], groupcolors['pink-900']],
      groupContourColors: colorMap

    };
    return layout;
  }
  

class RenderingConfig{
  



    // --------------- Stage 1 Variables ------------------ //
    // -------------- You need to prepare ----------------- //
    public component: any = null; // required, it is an HTMLElement
                                  // It should have embedding-svg and embedding-canvas, otherwise it will fail. 
    public gridData: any = null; // required in initWebGLBuffers {totalPointSize}
    public promptPoints: any = null; // required in initWebGLBuffers
  
    // gridData requirements:
    /**
     * xRange: [x,x]
     * yRange: [x,x]
     * padded: True / False
     * totalPointSize: total number of points
     */
  
    // promptPoints requirements:
    /**
     * [{
     * x, y, groupID (optional), time (optional)
     * }]
     */
  
  
    // --------------- Optional Variable --------------- //
    public timeTextureMap: any = null; // optional
    public groupNames: any = null; // optional
    public curZoomTransform: any = null; // by default it would be equal to d3.zoomIdentity
    public layout: any = null;    // by default return by getRenderingLayoutConfig();
    public loadedPointCount: any = null; // optional, default as 1. when initialize, set to promptPoints.length;
  
    // ---------------- Those data are managed by internal function. ------------------------- //
    // -- return by initSVGFromComponent -- //
    public svg: any = null; // return by initSVGFromComponent
    public svgPadding: any = null; // return by initSVGFromComponent
    public svgFullSize: any = null; // required {width, height} return by initSVGFromComponent
    public svgSize: any = null; // required {width, height} return by initSVGFromComponent
    public pointCanvas: any = null; // required  return by initSVGFromComponent
    public pointRegl: any = null; // required return by initSVGFromComponent
  
    // -- return by initScalesFromGridData -- //
    public xScale: any = null; // required, return by initScalesFromGridData
    public yScale: any = null; // required, return by initScalesFromGridData
  
    // -- return by initWebGLMatrices -- //
    public webGLMatrices: any = null; // return by initWebGLMatrices
  
    // -- return by initWebGLBuffers -- //
    public frontPositionBuffer: any = null; // return by initWebGLBuffers
    public frontTextureCoordinateBuffer: any = null; // return by initWebGLBuffers
    public frontBufferPointSize: any = null; // return by initWebGLBuffers
    public frontPointAlphaBuffer: any = null; // return by initWebGLBuffers

    // -- return by drawScatterPlot -- //
    public curPointWidth: any = null; // return by drawScatterPlot
  

    // ------------ Stage 2 Variables ------------------------ //

    // ---------------- Those data are managed by internal function. ------------------------- //

    // zoomScale is required in layout. in this stage.

    // -------- initTopSvg ----- ///
    public topSvg : any = null;
    
    public contentSvg: any = null;

    public brushSvg: any = null;
    public contours: any = null;
    public groupContours: any = null;


    public lastLabelNames: any = new Map();
    public lastLabelTreeLevel: any = null;
    public lastDrawnLabels: any = [];
    public contoursInitialized: any = true;
    public lastLabelLayoutTime: any = 0;
  

    public topicLevelTrees: any = null;
    public lastGridTreeLevels: number[] = [];
    
    public lastMouseClientPosition: any = null;

    public groupTimeTreeMap: any = null;
    public hoverPoint: any = null;
    public tooltipTop: any = null;

    
    ///////////////////////////////////////////////
    // User settings
    public showContours: any = [true, true, true];
    public showPoints: any = [true, true, true];
    public showLabel: any = true;
    public userMaxLabelNum: any = 20;
    public showLegend: any = true;


    public legendSvg: any = null;


    /**
     * main running process:
     * 1. initSVGFromComponent,
     * 2. initScalesFromGridData,
     * 3. initWebGLMatrices,
     * 4. initWebGLBuffers,
     * 5. drawScatterPlot.
     * 
     */
    constructor(){
      this.layout = getRenderingLayoutConfig();
      this.curZoomTransform = d3.zoomIdentity;
      this.loadedPointCount = 1;
    }
    initializeDataFromConfig(config: any){
      // Required Data Initialization
      this.component = config["component"];
      this.gridData = config["gridData"];
      this.promptPoints = config["promptPoints"];
  
      // Data Checking
      // let gridDataRequiredKeys = ["totalPointSize"]
      // let layotuRequiredKeys = ["scatterDotRadius", "defaultPointColorInt", "secondPointColorInt"];
  
      // Optional Data Initialization
      if(config.hasOwnProperty("loadedPointCount")){
        this.loadedPointCount = config["loadedPointCount"];
      }else{
        this.loadedPointCount = this.promptPoints.length;
      }
  
      if(config.hasOwnProperty("layout")){
        this.layout = config["layout"];
      }
      if(config.hasOwnProperty("timeTextureMap")){
        this.timeTextureMap = config["timeTextureMap"];
      }
      if(config.hasOwnProperty("groupNames")){
        this.groupNames = config["groupNames"];
      }
      if(config.hasOwnProperty("curZoomTransform")){
        this.curZoomTransform = config["curZoomTransform"];
      }
  
  
    }
  }
  


 class LegendManager{
  constructor(){

  }
  initLegend(config: any){
    const legendSvg = d3
      .select(config.component)
      .select('#legend-svg')
      .attr('width', `${config.svgFullSize.width}px`)
      .attr('height', `${config.svgFullSize.height}px`)
      .attr(
        'transform',
        `translate(${config.svgPadding.left}, ${config.svgPadding.top})`
      )
      .classed("hidden", !config.showLegend);
    
    console.log("initLegend", config.showLegend);
    legendSvg.selectAll("*").remove();
    const legendGroup = legendSvg.append('g').attr('class', 'legend-group')
    .attr("transform", `translate(5, ${config.svgFullSize.height - 110})`);

    // <rect x="5" y="155" width="120" height="120" fill="#ffffff" stroke="#bbbbbb"></rect>
    legendGroup
      .append('rect')
      .attr("x", 0)
      .attr("y", 0)
      .attr('width', 140)
      .attr('height', 100)
      .attr("stroke", "#bbbbbb")
      .attr("fill", "#ffffff")
      .attr("opacity", 0.8);
    
    const legendSubgroup = legendGroup.append('g');

      const legendData = [
        { color: colorMap[0], text: 'Attack Fail' },
        { color: colorMap[1], text: 'Attack Success' },
        { color: colorMap[2], text: 'Past Jailbreak Prompts' },
      ];
      const legendRows = legendSubgroup
        .selectAll('.legend-row')
        .data(legendData)
        .enter()
        .append('g')
        .attr('class', 'legend-row')
        .attr('transform', (d: any, i: any) => `translate(5, ${i * 15 + 5})`);
    
      legendRows
        .append('rect')
        .attr('width', 10)
        .attr('height', 10)
        .attr('fill', (d: any) => d.color);
    
      legendRows
        .append('text')
        .attr('x', 15)
        .attr('y', 10)
        .text((d: any) => d.text)
        .style('font-size', '12px'); // Adjust the font size here

          // Color Legend
      const colorScale = d3.scaleSequential(d3.interpolateRdYlGn)
      .domain([0, 1]); // Adjust the domain according to your data

      colorScale(0)
    const colorLegend = legendGroup.append('g')
      .attr('class', 'color-legend')
      .attr('transform', `translate(10, ${70})`);
      let colorLegendData : any= []; // Adjust the values for the color legend

      let totalWidth = 100;
      let totalDivide = 100;
      let stepWidth = totalWidth / (totalDivide + 1);
      let stepValue = 1 / totalDivide;
      for(let i = 0; i<=totalDivide; i++){
        colorLegendData.push(1 - i * stepValue)
      }
    const colorLegendRects = colorLegend.selectAll('.color-legend-rect')
      .data(colorLegendData)
      .enter()
      .append('rect')
      .attr('class', 'color-legend-rect')
      .attr('x', (d: any, i: any) => (10 + i * stepWidth))
      .attr('y', 0)
      .attr('width', stepWidth)
      .attr('height', 20)
      .attr('fill', (d: any) => colorScale(d))
      .attr('stroke', (d:any)=> colorScale(d));
      
      colorLegend.append('text')
      .attr('class', 'color-legend-text')
      .attr('x', +0)
      .attr('y', -5)
      .text('Attack Success Rate')
      .style('text-anchor', 'start');
    
      colorLegend.append('text')
      .attr('class', 'color-legend-text')
      .attr('x', 5)
      .attr('y', 15)
      .text('0')
      .style('text-anchor', 'end');

    colorLegend.append('text')
      .attr('class', 'color-legend-text')
      .attr('x', totalWidth + 10 + 5)
      .attr('y', 15)
      .text('1');
    config.legendSvg = legendSvg;

  }

 }






  class RenderingExecutor{
    public render_config: any = null;
    // Additional Functions:
    public point_drawer: any = new PointDrawer();
    public data_initalize: any = new DataInitalize();
    public topicgrid_manager: any = new TopicGridManager();

    public zoom_manager: any = new ZoomManager(this.point_drawer, this.topicgrid_manager);
    public brush_manager: any = new BrushManager(this.point_drawer);
    public contour_manager: any = new ContourManager();
    public hover_manager: any = new HoverManager();
    public legend_manager: any = new LegendManager();

    public enableZoom: any = true;
    public enableDrag: any = false;
    public initialized: any = false;
    constructor(){
        this.render_config = new RenderingConfig();

    }

    // external function
    initializeDataFromConfig(config: any){
       // Required Data Initialization
       // this.component = config["component"];
       // this.gridData = config["gridData"];
       // this.promptPoints = config["promptPoints"];

       // It will reinitialize render_config;
       this.render_config = new RenderingConfig();
       this.render_config.initializeDataFromConfig(config);
    }
    initDraggedCallback(callback: any){
      this.brush_manager.initBrushCallback(callback);
    }
    initBehavior(){
      let render_config = this.render_config;
      if(this.enableZoom){
        this.brush_manager.disableBrushOnTopSvg(render_config);
        this.zoom_manager.initZoomOnTopSvg(render_config);
        this.hover_manager.initHoverEvent(render_config);
      }
      if(this.enableDrag){
        this.zoom_manager.disableZoomOnTopSvg(render_config);
        this.hover_manager.disableHoverEvent(render_config);

        this.brush_manager.initBrushOnTopSvg(render_config);
      }
    }
    changeBrushMode(brushmode: any){
      if(brushmode === 0){
        // Zoom enabled, Brush disabled.
        this.enableZoom = true;
        this.enableDrag = false;
      }else{
        this.enableZoom = false;
        this.enableDrag = true;
      }
      if(this.initialized){
        this.initBehavior();
      }
    }
    changeShowLabel(show_label: any){
      let current_show_label = this.render_config.showLabel;
      let render_config = this.render_config;
      if(show_label !== current_show_label){
        render_config.showLabel = show_label;
        if(this.initialized){
          render_config.contentSvg
          .select('g.top-content g.topics')
          .classed('hidden', !render_config.showLabel);
          if(show_label === false){
            // disable label;
          }else{
            // show label;
            this.topicgrid_manager.layoutTopicLabels(render_config, render_config.userMaxLabelNum, false);
          }
        }
      }else{
        // nothing should do.
      }
    }
    changeLabelNum(label_num: any){
      let render_config = this.render_config;

      if(label_num !== render_config.userMaxLabelNum ){
        const newValue = label_num;
        render_config.userMaxLabelNum = newValue;
        render_config.lastLabelNames = new Map();
        this.topicgrid_manager.layoutTopicLabels(render_config, newValue);
      }

    }
    changeShowContours(show_contours: any){
      
      let render_config = this.render_config;

      render_config.showContours = show_contours;
      if(this.initialized){

        let svg = this.render_config.svg;
        for(let i = 0; i<show_contours.length; i++){
          let group = render_config.groupNames[i];
          svg
          .select(`g.contour-group-${group}`)
          .classed('hidden', !render_config.showContours[i]);
        }

        if (render_config.showLabel) {
          this.topicgrid_manager.layoutTopicLabels(render_config, render_config.userMaxLabelNum, true);
        }
      }

    }
    changeShowPoints(show_points: any){
      let render_config = this.render_config;

      render_config.showPoints = show_points;
      if(this.initialized){
        this.point_drawer.drawScatterPlot(render_config);

      }


    }
    changeShowLegend(show_legend: any){
      let render_config = this.render_config;
      render_config.showLegend = show_legend;
      if(this.initialized){
        console.log("showLegend", render_config.showLegend);
        render_config.legendSvg.classed("hidden", !render_config.showLegend);
      }
    }
    // external function
    main_execute(){
        let render_config = this.render_config;
        this.data_initalize.main_execute(render_config);
        this.contour_manager.initSVGGroups(render_config);
        this.legend_manager.initLegend(render_config);
        this.topicgrid_manager.initTopicData(render_config);
        this.hover_manager.initHoverConfig(render_config);

        this.initBehavior();

        this.point_drawer.main_execute(render_config);
        this.contour_manager.drawContour(render_config);
        this.contour_manager.initGroupContours(render_config);
        this.topicgrid_manager.layoutTopicLabels(render_config, render_config.userMaxLabelNum, false);
        this.initialized = true;
    }
  }
  
  export {
    RenderingConfig,
    RenderingExecutor
  }