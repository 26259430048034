var initMetaData = () => {
    console.log("not initialized");
}
var initFilterList = () => {
    console.log("not initialized filters");
}
var initDatasetList = () => {
    console.log("not initialized datasets");
}

var updateDialogueInfo = () => {
    console.log("not initialized");
}

var expandConversations = () => {
    console.log("not initialized");
}

var closeConversations = () => {
    console.log("not initialized");
}

var copyConversations = () => {
    console.log("not initialized");
}

var clusterinfo = () => {
    console.log("not initialized");
}

var getSelectedTurnInfo = () => {
    console.log("not initialized");
}

var updateCurrentTurn = () => {
    console.log("not initialized");
}

var getDatasetName = () => {
    console.log("not initialized");
}

function set_initMetaData(callback: any){
    initMetaData = callback;
}
function get_initMetaData(){
    return initMetaData;
}

function set_updateDialogueInfo(callback: any){
    updateDialogueInfo = callback;
}
function get_updateDialogueInfo(){
    return updateDialogueInfo;
}

function set_initFilterList(callback: any){
    initFilterList = callback;
}
function get_initFilterList(){
    return initFilterList;
}

function set_initDatasetList(callback: any){
    initDatasetList = callback;
}
function get_initDatasetList(){
    return initDatasetList;
}
function set_expandConversations(callback: any){
    expandConversations = callback;
}
function get_expandConversations(){
    return expandConversations;
}
function set_closeConversations(callback: any){
    closeConversations = callback;
}
function get_closeConversations(){
    return closeConversations;
}
function set_copyConversations(callback: any){
    copyConversations = callback;
}
function get_copyConversations(){
    return copyConversations;
}
function get_clusterinfo(){
    return clusterinfo;
}
function set_clusterinfo(callback: any){
    clusterinfo = callback;
}
function get_getSelectedTurnInfo(){
    return getSelectedTurnInfo;
}
function set_getSelectedTurnInfo(callback: any){
    getSelectedTurnInfo = callback;
}
function get_updateCurrentTurn(){
    return updateCurrentTurn;
}
function set_updateCurrentTurn(callback: any){
    updateCurrentTurn = callback;
}
function get_getDatasetName(){
    return getDatasetName;
}
function set_getDatasetName(callback: any){
    getDatasetName = callback;
}
export {
    set_initMetaData,
    get_initMetaData,
    set_initFilterList,
    get_initFilterList,
    set_initDatasetList,
    get_initDatasetList,
    set_updateDialogueInfo,
    get_updateDialogueInfo,
    set_expandConversations,
    get_expandConversations,
    set_closeConversations,
    get_closeConversations,
    set_copyConversations,
    get_copyConversations,
    get_clusterinfo,
    set_clusterinfo,
    get_getSelectedTurnInfo,
    set_getSelectedTurnInfo,
    get_updateCurrentTurn,
    set_updateCurrentTurn,
    get_getDatasetName,
    set_getDatasetName
}