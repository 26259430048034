import React from "react";
import { Select, Modal, Button, Table } from "antd";
import { get_copyConversations, get_updateCurrentTurn } from "../../../helper/callbackHook";

const Option = Select.Option;

export interface BookMarkerProps {
  visible: any,
  onClose: any,
  datasetID: any,
  changeDialogueID: any,
  setCurrentTurn: any,
  prompt_collection: any
}

export interface BookMarkerState {}

export default class BookMarker extends React.Component<
  BookMarkerProps,
  BookMarkerState
> {
  constructor(props: BookMarkerProps) {
    super(props);
    this.state = {};
  }

  handleCancel = () => {
    this.props.onClose();
  };
  handleDownload = () => {
    const { prompt_collection } = this.props;
    const json = JSON.stringify(prompt_collection);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "prompt_collection.json";
    a.click();
    URL.revokeObjectURL(url);
  };
  changeInstance = (record: any) => {
    if(record.datasetID !== this.props.datasetID){
      
    }else{
      let instance_id = record.instanceID;
      let turn_id = record.turnID;
      this.props.changeDialogueID(instance_id);
      // this.props.setCurrentTurn(turn_id);

      let func : any = get_updateCurrentTurn();
      func(turn_id);
    }   
  }
  render() {
    const { visible, datasetID } = this.props;
    let getDisabled = (record: any, curr_datasetID: any) => {
      console.log("getDisabled", record, curr_datasetID);
      if(record){
        if(record.hasOwnProperty("datasetID")){
          let datasetID = record["datasetID"];
          if(curr_datasetID == datasetID){
            return false;
          }
        }
      }
      return true;
    }
    const columns = [
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        render: (text: any, record: any) => {
          console.log("render, record", text, record);
        return <Button 
          type="primary" 
          disabled={getDisabled(record, datasetID)}
          onClick={()=>{this.changeInstance(record)}}
          >Go</Button>
        },
        width: 5
      },
      {
        title: "Dataset",
        dataIndex: "dataset",
        key: "dataset",
        width: 20
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        width: 20
      },
      {
        title: "Prompt",
        dataIndex: "prompt",
        key: "prompt",
        width: 200
      },
    ];

    const data = this.props.prompt_collection;

    return (
      <Modal
        title="Prompts Collection"
        visible={visible}
        onCancel={this.handleCancel}
        width="70%"
        footer={[
          <Button key="ok" onClick={this.handleCancel}>
            OK
          </Button>,
        ]}
      >
                <Button onClick={this.handleDownload}>Download</Button>

        <Table columns={columns} dataSource={data} />
      </Modal>
    );
  }
}