
const frag = `
precision highp float;

uniform sampler2D texture;

// Passed from the vertex shader
varying vec2 fragTextureCoord;
varying float fragAlpha;

// float linearstep(float edge0, float edge1, float x) {
//   return clamp((x - edge0) / (edge1 - edge0), 0.0, 1.0);
// }

void main() {
  // Test if the fragment is inside the circle area of this point
  // vec2 cxy = 2.0 * gl_PointCoord - 1.0;
  // if (dot(cxy, cxy) > 1.03) {
  //   discard;
  //   return;
  // }

  // Need to pre-compute alpha
  // https://medium.com/david-guan/alpha-blending-and-webgl-823d86de00d8
  vec4 color = texture2D(texture, fragTextureCoord);
  vec4 colorOpacity = color * fragAlpha * color[3];
  gl_FragColor = colorOpacity;
}
`;

const vert = `
precision highp float;

// Vertex variables from JS
attribute vec2 position;
attribute vec2 textureCoord;
// Attribute for point alpha
attribute float pointAlpha;

// Constant values from JS
uniform float pointWidth;

// Teransform from the data space to the stage space
uniform mat3 dataScaleMatrix;

// Apply zoom transform to the stage coordinate
uniform mat3 zoomMatrix;

// Transform stage space to normalized divice coordinate
// (-1, -1) top left to (1, 1) bottom right
uniform mat3 normalizeMatrix;

uniform float alpha;
uniform float userAlpha;

// Values sent to the fragment shader
varying vec2 fragTextureCoord;
varying float fragAlpha;

void main() {

  // Init variables in main
  // https://stackoverflow.com/questions/61765147/initializing-global-variables-in-glsl
  mat3 transformMatrix = dataScaleMatrix * zoomMatrix * normalizeMatrix;

  // Scale the point based on the zoom level
  // https://observablehq.com/@bmschmidt/zoom-strategies-for-huge-scatterplots-with-three-js
  float dynamicSize = pointWidth * (exp(log(zoomMatrix[0][0]) * 0.55));
  float dynamicAlpha = min(0.4, max(0.1, alpha * log(zoomMatrix[0][0]) / 2.0));
  dynamicAlpha = max(userAlpha, dynamicAlpha);


  fragTextureCoord = textureCoord;
  fragAlpha = dynamicAlpha * pointAlpha;
  gl_PointSize = dynamicSize;

  // Normalize the vertex position
  vec3 normalizedPosition = vec3(position, 1.0) * transformMatrix;
  gl_Position = vec4(normalizedPosition.x, normalizedPosition.y, 0.0, 1.0);
}
`;


export {
  frag,
  vert
}