import * as React from 'react';
// import ProjectionPlaneContainer from '../../../container/ProjectionPlaneContainer';

import ProjectionPlaneWebGLContainer from '../../../container/ProjectionPlaneWebGLContainer';
import BrushedInfoPlaneContainter from '../../../container/BrushedInfoPlaneContainter';
import BrushedVisPlaneContainter from '../../../container/BrushedVisPlaneContainter';
import ProjSettingsPanelContainer from '../../../container/ProjSettingsPanelContainer';
import TablePlaneContainer from '../../../container/TablePlaneContainer';
import { Select, Slider, InputNumber, Row, message, Col, Button, Spin} from 'antd';
import {
 SettingOutlined,
 GatewayOutlined
  } from '@ant-design/icons';
  import { getFilterClusterInfo, getFilterProjectionInfo } from "../../../service/dataService";
// import { cluster } from 'd3';
// import SimilarityGraphContainer from '../../../container/SimilarityGraphContainer'

const d3 = require("d3");

const { Option } = Select;
export interface ClusterViewProps {
    filter_id: number | null,
    width: number,
     height: number,
     changeSelectedCluster: any,
     changeSelectedInstance: any,
}

export interface ClusterViewState {
    cluster_results:any,
    brushmode: any,
    settings_modal_visible: any,
    current_projection_loading: any


}


export default class ClusterView extends React.Component<ClusterViewProps, ClusterViewState> {
    private clusterViewRef: React.RefObject<HTMLDivElement>;

    constructor(props: ClusterViewProps) {
        super(props);
        this.clusterViewRef = React.createRef<HTMLDivElement>();

        this.state = {
            brushmode: 0,
            cluster_results:{},
            settings_modal_visible: false,
            current_projection_loading: false

        };
    }

    componentDidUpdate(prevProps: Readonly<ClusterViewProps>, prevState: Readonly<ClusterViewState>, snapshot?: any): void {
        if(prevProps.filter_id !== this.props.filter_id || prevProps.width !== this.props.width || prevProps.height !== this.props.height){
            let new_brush_mode : any = 0;
            if(this.state.brushmode == 1){
                this.setState({
                    brushmode: new_brush_mode
                }, ()=>{
                    this.updateFilterProjectionInfo();
                })
            }else{
                this.updateFilterProjectionInfo();

            }
            
        }

    }

    updateFilterProjectionInfo(){
        // Test API
        if (this.props.filter_id === null) {
  
        }else{
          let filterID = this.props.filter_id;
          let changeSelectedCluster = this.props.changeSelectedCluster;
          let changeSelectedInstances = this.props.changeSelectedInstance;
          this.setState({
            current_projection_loading: true
          })
          getFilterProjectionInfo(filterID).then((results:any)=>{
            console.log("results", results);
            if(results["success"]){
                if(results.hasOwnProperty("results") && results["results"].hasOwnProperty("cluster_results")){
                    let cluster_results = results["results"]["cluster_results"];
                    // let cluster_stats = this.get_cluster_stats(cluster_results);
                    changeSelectedCluster([]);
                    changeSelectedInstances([]);
                    this.setState({
                        cluster_results: cluster_results,
                        // cluster_stats: cluster_stats
                        current_projection_loading: false

                    })
                }else{
                    message.error("Parse projection results fail");
                    this.setState({
                        current_projection_loading: false 
                    })
                }
            }else{
                message.error(""+results)
                this.setState({
                    current_projection_loading: false 
                })
            }
        }).catch((reason:any)=>{
            message.error(""+reason)
            this.setState({
                current_projection_loading: false 
            })
        })
        }
  
  
      }
    onBrushButtonClick = ()=>{
        let new_brush_mode : any = 0;
        if(this.state.brushmode == 0){
            new_brush_mode = 1;
        }else{
            new_brush_mode = 0;
        }
        this.setState({
            brushmode: new_brush_mode
        })
    }
    onSettingsModalClose = () => {
        this.setState({
            settings_modal_visible: false
        })
    }
    onSettingsButtonClick = () => {
        this.setState({
            settings_modal_visible: true
        })
    }
    public render() {
        console.log("width, height", this.props.width, this.props.height)
        // 1. When the filterID is changed, fetch the cluster info from the server.
        // 2. After loading the cluster info, we can have two modes. 
       // let cluster_num = this.state.cluster_stats["ClusterNum"];
       let config: any = {
        "brushmode": this.state.brushmode,
        "width": this.props.width,
        "height": this.props.height
       };
       let cluster_results : any = this.state.cluster_results;
       let cluster_num : any = 0;
       if(cluster_results.hasOwnProperty("datasetID")){
          cluster_num = 1;
       }

       let height_ratio = 70;
       let mode = 2;
       if(mode == 2){
        height_ratio = 60;
       }

       let generateView = () => {
            return <div style={{ width: '100%', height:""+(this.props.height - 40)+"px", overflowY: "hidden"}}>
                <div style={{ width: '100%', height: ""+height_ratio+'%'}}>
                    <ProjectionPlaneWebGLContainer cluster_results={cluster_results} config={config}/>
                </div>
                <div style={{ width: '100%', height: ""+(100-height_ratio)+'%', overflowY: "hidden"}}>
                    <BrushedVisPlaneContainter cluster_results={cluster_results}/>
                </div>
            </div>
       }
       
       let brush_mode = this.state.brushmode;

       
        return (
            <div ref={this.clusterViewRef}>
            <div className="ViewTitle">Projection View
                {/*generateControls()*/}
                <ProjSettingsPanelContainer
                    visible = {this.state.settings_modal_visible}
                    onClose = {this.onSettingsModalClose}
                    brush_mode = {brush_mode}
                    onBrushButtonClick = {this.onBrushButtonClick}
                    onSettingsButtonClick= {this.onSettingsButtonClick}
                    current_projection_loading={this.state.current_projection_loading}
                ></ProjSettingsPanelContainer>
            </div>
            <div className="ViewBox" id="similarity" style={{"width": '100%'}}>
                {cluster_num>0?generateView():<div/>}
                

            </div>
            </div>
            
        );
    }
}
