import ConfigPanel from '../components/DataRuns/ControlPanel/ConfigPanel'
import { connect } from 'react-redux';
import { Dispatch } from 'redux';


import { StoreState } from '../types';
import { changeFilterID } from '../actions';
import { changeDatasetID } from '../actions';

// 将 reducer 中的状态插入到组件的 props 中
const mapStateToProps = (state: StoreState) => ({
    current_filter: state.filter_id,
    current_dataset: state.dataset_id
})

// 将 对应action 插入到组件的 props 中
const mapDispatchToProps = (dispatch: Dispatch) => ({    
    setCurrentFilter: (filter_id: number) => dispatch(changeFilterID(filter_id)),
    setCurrentDataset: (dataset_id: number) => dispatch(changeDatasetID(dataset_id))
})

// 使用 connect 高阶组件对 Counter 进行包裹
export default connect(mapStateToProps, mapDispatchToProps)(ConfigPanel);



