import {getLatoTextWidth} from '../../../../helper/text-width';
import {getBorderColor} from '../../../../helper';
const IDEAL_TILE_WIDTH = 35;
const LABEL_SPLIT = '-';

const d3 = require("d3");


type TopicData = [number, number, string, number];
interface LabelData {
  tileX: number;
  tileY: number;
  tileCenterX: number;
  tileCenterY: number;
  pointX: number;
  pointY: number;
  name: string;
  percentage: number;
}
interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}
interface DrawnLabel extends Rect {
  direction: Direction;
  pointX: number; // Density center point's x coordinate.
  pointY: number; // Density center point's y coordinate.
  tileX: number; // Topic tile's top left point's x coordinate.
  tileY: number; // Topic tile's top left point's y coordinate.
  toHide: boolean; // Whether to hide this label.
  name: string; // Label name.
  lines: string[]; // Label lines.
  labelX: number; // Label element's x coordinate.
  labelY: number; // Label element's y coordinate.
  percentage: number;
}

enum Direction {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right'
}



/**
 * Detect if two rectangles overlap.
 * https://stackoverflow.com/a/306332
 *
 * @param rect1 Rectangle 1
 * @param rect2 Rectangle 2
 * @returns True if these two rectangles overlap.
 */
const rectsIntersect = (rect1: Rect, rect2: Rect) => {
  const right1 = rect1.x + rect1.width;
  const right2 = rect2.x + rect2.width;

  const bottom1 = rect1.y + rect1.height;
  const bottom2 = rect2.y + rect2.height;

  return (
    rect1.x < right2 &&
    right1 > rect2.x &&
    rect1.y < bottom2 &&
    bottom1 > rect2.y
  );
};



function getIdealTopicTreeLevel(config: any) {
    if (config.topicLevelTrees.size < 1) return null;
    let topicLevelTrees = config.topicLevelTrees;
    let bestLevel = -1;
    let bestDistance = Infinity;
  
    for (const level of topicLevelTrees.keys()) {
      const extent = topicLevelTrees.get(level)!.extent()!;
      const treeViewWidth = extent[1][0] - extent[0][0];
      const tileNum = Math.pow(2, level);
      const tileSize = treeViewWidth / tileNum;
      const scaledTileWidth =
        Math.max(
          config.xScale(tileSize) - config.xScale(0),
          config.yScale(tileSize) - config.yScale(0)
        ) * config.curZoomTransform.k;
  
      if (Math.abs(scaledTileWidth - IDEAL_TILE_WIDTH) < bestDistance) {
        bestLevel = level;
        bestDistance = Math.abs(scaledTileWidth - IDEAL_TILE_WIDTH);
      }
    }
  
    return bestLevel;
  }


  /**
   * Get the current zoom viewing box
   * @returns Current zoom view box
   */
  function getCurZoomBox(config: any) {
    const box: any = {
      x: config.curZoomTransform.invertX(0),
      y: config.curZoomTransform.invertY(0),
      width: Math.abs(
        config.curZoomTransform.invertX(config.svgFullSize.width) -
        config.curZoomTransform.invertX(0)
      ),
      height: Math.abs(
        config.curZoomTransform.invertY(config.svgFullSize.height) -
        config.curZoomTransform.invertY(0)
      )
    };
    return box;
  };

/**
 * Add direction indicator path
 * @param d Datum
 * @param i Datum index
 * @param g Nodes
 * @param tileScreenWidth Tile width in the screen coordinate
 * @returns This path element
 */
function addTileIndicatorPath(
  config: any,
  d: DrawnLabel,
  i: number,
  g: SVGPathElement[] | ArrayLike<SVGPathElement>,
  tileScreenWidth: number
) {
  const pathGenerator = d3.arc();
  const pathArgs: d3.DefaultArcObject = {
    innerRadius: 0,
    outerRadius: 3 / config.curZoomTransform.k,
    startAngle: -Math.PI / 2,
    endAngle: Math.PI / 2
  };
  const selection = d3.select(g[i]);

  let tx = config.xScale(d.tileX) + tileScreenWidth / 2;
  let ty = config.yScale(d.tileY);

  switch (d.direction) {
    case Direction.left: {
      pathArgs.startAngle = -Math.PI;
      pathArgs.endAngle = 0;
      tx = config.xScale(d.tileX);
      ty = config.yScale(d.tileY) + tileScreenWidth / 2;
      break;
    }

    case Direction.right: {
      pathArgs.startAngle = 0;
      pathArgs.endAngle = Math.PI;
      tx = config.xScale(d.tileX) + tileScreenWidth;
      ty = config.yScale(d.tileY) + tileScreenWidth / 2;
      break;
    }

    case Direction.bottom: {
      pathArgs.startAngle = Math.PI / 2;
      pathArgs.endAngle = (Math.PI * 3) / 2;
      tx = config.xScale(d.tileX) + tileScreenWidth / 2;
      ty = config.yScale(d.tileY) + tileScreenWidth;
      break;
    }

    default: {
      break;
    }
  }

  return selection
    .attr('d', pathGenerator(pathArgs))
    .attr('transform', `translate(${tx}, ${ty})`);
}

/**
 * Draw the labels using computed layouts
 * @param group Container group of current zoom level
 * @param drawnLabels Array of labels to draw
 * @param tileScreenWidth Tile width in the screen coordinate
 * @param idealTreeLevel Ideal tree level
 * @param fontSize Font size
 * @returns Drawn label selections
 */
function drawLabels(
  config: any,
  group: d3.Selection<d3.BaseType | SVGGElement, number, d3.BaseType, unknown>,
  drawnLabels: DrawnLabel[],
  tileScreenWidth: number,
  idealTreeLevel: number,
  fontSize: number
) {
  const transAddition = d3
    .transition('label-addition')
    .duration(300)
    .ease(d3.easeCubicInOut);

  const transRemoval = d3
    .transition('label-removal')
    .duration(100)
    .ease(d3.easeLinear);


  let layoutStyle = 2; // 1 ---> add orange bar on top of rectangle.

  const enterFunc = (
    enter: d3.Selection<
      d3.EnterElement,
      DrawnLabel,
      d3.BaseType | SVGGElement,
      number
    >
  ) => {
    // Add the group element
    const labelGroup = enter
      .append('g')
      .attr('class', `label-group zoom-${idealTreeLevel}`)
      .classed('hidden', d => d.toHide);

    // Animation for individual group addition
    if (config.lastLabelNames.size > 0) {
      labelGroup
        .style('opacity', 0)
        .transition(transAddition)
        .style('opacity', 1);
    }

    // Draw the text label
    const text = labelGroup
      .append('text')
      .attr('class', d => `topic-label ${d.direction}`)
      .attr('transform', d => `translate(${d.labelX}, ${d.labelY})`)
      .style('font-size', `${fontSize}px`)
      .text(d => (d.lines.length > 1 ? null : d.lines[0]))
      .attr('paint-order', 'stroke')
      .style('stroke', '#fff')
      .style('stroke-width', 3.2 / config.curZoomTransform.k);

    text
      .append('tspan')
      .attr('class', 'line-1')
      .attr('x', 0)
      .attr('y', 0)
      .text(d => (d.lines.length > 1 ? d.lines[0] : ''));
    text
      .append('tspan')
      .attr('class', 'line-2')
      .attr('x', 0)
      .attr('y', 0)
      .attr('dy', 0.96 * fontSize)
      .text(d => (d.lines.length > 1 ? d.lines[1] : ''));
    if(layoutStyle === 1){
      // Draw the topic region
      const tileRect2 = labelGroup
        .append('rect')
        .attr('class', 'topic-tile2')
        .attr('x', d => config.xScale(d.tileX))
        .attr('y', d => config.yScale(d.tileY))
        .attr('rx', 4 / config.curZoomTransform.k)
        .attr('ry', 4 / config.curZoomTransform.k)
        .attr('width', (d:any) => tileScreenWidth * d.percentage)
        .attr('height', tileScreenWidth * 0.2)
        .style('stroke-width', 1.6 / config.curZoomTransform.k);
    }

    // Draw the topic region
    const tileRect = labelGroup
      .append('rect')
      .attr('class', 'topic-tile')
      .attr('x', d => config.xScale(d.tileX) + 1.6 / 2 / config.curZoomTransform.k)
      .attr('y', d => config.yScale(d.tileY) + 1.6 / 2 / config.curZoomTransform.k)
      .attr('rx', 4 / config.curZoomTransform.k)
      .attr('ry', 4 / config.curZoomTransform.k)
      .attr('width', tileScreenWidth - 1.6 / config.curZoomTransform.k)
      .attr('height', tileScreenWidth - 1.6 / config.curZoomTransform.k)
      .style('stroke-width', 1.6 / config.curZoomTransform.k)
      .style('stroke', d => getBorderColor(d.percentage));





    // Add a dot to indicate the label direction
    labelGroup
      .append('path')
      .attr('class', 'direction-indicator')
      .attr('transform-origin', 'center')
      .each((d, i, g) => addTileIndicatorPath(config, d, i, g, tileScreenWidth));

    return labelGroup;
  };

  const updateFunc = (
    update: d3.Selection<
      d3.BaseType,
      DrawnLabel,
      d3.BaseType | SVGGElement,
      number
    >
  ) => {
    // Animate to hide labels
    const labelGroup = update.each((d, i, g) => {
      const selection = d3.select(g[i]);
      const lastToHide = selection.classed('hidden');
      if (!lastToHide && d.toHide && config.contoursInitialized) {
        selection
          .style('opacity', 1)
          .transition(transRemoval)
          .style('opacity', 0)
          .on('end', () => {
            selection.classed('hidden', d.toHide);
          });
      } else if (lastToHide && !d.toHide && config.contoursInitialized) {
        selection
          .style('opacity', 0)
          .classed('hidden', d.toHide)
          .transition(transRemoval)
          .style('opacity', 1);
      } else {
        selection.classed('hidden', d.toHide);
      }
    });

    // Update text location
    labelGroup
      .select('text')
      .style('stroke-width', 3.2 / config.curZoomTransform.k)
      .style('font-size', `${fontSize}px`)
      .each((d, i, g) => {
        const selection = d3.select(g[i]);
        const oldClass = selection.attr('class');
        const newClass = `topic-label ${d.direction}`;

        selection
          .attr('class', newClass)
          .select('.line-2')
          .attr('dy', 0.96 * fontSize);

        // If direction is changed, apply animation
        if (newClass !== oldClass) {
          selection
            .transition('update')
            .duration(300)
            .ease(d3.easeCubicInOut)
            .attr('transform', `translate(${d.labelX}, ${d.labelY})`);
        } else {
          selection.attr('transform', `translate(${d.labelX}, ${d.labelY})`);
        }
      });

      if(layoutStyle === 1){
        // Update the tile region
        labelGroup
          .select('rect.topic-tile2')
          .attr('x', d => config.xScale(d.tileX))
          .attr('y', d => config.yScale(d.tileY))
          .attr('rx', 4 / config.curZoomTransform.k)
          .attr('ry', 4 / config.curZoomTransform.k)
          .attr('width', (d:any) => tileScreenWidth * d.percentage)
          .attr('height', tileScreenWidth  * 0.2)
          .style('stroke-width', 1.6 / config.curZoomTransform.k);
      }

    // Update the tile region
    labelGroup
      .select('rect.topic-tile')
      .attr('x', d => config.xScale(d.tileX) + 1.6 / 2 / config.curZoomTransform.k)
      .attr('y', d => config.yScale(d.tileY) + 1.6 / 2 / config.curZoomTransform.k)
      .attr('rx', 4 / config.curZoomTransform.k)
      .attr('ry', 4 / config.curZoomTransform.k)
      .attr('width', tileScreenWidth - 1.6 / config.curZoomTransform.k)
      .attr('height', tileScreenWidth - 1.6 / config.curZoomTransform.k)
      .style('stroke-width', 1.6 / config.curZoomTransform.k)
      .style('stroke', d => getBorderColor(d.percentage));
    // Update the dot orientation
    labelGroup
      .select<SVGPathElement>('path.direction-indicator')
      .each((d, i, g) => addTileIndicatorPath(config, d, i, g, tileScreenWidth));
    return update;
  };

  const exitFunc = (
    exit: d3.Selection<
      d3.BaseType,
      DrawnLabel,
      d3.BaseType | SVGGElement,
      number
    >
  ) => {
    // Animation for individual group removal
    if (config.lastLabelNames.size > 0 && config.contoursInitialized) {
      exit
        .transition(transRemoval)
        .style('opacity', 0)
        .on('end', () => {
          exit.remove();
        });
      return exit;
    } else {
      return exit.remove();
    }
  };

  const labelGroups = group
    .selectAll('g.label-group')
    .data(drawnLabels, d => (d as DrawnLabel).name)
    .join(
      enter => enterFunc(enter),
      update => updateFunc(update),
      exit => exitFunc(exit)
    );

  return labelGroups;
}


/**
 * Search all data points in the given bounding box
 * @param quadtree Quadtree
 * @param xMin Min x
 * @param yMin Min y
 * @param xMax Max x
 * @param yMax Max y
 * @returns Array of points in this regin
 */
function search2DQuadTree (
  quadtree: d3.Quadtree<TopicData>,
  xMin: number,
  yMin: number,
  xMax: number,
  yMax: number
) {
  const results: TopicData[] = [];
  quadtree.visit((node: any, x1: any, y1: any, x2: any, y2: any) => {
    if (!node.length) {
      let leaf: d3.QuadtreeLeaf<TopicData> | undefined =
        node as d3.QuadtreeLeaf<TopicData>;
      do {
        const d = leaf.data;
        if (d[0] >= xMin && d[0] < xMax && d[1] >= yMin && d[1] < yMax) {
          results.push(d);
        }
      } while ((leaf = leaf.next));
    }
    return x1 >= xMax || y1 >= yMax || x2 < xMin || y2 < yMin;
  });
  return results;
};

/**
   * Show the topic labels at different zoom scales.
   */
  export function layoutTopicLabels(
    config: any,
    maxLabels: number | null = null,
    canSkipLayout = true
  ) {
    // config should consist of 
    /*
        topicLevelTrees: required
        contours: required
        curZoomTransform: required

        topSvg: topicGroup = config.topSvg.select('g.top-content g.topics');

    */

    /*
    function running process:
    Stage 1: Checking
    Stage 2: Collecting Polygon Centers
      - Collect polygon center points by averaging coordinates and store them with contour values.
    Stage 3: Computing View Extent and Tile Dimensions
      - Compute view extent and tile dimensions based on zoom level and topic tree properties.
    Stage 4: Handling Animation and Selecting Elements
      - Set up animation transition and select elements for entering, updating, and exiting.
    Stage 5: Label Data and Layout Skipping
      - Manage label data and counting occurrences, calculate font size, and check for layout skipping.


    */


    // --------------- Stage 1 -----------   Checking ------------ //
    if (config.topicLevelTrees.size <= 1) return;
    if (config.contours === null) return;
    if (!config.showLabel) return;
    if (config.groupNames !== null && config.groupContours === null) return;
    
    const topicGroup = config.contentSvg.select('g.top-content g.topics');

    // -------------------- Stage 2: Collecting Polygon Centers     --------------- //
    // - Collect polygon center points by averaging coordinates and store them with contour values.

    // Collect polygon centers from the total contour
    let polygonCenters: [number, number, number][] = [];
  
    // For each polygon, get their points and calculate center points
    // return [center X, center Y, contour value]
    const collectPolygonCenters = (curContour: d3.ContourMultiPolygon[]) => {
      if(!curContour) return;
      for (let i = curContour.length - 1; i >= 0; i--) {
        const contour = curContour[i];
  
        // Compute the geometric center of each polygon
        for (const polygon of contour.coordinates) {
          const xs = [];
          const ys = [];
          for (const point of polygon[0]) {
            xs.push(point[0]);
            ys.push(point[1]);
          }
          const centerX = xs.reduce((a, b) => a + b) / xs.length;
          const centerY = ys.reduce((a, b) => a + b) / ys.length;
          polygonCenters.push([centerX, centerY, contour.value]);
        }
      }
    };
    collectPolygonCenters(config.contours);
  
    // If the user specifies groups, we only consider polygon centers from the
    // shown contour groups
    if (config.groupNames !== null) {
      if (config.groupContours === null) {
        console.log('groupContours is null');
      }else{
        polygonCenters = [];
        for (const [i, _] of config.groupNames.entries()) {
           if (config.showContours[i]) {
            collectPolygonCenters(config.groupContours[i]);
           }
        }
      }

    }


    // ------------------- Stage 3: Computing View Extent and Tile Dimensions ------------ //
    //    - Compute view extent and tile dimensions based on zoom level and topic tree properties.

    // Compute the current view extent based on the zoom
    const curZoomBox = getCurZoomBox(config);
  
    // Choose the topic tree level based on the current zoom level
    const idealTreeLevel = getIdealTopicTreeLevel(config)!;
    const topicTree = config.topicLevelTrees.get(idealTreeLevel)!;
    const treeExtent = topicTree.extent()!;
    const tileWidth =
      (treeExtent[1][0] - treeExtent[0][0]) / Math.pow(2, idealTreeLevel);
    const tileScreenWidth = Math.abs(config.xScale(tileWidth) - config.xScale(0));
  
    
    //  ---------------  Stage 4: Handling Animation and Selecting Elements   -------------- //
    //         - Set up animation transition and select elements for entering, updating, and exiting.
    
    // Show animation when we shift zoom level
    const trans = d3.transition('removal').duration(400).ease(d3.easeCubicInOut);
  
    const group = topicGroup
      .selectAll('g.topics-content')
      .data([idealTreeLevel], (d: any) => d as number)
      .join(
        (enter: any) => {
          const newGroup = enter
            .append('g')
            .attr('class', (d: any) => `topics-content zoom-${d}`)
            .style('opacity', 0);
  
          newGroup.transition(trans).style('opacity', 1);
  
          if (!enter.empty()) {
            config.lastLabelNames = new Map();
          }
  
          return newGroup;
        },
        (update: any) => {
          return update;
        },
        (exit: any) => {
          if (config.lastLabelTreeLevel !== idealTreeLevel) {
            return exit
              .transition(trans)
              .style('opacity', 0)
              .on('end', () => {
                exit.remove();
              });
          } else {
            return exit;
          }
        }
      );
  
    //  ------------------ Stage 5: Label Data and Layout Skipping -------------------------- //
    //    - Manage label data and counting occurrences, calculate font size, and check for layout skipping.
    //    - If we can skip layout and the time is small, we can only draw labels instead of drawing all of them. 

    
    // Find closest topic labels for each high density point
    const labelDataMap = new Map<string, LabelData>();
    const labelDataCounter = new Map<string, number>();
    const fontSize = 14 / config.curZoomTransform.k;
  
    // Skip layout if we have just done it
    if (canSkipLayout && Date.now() - config.lastLabelLayoutTime < 300) {
      // Draw the labels
      drawLabels(
        config,
        group,
        config.lastDrawnLabels,
        tileScreenWidth,
        idealTreeLevel,
        fontSize
      );
      return;
    }
  

    // - The code performs a 2D search using a quadtree on a set of points, calculates label data, 
    // - and sorts it based on density scores.


    config.lastLabelTreeLevel = idealTreeLevel;
  
    // ------------------------ Accumated Density Scores for Sorting ---------------------- ///
    for (const point of polygonCenters) {
      const viewX = config.xScale.invert(point[0]);
      const viewY = config.yScale.invert(point[1]);
  
      // Use 2D search to potentially detect multiple tiles for one density
      // center. Radius is a hyper parameter.
      const radius = tileWidth * 0.5;
      const closestTopics = search2DQuadTree(
        topicTree,
        viewX - radius,
        viewY - radius,
        viewX + radius,
        viewY + radius
      );
  
      for (const closestTopic of closestTopics) {
        const curLabelData: LabelData = {
          tileX: closestTopic[0] - tileWidth / 2,
          tileY: closestTopic[1] + tileWidth / 2,
          tileCenterX: closestTopic[0],
          tileCenterY: closestTopic[1],
          pointX: viewX,
          pointY: viewY,
          name: closestTopic[2],
          percentage: closestTopic[3]
        };
  
        if (labelDataCounter.has(curLabelData.name)) {
          labelDataCounter.set(
            curLabelData.name,
            labelDataCounter.get(curLabelData.name)! + point[2]
          );
        } else {
          labelDataCounter.set(curLabelData.name, point[2]);
          labelDataMap.set(curLabelData.name, curLabelData);
        }
      }
    }
    // ------------------------End  Accumated Density Scores for Sorting ---------------------- ///

    // Sort the label data by their accumulated density scores
    const sortedLabelData = [...labelDataCounter]
      .sort((a, b) => b[1] - a[1])
      .map(pair => labelDataMap.get(pair[0])!);
  
    const drawnLabels: DrawnLabel[] = [];
    const drawnTiles: Rect[] = [];
  
    const textHeight = fontSize * 1.1;
    const vPadding = 6.4 / config.curZoomTransform.k;
    const hPadding = 6.4 / config.curZoomTransform.k;
  
    // Count the number of labels that are shown
    let shownLabelNum = 0;
    let inViewLabelNum = 0;
  
    // --------------  Determine how to show labels ------------------------- ///
    for (const label of sortedLabelData) {
      const twoLine = label.name.length > 12;
      let line1 = label.name.slice(0, Math.floor(label.name.length / 2));
      let line2 = label.name.slice(Math.floor(label.name.length / 2));
  
      if (twoLine && label.name.split(LABEL_SPLIT).length >= 4) {
        const words = label.name.split(LABEL_SPLIT);
        line1 = words.slice(0, 2).join('-') + '-';
        line2 = words.slice(2).join('-');
      }
  
      const textWidth = twoLine
        ? Math.max(
            getLatoTextWidth(line1, fontSize),
            getLatoTextWidth(line2, fontSize)
          )
        : getLatoTextWidth(label.name, fontSize);
      const curTextHeight = twoLine ? textHeight * 1.8 : textHeight;
  
      // Try 4 different layout
      let fit = true;
      let fitRect: Rect | null = null;
      let fitDirection: Direction | null = null;
  
      // Simple greedy heuristic:
      // https://en.wikipedia.org/wiki/Automatic_label_placement
      // (1) Prioritize left and right over top and bottom;
      const directions = [
        Direction.left,
        Direction.right,
        Direction.bottom,
        Direction.top
      ];
  
      // (2) Prioritize right if the tile is on the right half
      if (label.tileCenterX >= (treeExtent[0][0] + treeExtent[1][0]) / 2) {
        directions[0] = Direction.right;
        directions[1] = Direction.left;
      }
  
      // (3) pick the opposite direction as the connected drawn neighbor's tile.
      // We also use this iteration to check if this topic tile would overlaps
      // with previous labels.
      let neighborDirection: Direction | null = null;
      let tileIntersects = false;
      const curTileRect: Rect = {
        x: config.xScale(label.tileX),
        y: config.yScale(label.tileY),
        width: tileScreenWidth,
        height: tileScreenWidth
      };
  
      for (const drawnLabel of drawnLabels) {
        if (rectsIntersect(curTileRect, drawnLabel)) {
          tileIntersects = true;
          break;
        }
  
        const xDiff = Math.abs(drawnLabel.tileX - label.tileX);
        const yDiff = Math.abs(drawnLabel.tileY - label.tileY);
        if (xDiff + yDiff <= tileWidth) {
          neighborDirection = drawnLabel.direction;
        }
      }
  
      // Do now show this label if it overlaps
      if (tileIntersects) {
        continue;
      }
  
      switch (neighborDirection) {
        case Direction.left: {
          directions.splice(directions.indexOf(Direction.right), 1);
          directions.unshift(Direction.right);
          break;
        }
        case Direction.right: {
          directions.splice(directions.indexOf(Direction.left), 1);
          directions.unshift(Direction.left);
          break;
        }
        case Direction.top: {
          directions.splice(directions.indexOf(Direction.bottom), 1);
          directions.unshift(Direction.bottom);
          break;
        }
        case Direction.bottom: {
          directions.splice(directions.indexOf(Direction.top), 1);
          directions.unshift(Direction.top);
          break;
        }
        default: {
          break;
        }
      }
  
      // (4) Prioritize a safer direction first if there is another (future)
      // tile connecting to the current tile's left or right
      for (const futureLabel of sortedLabelData) {
        if (futureLabel.tileY === label.tileY) {
          const xDiff = futureLabel.tileX - label.tileX;
          if (xDiff === tileWidth) {
            // There is a future tile on the right, prioritize left
            directions.splice(directions.indexOf(Direction.left), 1);
            directions.unshift(Direction.left);
          } else if (xDiff === -tileWidth) {
            // There is a future tile on the left, prioritize right
            directions.splice(directions.indexOf(Direction.right), 1);
            directions.unshift(Direction.right);
          }
        }
      }
  
      // (5) Highest priority: prioritize previous shown direction to avoid
      // labels moving around
      if (config.lastLabelNames.has(label.name)) {
        const lastDirection = config.lastLabelNames.get(label.name)!;
        directions.splice(directions.indexOf(lastDirection), 1);
        directions.unshift(lastDirection);
      }
  
      for (const direction of directions) {
        fit = true;
        const curRect: Rect = {
          x: 0,
          y: 0,
          width: textWidth,
          height: curTextHeight
        };
  
        // Compute the bounding box for this current layout
        switch (direction) {
          case Direction.top:
            curRect.x = config.xScale(label.tileCenterX) - textWidth / 2;
            curRect.y =
              config.yScale(label.tileCenterY) -
              tileScreenWidth / 2 -
              curTextHeight -
              vPadding;
            break;
  
          case Direction.bottom:
            curRect.x = config.xScale(label.tileCenterX) - textWidth / 2;
            curRect.y =
              config.yScale(label.tileCenterY) + tileScreenWidth / 2 + vPadding;
            break;
  
          case Direction.left:
            curRect.x =
              config.xScale(label.tileCenterX) -
              tileScreenWidth / 2 -
              textWidth -
              hPadding;
            curRect.y = config.yScale(label.tileCenterY) - curTextHeight / 2;
            break;
  
          case Direction.right:
            curRect.x =
              config.xScale(label.tileCenterX) + tileScreenWidth / 2 + hPadding;
            curRect.y = config.yScale(label.tileCenterY) - curTextHeight / 2;
            break;
  
          default:
            console.error('Unknown direction value.');
            break;
        }
  
        // Compare the current direction with existing labels to see if there is
        // any overlapping
        for (const drawnLabel of drawnLabels) {
          if (rectsIntersect(curRect, drawnLabel)) {
            fit = false;
            break;
          }
        }
  
        // Compare the current direction with existing tile squares to see if
        // there is any overlapping
        for (const drawnTile of drawnTiles) {
          if (rectsIntersect(curRect, drawnTile)) {
            fit = false;
            break;
          }
        }
  
        // The current direction does not overlap with any existing rects
        if (fit) {
          fitRect = curRect;
          fitDirection = direction;
          break;
        }
      }
  
      // Draw this label if we find a location for it
      if (fit && fitRect && fitDirection) {
        const drawnLabel: DrawnLabel = {
          x: fitRect.x,
          y: fitRect.y,
          width: fitRect.width,
          height: fitRect.height,
          direction: fitDirection,
          pointX: label.pointX,
          pointY: label.pointY,
          tileX: label.tileX,
          tileY: label.tileY,
          toHide: false,
          name: label.name,
          lines: twoLine ? [line1, line2] : [label.name],
          labelX: config.xScale(label.tileCenterX),
          labelY: config.yScale(label.tileCenterY),
          percentage: label.percentage
        };
        const drawnTile: Rect = {
          x: config.xScale(label.tileX),
          y: config.yScale(label.tileY),
          width: tileScreenWidth,
          height: tileScreenWidth
        };
  
        // Check if this label and tile rect intersects with the view extent
        drawnLabel.toHide =
          !rectsIntersect(fitRect, curZoomBox) &&
          !rectsIntersect(drawnTile, curZoomBox);
  
        if (!drawnLabel.toHide) {
          inViewLabelNum += 1;
          if (maxLabels !== null) {
            // We have shown enough labels, stop showing this label if we haven't
            // drawn it last time. Edge case: we stop showing extra labels during
            // the initial zoom triggered by drawContours()
            if (
              shownLabelNum >= maxLabels &&
              (!config.contoursInitialized || !config.lastLabelNames.has(label.name))
            ) {
              drawnLabel.toHide = true;
            } else {
              shownLabelNum += 1;
            }
          }
        }
  
        switch (fitDirection) {
          case Direction.top: {
            drawnLabel.labelY -=
              vPadding + tileScreenWidth / 2 + (twoLine ? textHeight : 0);
            break;
          }
          case Direction.bottom: {
            drawnLabel.labelY += vPadding + tileScreenWidth / 2;
            break;
          }
          case Direction.left: {
            drawnLabel.labelX -= hPadding + tileScreenWidth / 2;
            drawnLabel.labelY -= twoLine ? textHeight : 0;
            break;
          }
          case Direction.right: {
            drawnLabel.labelX += hPadding + tileScreenWidth / 2;
            drawnLabel.labelY -= twoLine ? textHeight : 0;
            break;
          }
          default: {
            console.error('Unknown layout value.');
            break;
          }
        }
  
        drawnLabels.push(drawnLabel);
        drawnTiles.push(drawnTile);
      }
    }
    // --------------  End Determine how to show labels ------------------------- ///

    config.lastDrawnLabels = drawnLabels;
    config.lastLabelLayoutTime = Date.now();
  
    // Draw the labels
    drawLabels(
      config,
      group,
      drawnLabels,
      tileScreenWidth,
      idealTreeLevel,
      fontSize
    );
  
    // Track the labels we have shown
    config.lastLabelNames = new Map();
    drawnLabels
      .filter(d => !d.toHide)
      .forEach(d => config.lastLabelNames.set(d.name, d.direction));
  
      /*
    this.maxLabelNum = inViewLabelNum;
    this.curLabelNum = shownLabelNum;
  
    const sliderElem = this.component.querySelector(
      '.label-menu input#slider-label-num'
    ) as HTMLInputElement;
    sliderElem.max = `${this.maxLabelNum}`;
    sliderElem.value = `${this.curLabelNum}`;
  
    const sliderCountElem = this.component.querySelector(
      '.label-menu span.slider-count'
    ) as HTMLSpanElement;
    sliderCountElem.innerText = `${this.curLabelNum}`;
    */
  }


   export function initTopicData(config: any){
    config.topicLevelTrees = new Map<
        number,
        d3.Quadtree<TopicData>
      >();
    // Handling the topic label data
    // Create a quad tree at each level
    for (const level of Object.keys(config.gridData.topic.data)) {
      const tree = d3
        .quadtree()
        .x((d: any) => d[0])
        .y((d: any) => d[1])
        .addAll(config.gridData.topic.data[level]);
      config.topicLevelTrees.set(parseInt(level), tree);
    }
  }
