import ClusterView from '../components/DataRuns/ClusterView/ClusterView'
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

//import { decrement, increment } from '../actions';
import { StoreState } from '../types';
import { changeSelectedCluster, changeSelectedInstances } from '../actions';


// 将 reducer 中的状态插入到组件的 props 中
const mapStateToProps = (state: StoreState) => ({
    filter_id: state.filter_id

})

// 将 对应action 插入到组件的 props 中
const mapDispatchToProps = (dispatch: Dispatch) => ({
    changeSelectedCluster: (selected_cluster:any) => dispatch(changeSelectedCluster(selected_cluster)),
    changeSelectedInstance: (selected_instances: any) => dispatch(changeSelectedInstances(selected_instances))

})

// 使用 connect 高阶组件对 Counter 进行包裹
export default connect(mapStateToProps, mapDispatchToProps)(ClusterView);



