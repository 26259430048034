import axios from 'axios';

const ENABLE_STATIC_JSON = false;
const ENABLE_PUBLIC_URL = false;
const PUBLIC_URL = "http://diagvis.s58.hkustvis.org";
const PRIVATE_URL = "http://localhost:7777";
const CHOICE_URL = (ENABLE_PUBLIC_URL)?PUBLIC_URL:PRIVATE_URL;
export const ENABLE_FORCE_AUTH = true;
export const ENABLE_AUTO_LOGIN = false;
let TOKEN:any = "";

// Remote Backend.
const URL = process.env.NODE_ENV === 'development'
    ? CHOICE_URL
    : window.location.origin;
const axiosInstance1 = axios.create({
    baseURL: `${URL}/api/`,
    // timeout: 1000,
    headers: {
        'Access-Control-Allow-Origin': '*'
    }
});

// Load Static Data.
const URL2 = window.location.origin;
const axiosInstance2 = axios.create({
    baseURL: `${URL2}/data/`,
    // timeout: 1000,
    headers: {
        'Access-Control-Allow-Origin': '*'
    }
});

let axiosInstance = (ENABLE_STATIC_JSON)?axiosInstance2:axiosInstance1;

export async function getUserVerify(username: any, password:any): Promise<any> {
    let url = `/user_verify`;
    const params = { username, password};
    const res = await axiosInstance.get(url, {params}); // EXCEPTION
    //const params = { classifier_start, classifier_end };
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}
function wrap_params_with_token(params: any){
    if(ENABLE_FORCE_AUTH){
        const new_params:any = {
            token: TOKEN,
            ...params
        };
        return new_params;
    }else{
        return params;
    }
}
function wrap_formData_with_token(formdata: any){
    if(ENABLE_FORCE_AUTH){
        formdata.append('token', TOKEN)
        return formdata;
    }else{
        return formdata;
    }
}
function wrap_URL_with_token(URL: any){
    if(ENABLE_FORCE_AUTH){
        let newURL = URL + "&token=" + TOKEN
        return newURL;
    }else{
        return URL;
    }
}
function wrap_UploadURL_with_token(URL: any){
    if(ENABLE_FORCE_AUTH){
        let newURL = URL + "?token=" + TOKEN
        return newURL;
    }else{
        return URL;
    }
}
function wrap_ret_pkg(pkg:any, key:any){
    if(ENABLE_STATIC_JSON){
        let ret_pkg:any = {
            "success": true
        }
        ret_pkg[key] = pkg;
        return ret_pkg;
    }else{
        return pkg;
    }
}
export function setToken(token:any) {
    TOKEN = token;
}


// Read graph dataset metainfo.
export async function getMetaData(): Promise<any> {
    let url = `/metadata`;
    const params = wrap_params_with_token({});
    const res = await axiosInstance.get(url, {params});
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}

export async function getDialogueInfo(id:number): Promise<any> {
    let url = '/dialogue'
    const params = wrap_params_with_token({id}); 
    const res = await axiosInstance.get(url, {params} );
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}

// Read filter list.
export async function getFilterList(): Promise<any> {
    let url = `/getFiltersList`;
    const params = wrap_params_with_token({});
    const res = await axiosInstance.get(url, {params});
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}

// Read datasets list.
export async function getDatasetList(): Promise<any> {
    let url = `/getDatasetsList`;
    const params = wrap_params_with_token({});
    const res = await axiosInstance.get(url, {params});
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}

// Delete current filter.
export async function deleteFilter(filterID:number|null): Promise<any> {
    let url = `/deleteFilter`;
    const params = wrap_params_with_token({filterID}); 
    const res = await axiosInstance.post(url, params);
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}

// Read datasetinfo.
export async function getDatasetInfo(datasetID:number|null): Promise<any> {
    let url = `/getDatasetInfo`;
    const params = wrap_params_with_token({datasetID}); 
    const res = await axiosInstance.get(url, {params});
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}

// Read filterinfo.
export async function getFilterInfo(filterID:number|null): Promise<any> {
    let url = `/getFilterInfo`;
    const params = wrap_params_with_token({filterID}); 
    const res = await axiosInstance.get(url, {params});
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}

// Test Filter.
export async function testFilter(data:any): Promise<any> {
    let url = `/testFilter`;
    // console.log(data)
    const params = wrap_params_with_token({"filterInfo": data}); 
    const res = await axiosInstance.post(url, params);
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}


// Save Filter.
export async function saveFilter(data:any): Promise<any> {
    let url = `/saveFilter`;
    // console.log(data)
    const params = wrap_params_with_token({"filterInfo": data}); 
    const res = await axiosInstance.post(url, params);
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}

//getinstanceinfo
export async function getInstanceInfo(dataset:any, dialogue:any): Promise<any> {
    let url = `/getInstanceInfo`;
    // console.log(dataset, dialogue)
    const params = wrap_params_with_token({"datasetID": dataset, "instanceID": dialogue }); 
    const res = await axiosInstance.get(url, {params});
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}

//getInstanceAuxInfo
export async function getInstanceAuxInfo(dataset:any, dialogue:any): Promise<any> {
    let url = `/getInstanceAuxInfo`;
    // console.log(dataset, dialogue)
    const params = wrap_params_with_token({"datasetID": dataset, "instanceID": dialogue }); 
    const res = await axiosInstance.get(url, {params});
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}

// getPastJailbreakPrompts
export async function getPastJailbreakPromptsList(data:any): Promise<any> {
    let url = `/getPastJailbreakPromptsList`;
    console.log(data)
    const params = wrap_params_with_token({"promptsIDList": data}); 
    const res = await axiosInstance.post(url, params);
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}

// results = post_request("getPastJailbreakPromptsHighlightList", {"datasetID": 1, "instanceID": 1, "turnID": 0, "promptsIDList": [0, 1, 2]})

export async function getPastJailbreakPromptsHighlightList(data:any, datasetID: any, instanceID: any, turnID: any): Promise<any> {
    let url = `/getPastJailbreakPromptsHighlightList`;
    console.log(data)
    const params = wrap_params_with_token({"promptsIDList": data, "datasetID": datasetID, "instanceID": instanceID, "turnID": turnID}); 
    const res = await axiosInstance.post(url, params);
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}


/*
### Normal
results = get_request("getFilterClusterInfo", {"filterID": 2})
print(results["results"]["cluster_results"]["Clusters"][0])
*/
export async function getFilterClusterInfo(filterID:any):Promise<any>{
    let url = `/getFilterClusterInfo`;
    const params = wrap_params_with_token({"filterID": filterID}); 
    const res = await axiosInstance.get(url, {params});
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}

/*
### Normal
results = get_request("getFilterProjectionInfo", {"filterID": 3})
print(results["results"].keys())
*/
export async function getFilterProjectionInfo(filterID:any):Promise<any>{
    let url = `/getFilterProjectionInfo`;
    const params = wrap_params_with_token({"filterID": filterID}); 
    const res = await axiosInstance.get(url, {params});
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}

/*
### Normal
instancesIDs = [0, 1, 2]
datasetID = 1
results = post_request("getClusterInfoFromIDs", {"instancesIDs": instancesIDs, "datasetID": datasetID})
print(results["cluster_results"]["Clusters"][0])
*/
export async function getClusterInfoFromIDs(instancesIDs:any, datasetID:any): Promise<any> {
    let url = `/getClusterInfoFromIDs`;
    const params = wrap_params_with_token({"instancesIDs": instancesIDs, "datasetID": datasetID}); 
    const res = await axiosInstance.post(url, params);
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}

export async function getClusterInfoFromIDs_V2(instancesIDs:any, datasetID:any, jailbreakPromptIDs: any): Promise<any> {
    let url = `/getClusterInfoFromIDs_V2`;
    const params = wrap_params_with_token({"instancesIDs": instancesIDs, "datasetID": datasetID, "jailbreakPromptIDs": jailbreakPromptIDs}); 
    const res = await axiosInstance.post(url, params);
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}

export async function getCodeFromTemplate(model_selector_array:any, language_selector_array:any, turns_array: any, malicious_categories_array:any): Promise<any> {
    let url = `/getCodeFromTemplate`;
    const params = wrap_params_with_token({
        "model_selector_array": model_selector_array,
        "language_selector_array": language_selector_array,
        "turns_array": turns_array,
        "malicious_categories_array": malicious_categories_array
    }); 
    const res = await axiosInstance.post(url, params);
    if (res.status === 200) {
        return res.data;
    }
    throw res;
}

