
import React from 'react';
import { Select, Button, Modal, Input, Collapse, Slider, InputNumber, Row, Col } from 'antd';

const { Panel } = Collapse;
const Option = Select.Option;

export interface TemplateConfigPanelProps {
  dataset_info: any;
  generateCallback: any
}

export interface TemplateConfigPanelState {
  selectedModels: string[];
  selectedLanguages: string[];
  selectedMaliciousCategories: string[];
  selectedTurns: any;
}

export default class TemplateConfigPanel extends React.Component<TemplateConfigPanelProps, TemplateConfigPanelState> {
  constructor(props: TemplateConfigPanelProps) {
    super(props);
    this.state = {
      selectedModels: [],
      selectedLanguages: ["English"],
      selectedMaliciousCategories: ["harassment", "sexual", "self-harm", "violence", "hate"],
      selectedTurns: [1, 1],
    };
  }
  componentDidUpdate(prevProps: Readonly<TemplateConfigPanelProps>, prevState: Readonly<TemplateConfigPanelState>, snapshot?: any): void {
      if(prevProps.dataset_info !== this.props.dataset_info){
        let dataset_info = this.props.dataset_info;
        let required_keys = ["language_dict", "malicious_types_dict", "model_dict", "turns_meta_info"];
        let flagged = true;
        for (let i = 0; i < required_keys.length; i++) {
          if (!dataset_info.hasOwnProperty(required_keys[i])) {
            flagged = false;
            break;
          }
        }
        this.setState({
            selectedModels: [],
            selectedLanguages: ["English"],
            selectedMaliciousCategories: ["harassment", "sexual", "self-harm", "violence", "hate"],
            selectedTurns: [1, this.props.dataset_info["turns_meta_info"]["max"]]
        })
      }
  }
  handleModelChange = (selectedModels: string[]) => {
    this.setState({ selectedModels });
  };

  handleLanguageChange = (selectedLanguages: string[]) => {
    this.setState({ selectedLanguages });
  };

  handleMaliciousCategoryChange = (selectedMaliciousCategories: string[]) => {
    this.setState({ selectedMaliciousCategories });
  };

  handleTurnsChange = (selectedTurns: any) => {
    this.setState({ selectedTurns });
  };
  handleGenerate = () => {
    const { selectedModels, selectedLanguages, selectedMaliciousCategories, selectedTurns } = this.state;
    this.props.generateCallback({
        selectedModels, selectedLanguages, selectedMaliciousCategories, selectedTurns 
    })
    /*
    // Perform the desired action when the Generate button is clicked
    console.log('Generate button clicked');
    // You can access the selected values from the state variables:
    console.log('Selected Models:', selectedModels);
    console.log('Selected Languages:', selectedLanguages);
    console.log('Selected Malicious Categories:', selectedMaliciousCategories);
    console.log('Selected Turns:', selectedTurns);*/
  };
  generateFilterTemplate = () => {
    const { dataset_info } = this.props;
    const { selectedModels, selectedLanguages, selectedMaliciousCategories, selectedTurns } = this.state;

    let required_keys = ["language_dict", "malicious_types_dict", "model_dict", "turns_meta_info"];
    let flagged = true;
    for (let i = 0; i < required_keys.length; i++) {
      if (!dataset_info.hasOwnProperty(required_keys[i])) {
        flagged = false;
        break;
      }
    }
    let language_list: any = [];
    let malicious_types_list: any = [];
    let model_list: any = [];
    let max_turns = 1;
    let min_turns = 1;
    if (flagged) {
      language_list = Object.keys(dataset_info['language_dict']);
      malicious_types_list = Object.keys(dataset_info["malicious_types_dict"]);
      model_list = Object.keys(dataset_info["model_dict"]);
      max_turns = dataset_info["turns_meta_info"]["max"];
    }

    return <Collapse>
        <Panel header=" &nbsp; &nbsp; Filter Template" key="filterTemplate">
          <div>
            <Row>
              <Col span={3}>
                <span>Model Name: &nbsp;</span>
              </Col>
              <Col span={21}>
                <Select mode="multiple" style={{ width: "100%" }} value={selectedModels} onChange={this.handleModelChange}>
                  {model_list.map((model: any) => (
                    <Option key={model} value={model}>
                      {model}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col span={3}>
                <span>Language: &nbsp;</span>
              </Col>
              <Col span={21}>
                <Select mode="multiple" style={{ width: "100%" }} value={selectedLanguages} onChange={this.handleLanguageChange}>
                  {language_list.map((language: any) => (
                    <Option key={language} value={language}>
                      {language}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col span={3}>
                <span>Malicious Category: &nbsp;</span>
              </Col>
              <Col span={21}>
                <Select mode="multiple" style={{ width: "100%" }} value={selectedMaliciousCategories} onChange={this.handleMaliciousCategoryChange}>
                  {malicious_types_list.map((category: any) => (
                    <Option key={category} value={category}>
                      {category}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col span={3}>
                <span>Turn: &nbsp;</span>
              </Col>
              <Col span={12}>
                <Slider min={min_turns} range value={selectedTurns} max={max_turns}  onChange={this.handleTurnsChange}/>
              </Col>
              <Col span={9}>
                <InputNumber style={{ marginLeft: '10px', marginRight: '10px' }} min={min_turns}
                 value={selectedTurns[0]} max={max_turns} 
                 onChange={(value: any) =>{this.handleTurnsChange([value, selectedTurns[1]]);}}
                />
                <span>-</span>
                <InputNumber style={{ marginLeft: '10px' }} min={min_turns} 
                value={selectedTurns[1]} max={max_turns}
                onChange={(value) => {this.handleTurnsChange([selectedTurns[0], value])}}   />
              </Col>
            </Row>
          </div>
          <Button type="primary" onClick={this.handleGenerate}>Generate</Button>
        </Panel>
      </Collapse>
    
  }
  public render() {
    return this.generateFilterTemplate();
  }
}


/*import * as React from 'react';

import { Select, Button, Modal, Input, Collapse,  Slider, InputNumber, Row, Col } from 'antd';
const { Panel } = Collapse;
const Option = Select.Option;

export interface TemplateConfigPanelProps {
    // dataset_id: number | null,   
    dataset_info: any
}

export interface TemplateConfigPanelState {}

export default class TemplateConfigPanel extends React.Component<TemplateConfigPanelProps, TemplateConfigPanelState> {
    constructor(props: TemplateConfigPanelProps) {
        super(props);
        this.state = {
            
        };
    }
    public render() {
        let {dataset_info} = this.props;
        let generateFilterTemplate = () => {
            console.log("dataset_info", dataset_info);
            let required_keys = [
                "language_dict",
                "malicious_types_dict",
                "model_dict",
                "turns_meta_info"
            ]
            let flagged = true;
            for(let i = 0; i<required_keys.length; i++){
                if(!dataset_info.hasOwnProperty(required_keys[i])){
                    flagged = false;
                    break;
                }
            }
            let language_list: any = [];
            let malicious_types_list: any = [];
            let model_list: any = [];
            let max_turns = 1;
            let min_turns = 1;
            if(flagged){
                language_list = Object.keys(dataset_info['language_dict']);
                malicious_types_list = Object.keys(dataset_info["malicious_types_dict"]);
                model_list = Object.keys(dataset_info["model_dict"]);
                max_turns = dataset_info["turns_meta_info"]["max"];
            }
            return (
                <Collapse>
                    <Panel header=" &nbsp; &nbsp; Filter Template" key="filterTemplate">
                    <div>
                        <Row>
                            <Col span={3}>
                            <span>Model Name: &nbsp;</span>
    
                            </Col>
                            <Col span={21}>
                            <Select mode="multiple" style={{width: "100%"}}>
                                {model_list.map((model: any) => (
                                <Option key={model} value={model}>
                                    {model}
                                </Option>
                                ))}
                            </Select>
                            </Col>
                        </Row>
    
                    </div>
                    <div>
                        <Row>
                            <Col span={3}>
                            <span>Language: &nbsp;</span>
    
                            </Col>
                            <Col span={21}>
                            <Select mode="multiple" style={{width: "100%"}}>
                        {language_list.map((model: any) => (
                                <Option key={model} value={model}>
                                    {model}
                                </Option>
                                ))}
                        </Select>
                            </Col>
                        </Row>
    
                    </div>
                    <div>
                        <Row>
                        <Col span={3}>
                        <span>Malicious Category: &nbsp;</span>
    
                        </Col>
                        <Col span={21}>
                            <Select mode="multiple" style={{width: "100%"}}>
                            {malicious_types_list.map((model: any) => (
                                <Option key={model} value={model}>
                                    {model}
                                </Option>
                                ))}
                        </Select>
                        </Col>
                        </Row>
    
                    </div> 
                    <div>
                        <Row>
                        <Col span={3}>
                        <span>Turn: &nbsp;</span>
    
                        </Col>
                        <Col span={12}>
                            <Slider min={min_turns}  range defaultValue={[min_turns, max_turns]}  max={max_turns}/>
    
                        </Col>
                        <Col span={9}>
                        <InputNumber style={{ marginLeft: '10px', marginRight: '10px' }} min={min_turns} defaultValue={max_turns} max={max_turns}/>
                        <span>-</span>
                        <InputNumber style={{ marginLeft: '10px' }} min={min_turns} defaultValue={max_turns} max={max_turns}/>
                        </Col>
                        </Row>
    
                    </div>
                    <Button type="primary">Generate</Button>
                    </Panel>
                </Collapse>
                );
        }
            
        return generateFilterTemplate();
    }
}
*/