import BookMarker from '../components/DataRuns/InstanceView/BookMarker'
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

//import { decrement, increment } from '../actions';
import { StoreState } from '../types';
import { changeDialogueID , changeTurnID} from '../actions';


// 将 reducer 中的状态插入到组件的 props 中
const mapStateToProps = (state: StoreState) => ({
    datasetID: state.dataset_id,
    prompt_collection: state.prompt_collection
})

// 将 对应action 插入到组件的 props 中
const mapDispatchToProps = (dispatch: Dispatch) => ({
    changeDialogueID: (dialogueID: number) => dispatch(changeDialogueID(dialogueID)),
    setCurrentTurn: (turn_id: number) => dispatch(changeTurnID(turn_id)),

})

// 使用 connect 高阶组件对 Counter 进行包裹
export default connect(mapStateToProps, mapDispatchToProps)(BookMarker);



