import * as React from "react";
import { Select, Button, Modal, Input, Collapse,  Slider, InputNumber, Row, Col } from 'antd';
import { message } from 'antd';

import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/python/python';
import TemplateConfigPanel from "./TemplateConfigPanel";
import { getFilterList, getDatasetList, deleteFilter, getDatasetInfo, getFilterInfo, testFilter, saveFilter, getCodeFromTemplate } from '../../../service/dataService';

const { Panel } = Collapse;

const Option = Select.Option;

export interface ConfigPanelProps {
    // current_filter: number | null,
    setCurrentFilter: any,
    visible: boolean,
    onClose: () => void,
    // current_dataset: number | null,
    // setCurrentDataset: any
}

export interface ConfigPanelState {
    filter_list: any,
    editFilterName: any,
    dataset_list: any,
    dataset_instance: number | null,
    dataset_info: any,
    filter_instance: number | null,
    filter_code: any,
    error_message: any,

    current_dataset: any,
    current_filter: any,
    current_test_loading: any,
    current_save_loading: any
}
class TemplateCodeConfig {

}
export default class ConfigPanel extends React.Component<ConfigPanelProps, ConfigPanelState>{
    public codeMirrorRef:any;
    constructor(props: ConfigPanelProps) {
        super(props);
        this.codeMirrorRef = React.createRef();
        this.updateFilterList = this.updateFilterList.bind(this);
        this.onDatasetSelectorChange = this.onDatasetSelectorChange.bind(this);
        this.onFilterSelectorChange = this.onFilterSelectorChange.bind(this);
        this.handleFilterNameChange = this.handleFilterNameChange.bind(this);
        this.atDeleteFilter = this.atDeleteFilter.bind(this);
        this.getDatasetInstanceNumber = this.getDatasetInstanceNumber.bind(this);
        this.handleTest = this.handleTest.bind(this);
        this.handleSave = this.handleSave.bind(this)
        this.state = {
            filter_list: [],
            editFilterName: "",
            dataset_list: [],
            dataset_instance: null,
            filter_instance: null,
            dataset_info: {},
            filter_code: "",
            error_message: "",

            current_dataset: null,
            current_filter: null,
            current_test_loading: false,
            current_save_loading: false
        }
    }

    public componentDidMount() {
    }

    componentDidUpdate(prevProps: ConfigPanelProps, prevState: ConfigPanelState) {
        // 当visible状态从false变为true时，调用set_initFilterList
        if (!prevProps.visible && this.props.visible) {
            // console.log(this.props.current_filter);
            this.updateFilterList();
            this.updateDatasetList();
            this.getDatasetInstanceNumber();
            this.updateFilterInfo();
            if (this.codeMirrorRef.current) {
                this.codeMirrorRef.current.editor.refresh();
            }
        }
        if (prevState.current_filter !== this.state.current_filter) {
            const currentFilter = this.state.filter_list.findIndex((filter: any) => filter.filterID === this.state.current_filter);
            const filterName = this.state.filter_list[currentFilter]?.filterInfo?.filterName || '';
            this.setState({ editFilterName: filterName });
            this.updateFilterInfo();
            this.getDatasetInstanceNumber()
        }
        if (prevState.current_dataset !== this.state.current_dataset) {
            if (this.state.current_dataset){
                this.getDatasetInstanceNumber()
            }   
        }
    }

    getDatasetInstanceNumber = () => {
        getDatasetInfo(this.state.current_dataset).then((results: any) => {
            if (results["success"]) {
                let data = results["results"];
                this.setState({
                    dataset_instance: data["total_num"],
                     dataset_info: data
                })
            }
        }).catch((reason: any) => {
            message.error("" + reason);
        });
    }

    handleCancel = () => {
        this.props.onClose()
    };

    handleFilterNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ editFilterName: e.target.value });
    };

    updateFilterList = () => {
        getFilterList().then((results: any) => {
            let current_filter = this.state.current_filter;
            if (results["success"]) {
                let data = results["results"];
                this.setState({
                    filter_list: data["filter_list"]
                })
                const currentFilter = this.state.filter_list.findIndex((filter: any) => filter.filterID === current_filter);
                const filterName = this.state.filter_list[currentFilter]?.filterInfo?.filterName || '';
                this.setState({ editFilterName: filterName });
            }
        }).catch((reason: any) => {
            message.error("" + reason);
        });
    }

    updateDatasetList = () => {
        getDatasetList().then((results: any) => {
            if (results["success"]) {
                let data = results["results"];
                this.setState({
                    dataset_list: data
                })
            }
        }).catch((reason: any) => {
            message.error("" + reason);
        });
    }

    public onFilterSelectorChange(value: number) {
        // this.setStateCurrentFilter(value);
        // this.setStateCurrentDataset(this.state.filter_list[0]["filterInfo"]["datasetID"])
        const currentFilter = this.state.filter_list.findIndex((filter: any) => filter.filterID === value);
        console.log("value", currentFilter, this.state.filter_list);

        if(currentFilter >= 0 && currentFilter < this.state.filter_list.length){
            this.setState({
                "current_filter": value,
                "current_dataset": this.state.filter_list[currentFilter]["filterInfo"]["datasetID"]
            })
        }else{
            this.setState({
                "current_filter": value,
            })
        }

        // this.props.setCurrentFilter(value);
        // this.props.setCurrentDataset(this.state.filter_list[0]["filterInfo"]["datasetID"]);
    }

    public onDatasetSelectorChange(value: number) {
        this.setStateCurrentDataset(value);
        // this.props.setCurrentDataset(value);
    }
    setStateCurrentFilter(value: any){
        this.setState({
            current_filter: value
        })
    }
    setStateCurrentDataset(value: any){
        this.setState({
            current_dataset: value
        })
    }
    public atDeleteFilter() {
        deleteFilter(this.state.current_filter).then((results: any) => {
            if (results["success"]) {
                message.info('Filter deleted successfully.');
                this.updateFilterList();
                this.setStateCurrentFilter(null);
                this.props.setCurrentFilter(null);
            }
        }).catch((reason: any) => {
            message.error("" + reason);
        });
    }


    updateFilterInfo = () => {
        getFilterInfo(this.state.current_filter).then((results: any) => {
            if (results["success"]) {
                let data = results["results"];
                this.setState({
                    filter_instance: data["details"]["filtered_instances_num"],
                    filter_code: data["filterInfo"]["filterCode"]
                })
            }
        }).catch((reason: any) => {
            message.error("" + reason);
        });
    }

    public handleTest(){
        const currentDataset = this.state.dataset_list.findIndex((filter: any) => filter.datasetID === this.state.current_dataset);
        let testdata = {
            "filterName": this.state.editFilterName,
            "filterCode": this.state.filter_code,
            "datasetName": this.state.dataset_list[currentDataset],
            "datasetID": this.state.current_dataset
        }
        message.info("Testing filters...");
        this.setState({
            current_test_loading: true
        })
        testFilter(testdata).then((results: any) => {
            if (results["success"]) {
                message.info("Successfully tested!");
                let data = results;
                this.setState({
                    error_message: data["error_message"],
                    filter_instance: data["filtered_instances_num"],
                    current_test_loading: false
                })
            }else{
                let data = results;
                this.setState({
                    error_message: data["error_message"],
                    filter_instance: null,
                    current_test_loading: false

                })
            }
        }).catch((reason: any) => {
            message.error("" + reason);
            this.setState({
                current_test_loading: false
            })
        });
    }


    public handleSave(){
        const currentDataset = this.state.dataset_list.findIndex((filter: any) => filter.datasetID === this.state.current_dataset);
        let savedata = {
            "filterName": this.state.editFilterName,
            "filterCode": this.state.filter_code,
            "datasetName": this.state.dataset_list[currentDataset],
            "datasetID": this.state.current_dataset
        }
        message.info("Saving the filters.");
        this.setState({
            current_save_loading: true
        })
        saveFilter(savedata).then((results: any) => {
            if (results["success"]) {
                message.info("Successfully save the filters.");
                let data = results["results"];
                this.updateFilterList();
                // this.setStateCurrentFilter(data["filterID"])
                this.setState({
                    current_filter: data["filterID"],
                    current_save_loading: false

                })
                this.props.setCurrentFilter(data["filterID"]);
            }else{
                this.setState({
                    current_save_loading: false
                })
            }

        }).catch((reason: any) => {
            message.error("" + reason);
            this.setState({
                current_save_loading: false
            })
        });
    }
    onClickGenerate = (config: any) => {
        let  { selectedModels, selectedLanguages, selectedMaliciousCategories, selectedTurns } = config;
        console.log("onClickGenerate, ", config);
        getCodeFromTemplate(selectedModels, selectedLanguages, selectedTurns, selectedMaliciousCategories).then((results: any)=>{
            if (results["success"]) {
                message.info("Generate code successfully!");
                console.log("results", results);
                if(results.hasOwnProperty("results") && results["results"].hasOwnProperty("code")){
                    this.setState({
                        filter_code: results["results"]["code"]
                    })
                }
            }
        }).catch((reason: any) => {
            message.error("" + reason);
        });
    }
    public render() {
        let disabledFilterSelector = this.state.filter_list.length <= 0;
        let disabledDatasetSelector = this.state.dataset_list.length <= 0;
        

       


        return (
            <Modal
                title="Filters Configuration Panel" visible={this.props.visible}
                onCancel={this.handleCancel}
                width="60%"
                footer={[
                ]}>
                <div style={{ width: '100%' }}>
                    Filter: &nbsp;
                    <Select
                        placeholder="Select a filter"
                        value={this.state.current_filter}
                        style={{ width: '30%' }}
                        onChange={this.onFilterSelectorChange}
                        disabled={disabledFilterSelector}
                    >
                        {this.state.filter_list.map((d: any) => (
                            <Option value={d.filterID} key={d.filterID}>
                                {d.filterInfo.filterName}
                            </Option>
                        ))}
                    </Select>
                    <Button
                        type="link"
                        onClick={() => {
                            if (this.state.current_filter !== null) {
                                Modal.confirm({
                                    title: 'Confirm Deletion',
                                    content: 'Are you sure you want to delete this filter?',
                                    onOk: () => {
                                        this.atDeleteFilter();
                                    },
                                    footer: (_, { OkBtn, CancelBtn }) => (
                                        <>
                                        <CancelBtn />
                                        <OkBtn />
                                        </>
                                    ),
                                });
                            }else{
                                message.info('No filter is selected for deletion.');
                            }
                        }}
                        >
                            Delete?
                    </Button>
                </div>

                <div style={{ width: '100%', marginTop: "5px"}}>
                    Filter Name: &nbsp;
                    {/* {this.props.current_filter && this.state.filter_list.length > 0 ? */}
                        <Input
                        value={this.state.editFilterName}
                        placeholder="Input your filter name"
                        onChange={this.handleFilterNameChange}
                        style={{ width: '40%' }}
                        />
                        {/* : null} */}
                </div>

                <div style={{ width: '100%', marginTop:'4px'}}>
                    Dataset: &nbsp;  
                    <Select
                        placeholder="Select a dataset"
                        value={this.state.current_dataset}
                        style={{ width: '30%' , marginRight:'4px'}}
                        onChange={this.onDatasetSelectorChange}
                        disabled={disabledDatasetSelector}
                    >
                        {this.state.dataset_list.map((d:any)=>(
                            <Option value={d.datasetID} key={d.datasetID}>
                                {d.datasetName}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div style={{ width: '100%', marginTop: '8px'}}>
                    #Dataset Instances: &nbsp;
                    {this.state.dataset_instance}
                </div>
                
                <div style={{ width: '100%', marginTop:'4px'}}>
                    <TemplateConfigPanel dataset_info={this.state.dataset_info} generateCallback={this.onClickGenerate}></TemplateConfigPanel>
                </div>


                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                    <div style={{ flex: '1', marginRight: '1%', padding: '5px' }}>
                        Filter Code: &nbsp;
                        <div style={{ width: '100%',
                                    marginTop: "5px",
                                    border: '1px solid #ccc'}}>
                            <CodeMirror
                                ref={this.codeMirrorRef}
                                value={this.state.filter_code}
                                options={{
                                    mode: 'python',
                                    theme: 'default',
                                    lineNumbers: true,
                                    lineWrapping: true,
                                }}
                                onBeforeChange={(editor, data, value) => {
                                    this.setState({ filter_code: value });
                                }}
                                onChange={(editor, data, value) => {
                                    // Handle the change if needed
                                    this.setState({ filter_code: value });
                                }}
                            />
                        </div>
                    </div>
                    <div style={{ flex: '1', marginLeft: '1%', padding: '5px' }}>
                        Error: &nbsp;
                        <div style={{ width: '100%',
                                    marginTop: "5px",
                                    border: '1px solid #ccc'}}>
                            <CodeMirror
                                value={this.state.error_message}
                                options={{
                                    mode: 'python',
                                    theme: 'default',
                                    lineNumbers: false,
                                    lineWrapping: true,
                                }}
                                onBeforeChange={(editor, data, value) => {
                                    this.setState({ error_message: value });
                                }}
                                onChange={(editor, data, value) => {
                                    // Handle the change if needed
                                    this.setState({ error_message: value });
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div style={{ width: '100%', marginTop: '8px'}}>
                    #Filter Instances: &nbsp;
                    {this.state.filter_instance}
                </div>

                <div style={{ marginTop: '16px', display: 'flex'}}>
                <Button type="primary" loading={this.state.current_test_loading} style={{ marginRight: '8px' }} onClick={this.handleTest}>Test</Button>
                <Button type="default" loading={this.state.current_save_loading} onClick={this.handleSave}>Save</Button>
                </div>

            </Modal>
        );
    }
}

