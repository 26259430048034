import { DIALOGUE_ID_CHANGE, FILTER_ID_CHANGE, DATASET_ID_CHANGE, 
    SELECTED_CLUSTER_CHANGE, SELECTED_INSTANCES_CHANGE, 
    PROJ_LAYOUT_CONFIG_CHANGE, TURN_ID_CHANGE, 
    SELECTED_PAST_JAILBREAK_PROMPT_INDEX_CHANGE, HIGHLIGHTED_QUERY_CHANGE,
    TRANSLATED_TEXT_CHANGE, 
    PROMPT_BOOKMARK} from '../constants';

// Define Change Model Methods
export const changeDialogueID = (dialogue_id: number) =>({
    type: DIALOGUE_ID_CHANGE,
    dialogue_id: dialogue_id
})

export const changeFilterID = (filter_id: number) =>({
    type: FILTER_ID_CHANGE,
    filter_id: filter_id
})

export const changeDatasetID = (dataset_id: number) =>({
    type: DATASET_ID_CHANGE,
    dataset_id: dataset_id
})

export const changeTurnID = (turn_id: number) =>({
    type: TURN_ID_CHANGE,
    turn_id: turn_id
})


export const changeSelectedCluster = (selected_cluster: any) =>({
    type: SELECTED_CLUSTER_CHANGE,
    selected_cluster: selected_cluster
})

export const changeSelectedInstances = (selected_instance: any) =>({
    type: SELECTED_INSTANCES_CHANGE,
    selected_instance: selected_instance
})

export const changeProjLayoutConfig = (proj_layout_config: any) => ({
    type: PROJ_LAYOUT_CONFIG_CHANGE,
    proj_layout_config: proj_layout_config
})

export const changePastJailbreakPromptIndex = (selected_pastjailbreakprompt_index: any) => ({
    type: SELECTED_PAST_JAILBREAK_PROMPT_INDEX_CHANGE,
    selected_pastjailbreakprompt_index: selected_pastjailbreakprompt_index
})

export const changeHighlightedQuery = (highlighted_query: any) => ({
    type: HIGHLIGHTED_QUERY_CHANGE,
    highlighted_query: highlighted_query
})

export const changeTranslationText = (translated_text: any) => ({
    type: TRANSLATED_TEXT_CHANGE,
    translated_text: translated_text
})

export const promptBookmark = (prompt_config: any) => ({
    type: PROMPT_BOOKMARK,
    prompt_config: prompt_config
})