import * as React from 'react';
import PastPromptContainer from '../../../container/PastPromptContainer'
import {Switch} from 'antd';
export interface RelationViewProps {
    width: number,
    height: number
}

export interface RelationViewState {
    checked: boolean,
    keywords_checked: boolean


}

export default class RelationView extends React.Component<RelationViewProps, RelationViewState> {
    constructor(props: RelationViewProps) {
        super(props);
        this.state = {
            checked: true,
            keywords_checked: true
 
        };
    }
    
    handleSwitchChange = (checked: any) => {
        this.setState({ checked });
      };
    handleKeywordsSwitchChange = (keywords_checked: any) => {
        this.setState({keywords_checked});
    }
    public render() {
        return (
            <div style={{ width: "100%", height: "100%"}}>
            <div className="ViewTitle">Relation View
            <div style={{"float": "right"}}>
                Keywords: <Switch
                    checked={this.state.keywords_checked}
                    onChange={this.handleKeywordsSwitchChange}
                    size="small"
                ></Switch>
                &nbsp;
                Compare: <Switch
                    checked={this.state.checked}
                    onChange={this.handleSwitchChange}
                    size="small"
                    />
            </div>
            </div>
            <div className="ViewBox" style={{ width: "100%", height: "95%"}}>
                <PastPromptContainer width={this.props.width} 
                height={this.props.height} compare_mode={this.state.checked} keywords_mode={this.state.keywords_checked}/>
            </div>
            </div>
            
        );
    }
}
