import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { StoreState } from '../types';
import LoginPanel from '../components/DataRuns/LoginPanel';
import { changeFilterID } from '../actions';
import { changeDatasetID } from '../actions';
import { changeDialogueID, changeSelectedInstances } from '../actions';

const mapStateToProps = (state: StoreState) => ({

})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setCurrentFilter: (filter_id: number) => dispatch(changeFilterID(filter_id)),
    setCurrentDataset: (dataset_id: number) => dispatch(changeDatasetID(dataset_id)),
    changeSelectedInstances: (selected_instances: any) => dispatch(changeSelectedInstances(selected_instances)),
    changeDialogueID: (dialogue_id: any) => dispatch(changeDialogueID(dialogue_id))

})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPanel);



