import * as React from 'react';
import { getInstanceInfo,getInstanceAuxInfo,getPastJailbreakPromptsList, getPastJailbreakPromptsHighlightList } from "../../../service/dataService";
import { message,Card, Button, Table, Radio, Row, Col, Tooltip } from 'antd';
import { getSimilarityColor, hexToRgbA, type_descriptions } from '../../../helper';

const d3 = require("d3");

export interface PastPromptProps {
    dialogue_id: number,
    dataset_id: number | null,
    turn_id: number,
    width: number,
    height: number,
    changeHighlightQuery: any,
    compare_mode: any,
    keywords_mode: any
}

export interface PastPromptState {
    dialogue_results: any,
    similarprompt:any,
    similarpromptlist: any
    allprompt: any,
    alldata: any,
    refreshnumber: number,
    selected_one_prompt: number
}

export default class PastPrompt extends React.Component<PastPromptProps, PastPromptState> {
    private similarityRef: React.RefObject<HTMLDivElement>;

    constructor(props: PastPromptProps) {
        super(props);
        this.similarityRef = React.createRef<HTMLDivElement>();
        this.state = {
            dialogue_results: [],
            similarprompt:[],
            similarpromptlist: [],
            allprompt:{},
            alldata:{},
            refreshnumber: 0, 
            selected_one_prompt: 0
        };
    }


    public componentDidUpdate(prevProps: PastPromptProps, prevState: PastPromptState) {
        if (prevProps.dialogue_id !== this.props.dialogue_id || prevProps.dataset_id !== this.props.dataset_id) {
            this.updateDialogueInfo();
            return;
        }else{
            if(this.props.turn_id !== prevProps.turn_id) {
                this.updateTurns();
                return;
            }
        }
        if(prevProps.keywords_mode !== this.props.keywords_mode){
            this.changeHighlightQuery(this.state.selected_one_prompt);
        }
    }
    getSimilarPrompt(auxinfo: any){
        if(auxinfo.hasOwnProperty("details"))
        {
            if(auxinfo["details"].hasOwnProperty("query_level_similarity_to_past_jailbreak_prompts_info")){
                let info = auxinfo["details"]["query_level_similarity_to_past_jailbreak_prompts_info"];
                if(info.hasOwnProperty("turn_level_topk_info")){
                    let topk_info = info["turn_level_topk_info"];
                    if(this.props.turn_id >= 0 && this.props.turn_id < topk_info.length){
                        return topk_info[this.props.turn_id].topk_info.topk_indices;
                    }
                }
            }
        }
        return [];
        // return auxinfo.details.query_level_similarity_to_past_jailbreak_prompts_info.turn_level_topk_info[this.props.turn_id].topk_info.topk_indices;
    }
    getSimilarities(index: any){
        if(this.getSimilarPrompt(this.state.alldata).length > 0){
            return this.state.alldata.details.query_level_similarity_to_past_jailbreak_prompts_info.turn_level_topk_info[this.props.turn_id].topk_info.topk_similarities[index]
        }else{
            return 0;
        }
    }
    updateDialogueInfo = () => {
        getInstanceInfo(this.props.dataset_id, this.props.dialogue_id).then((results: any) => {
            if (results["success"]) {
                let dialogue_info = results["results"];
                // this.renderInstance(dialogue_info);
                getInstanceAuxInfo(this.props.dataset_id, this.props.dialogue_id).then((results: any) => {
                    if (results["success"]) {
                        let auxinfo = results["results"];
                        this.setState({
                            dialogue_results: dialogue_info,
                            similarprompt: this.getSimilarPrompt(auxinfo),
                            alldata: auxinfo
                        }, () => {
                            this.getPastprompt()
                        })
                        // this.renderInstance(dialogue_info);
                    }
                }).catch((reason: any) => {
                    message.error("" + reason);
                });
            }
        }).catch((reason: any) => {
            message.error("" + reason);
        });
    }

    updateTurns(){

        this.setState({
            similarprompt:  this.getSimilarPrompt(this.state.alldata), //auxinfo.details.query_level_similarity_to_past_jailbreak_prompts_info.turn_level_topk_info[this.props.turn_id].topk_info.topk_indices,
        }, () => {
            this.getPastprompt()
        })

    }
    
    getPastprompt(){
        getPastJailbreakPromptsHighlightList(this.state.similarprompt, this.props.dataset_id, this.props.dialogue_id, this.props.turn_id).then((results: any) => {
            console.log("getPastJailbreakPromptsListResults", results);

            if (results["success"]) {
                let data = results["results"];
                let pastprompt = []
                for (let i=0; i<this.state.similarprompt.length; i++){
                    let key = this.state.similarprompt[i].toString();
                    if(data.hasOwnProperty(key)){
                        pastprompt.push(data[key])

                    }
                }
                this.setState({
                    similarpromptlist: pastprompt,
                    allprompt: data
                }, () => {
                    this.changeHighlightQuery(this.state.selected_one_prompt)
                    this.drawAllSimilarity();
                })
            }
        }).catch((reason: any) => {
            message.error("" + reason);
        });
    }

    renderSimilarity(prompt:any,index:number){
        // this.drawSimilarity(index)
        const svgId = `pastsimilaritysvg-${index.toString()}`;
        return <svg id={svgId} style={{ width: "100%", height: "23px" }} />
    
    }

    drawAllSimilarity(){
        this.state.similarpromptlist.forEach((item:any, index: number) => {
            this.drawSimilarity(index);
        });
    }

    drawSimilarity(index:number){
        const data = this.getSimilarities(index);

        var width_svg = (this.props.width*0.09),
            height_svg = 15
        var margin = { top: 0, right: 0, bottom: 0, left: 0 };

        /*if (this.similarityRef.current) {
        }
        if (this.similarityRef.current) {
            const width = this.similarityRef.current.offsetWidth;
            const height = this.similarityRef.current.offsetHeight;

            width_svg = width - margin.left - margin.right;
            height_svg = height - margin.top - margin.bottom;
        }*/
        console.log(index)
        const svgId = `pastsimilaritysvg-${index.toString()}`;

        d3.select(`#${svgId}`).selectAll("*").remove();

        
        const svg = d3.select(`#${svgId}`)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        //let width_svg = 120;
        //let height_svg = 20;     
        let margin_y = 5;
        let height = 14;   
        const x = d3.scaleLinear()
            .domain([0, 1])
            .range([0, width_svg]);

        svg.append("rect")
            .attr("x", x(0))
            .attr("y", margin_y)
            .attr("width", x(1)-x(0))
            .attr("height", height)
            .attr("stroke", getSimilarityColor())
            .attr("stroke-width", '2px')
            .attr("fill", "white");

        svg.append("rect")
            .attr("x", x(0))
            .attr("y", margin_y)
            .attr("width", x(data) - x(0))
            .attr("height", height)
            .attr("fill", getSimilarityColor());

        svg.append("text")
            .attr("x", x(0.5))
            .attr("y", 10)
            .text(data.toFixed(2))
            .attr('text-anchor', 'middle')
            .attr('dominant-baseline', 'central')
            .style('font-size', '12px')
            .attr('fill', "gray")
    }
    public handleRadioClick(record: any){
        this.setState({
            selected_one_prompt: record.index
        })
        
        this.changeHighlightQuery(record.index);

        // this.props.changePastJailbreakPromptIndex(record.index);

    }
    changeHighlightQuery = (index: any) => {
        if(index >= 0 && index<this.state.similarprompt.length){
        }else{
            return;
        }

        let key = this.state.similarprompt[index].toString();
        let type = this.getType(index);
        if(type.length > 5 && type.substring(0, 5) === "Start"){
            type = "Start Prompt";
        }
        let keywords_mode = this.props.keywords_mode;
        if(this.state.allprompt.hasOwnProperty(key)){
            let item = this.state.allprompt[key];
            let highlight = item["query_highlight"];
            if(keywords_mode){
                highlight = item["old_query_highlight"];
            }
            highlight["type"] = type;

            this.props.changeHighlightQuery(highlight);
        }else{
            this.props.changeHighlightQuery({});
        }
    }
    getType = (index: any) => {
        let type = "N/A";
        if(index >= 0 && index < this.state.similarprompt.length){
            let key = this.state.similarprompt[index].toString();
            if(this.state.allprompt.hasOwnProperty(key)){
                let item = this.state.allprompt[key];
                if(item["community_name"] === ""){
                    type = "N/A";
                }else{
                    type = item["community_name"];

                }
            }
        }
        return type;
    }
    constructStyleText = (index: any, see_query: any = false) => {
        let keywords_mode = this.props.keywords_mode;
        if(index >= 0 && this.state.similarprompt.length){

            let key = this.state.similarprompt[index].toString();
            let defaultColor = hexToRgbA("#ffffff", 0);

            if(this.state.allprompt.hasOwnProperty(key)){
                let item = this.state.allprompt[key];
                console.log("item in relation view", item);
                let highlight;
                if(!keywords_mode){
                    highlight = item["highlight"];
                    if(see_query){
                        highlight = item["query_highlight"]["highlight"];
                    }
                }else{
                    highlight = item["old_highlight"];
                    if(see_query){
                        highlight = item["old_query_highlight"]["highlight"];
                    }
                }

                let element_list:any[] = highlight.map((d:any)=>{
                    let cutted_text = d["text"];
                    let color = defaultColor;
                    if(d["highlight"]){
                        color = hexToRgbA(getSimilarityColor(), 0.3);
                    }

                    return (<span style={{"background":color}}>
                                {cutted_text}
                            </span>
                    )
                });
                return element_list;
            }else{
                return "";
            }
        }

    }
    constructAugmentedStyleText = (index: any) => {
        if(this.props.compare_mode === false){
            let elements = this.constructStyleText(index);
            return elements;
        }else{
            let elements = this.constructStyleText(index);
            let elements2 = this.constructStyleText(index, true);
            return <Row>
            <Col span={12} style={{ borderRight: "1px solid lightgray", paddingRight: "10px" }}>
              <span style={{ fontWeight: "bold" }}>Past Jailbreak Prompt:</span>
              <br />
              <div >{elements}</div>
            </Col>
            <Col span={12} style={{ paddingLeft: "10px" }}>
              <span style={{ fontWeight: "bold" }}>Currently Inspected Prompt:</span>
              <br />
              <div >{elements2}</div>
            </Col>
          </Row>
        }

    }
    getKeywords = (index: any) => {
        if(index >= 0 && this.state.similarprompt.length){
            let key = this.state.similarprompt[index].toString();
            if(this.state.allprompt.hasOwnProperty(key)){
                let item = this.state.allprompt[key];
                let keywords = item["keywords"];
                let keyword_text = "";
                for(let i = 0; i<keywords.length; i++){
                    if(i>=20 || i == keywords.length - 1){
                        keyword_text += keywords[i][0];
                        break;
                    }
                    keyword_text += keywords[i][0] + ", "
                    // keyword_text += keywords[i][0] + " (" + keywords[i][1] + ") - ";
                }
                return keyword_text;
            }else{
                return "";
            }
        }
        return "";

    }

    
    public render() {
        const { similarpromptlist, selected_one_prompt,} = this.state;
        const {keywords_mode} = this.props;
        console.log("width, height, relation view", this.props.width, this.props.height);
        
        let get_type_description = (type: any) => {
            if(type.length > 5 && type.substring(0, 5) === "Start"){
                type = "Start Prompt";
            }
            console.log("type, ", type, Object.keys(type_descriptions), type_descriptions.hasOwnProperty(type))
            if(type_descriptions.hasOwnProperty(type)){
                let desc:any = type_descriptions;
                return desc[type];
            }else{
                return type;
            }
        }
        let columns : any= [
            {
                title: '',
                key: 'action',
                render: (text: any, record: any) => (
                  <Radio style={{left: "-5px"}}checked={record["index"] == selected_one_prompt}
                   onClick={() => this.handleRadioClick(record)} />
                  // Add render property to render radio buttons and attach onClick event
                ),
                width: "30px"
              },
          {
            title: 'Similarity',
            dataIndex: 'similarity',
            key: 'similarity',
            width: ""+(this.props.width*0.12)+"px"
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: ""+(100)+"px",
            render: (text: any) => (
                <Tooltip title={get_type_description(text)}>
                  <span>{text}</span>
                </Tooltip>
              )
          },
          
          /*{
            title: 'Overlap Keywords',
            dataIndex: 'overlapKeywords',
            key: 'overlapKeywords',
          },*/
        ];
        if(keywords_mode){
            columns.push({
                title: 'Overlap Keywords',
                dataIndex: 'overlapKeywords',
                key: 'overlapKeywords',
              })
        }else{
            columns.push({
                
                    title: 'Past Jailbreak Prompts',
                    dataIndex: 'prompts_prefix',
                    key: 'prompts_prefix'
                  
            })
        }
      
        const expandedRowRender = (record: any) => (
          <div className="expanded-row-content">{record.prompt}</div>
        );
       console.log("render, similarpromptlist", similarpromptlist);
        const data = similarpromptlist.map((prompt: any, index: any) => ({
          key: index,
          expandButton: '',
          clickedButton: '',
          similarity: this.renderSimilarity(prompt, index),
          overlapKeywords: this.getKeywords(index),
          prompt: (index == selected_one_prompt || this.props.compare_mode) ?this.constructAugmentedStyleText(index):prompt.prompt,
          prompts_prefix: (prompt.prompt.length > 90)? prompt.prompt.substring(0, 90) + "...": prompt.prompt,
          expanded: index == selected_one_prompt,
          index: index,
          type: this.getType(index)
        }));
      
        const expandedRowKeys = data.filter((record: any) => record.expanded).map((record: any) => record.key);
      
        return (
          <div id="tableContainer" style={{ width: '100%', height: '100%' }}>
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              expandable={{
                expandedRowRender: expandedRowRender,
              }}
              size="small"
              scroll={{ y: this.props.height  - 60 }} // This enables vertical scrolling. Adjust the height as needed.

            />
          </div>
        );
      }
      
}
