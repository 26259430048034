import { index } from 'd3';
import { RenderingExecutor } from './RenderingExecutor';

const d3 = require("d3");

export class RenderingManager{
  public render_executor: any = null;
  constructor(){
    this.render_executor = new RenderingExecutor();
  }
  public changeBrushMode(brushmode: any){
    this.render_executor.changeBrushMode(brushmode);
  }
  public changeProjLayoutConfig(proj_layout_config: any){
    if(proj_layout_config){
      console.log("changeProjLayoutConfig", proj_layout_config);
      this.render_executor.changeShowLabel(proj_layout_config.showGrid);
      this.render_executor.changeLabelNum(proj_layout_config.gridValue);

      let showContours = [false, false, false];
      let showPoints = [false, false, false];
      let checkedContours = proj_layout_config.checkedContours;
      let checkedPoints = proj_layout_config.checkedPoints;
      let index_dict: any = {
        "attackFail": 0,
        "attackSuccess": 1,
        "pastJailbreakPrompts": 2
      }
      for(let i = 0; i<checkedContours.length; i++){
        if(index_dict.hasOwnProperty(checkedContours[i])){
          let index : any = index_dict[checkedContours[i]];
          showContours[index] = true;
        }
      }
      for(let i = 0; i<checkedPoints.length; i++){
        if(index_dict.hasOwnProperty(checkedPoints[i])){
          let index: any = index_dict[checkedPoints[i]];
          showPoints[index] = true;
        }
      }

      this.render_executor.changeShowContours(showContours);
      this.render_executor.changeShowPoints(showPoints);
      this.render_executor.changeShowLegend(proj_layout_config.showLegend);
    }
  }
  public initDraggedCallback(callback: any){
    this.render_executor.initDraggedCallback(callback);
  }
  public simple_execute_rendering(
    component: any,
    cluster_results: any
  ){
    console.log("cluster_results", cluster_results);
    
    let promptPoints = cluster_results["data_list"].map((item: any)=>{
      return {
        "x": item[0],
        "y": item[1],
        "groupID": item[2],
        "instances_id": item[3],
        "pastjailbreakprompt_id": item[4],
        "instances_text": item[5],
        "pastjailbreakprompt_text": item[6]
      }
    });
    console.log("promptPoints", promptPoints);
    let gridData = cluster_results["grid_dict"];
    this.render_executor.initializeDataFromConfig({
      "component": component,
      "gridData": gridData,
      "promptPoints": promptPoints
    })
    this.render_executor.main_execute();
  
  }
}
