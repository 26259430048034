import createRegl from 'regl';

const d3 = require("d3");

export function initSVGFromComponent(config: any){

    // input:
    // component
    // It should have embedding-svg and embedding-canvas, otherwise it will fail. 
    // Make sure you have initialized svg and canvas first.


    /* sample code.
  <div class="embedding">
    <svg class="top-svg"></svg>
    <canvas class="search-point-canvas hidden"></canvas>
    <canvas class="embedding-canvas"></canvas>
    <canvas class="embedding-canvas-back"></canvas>
    <canvas class="topic-grid-canvas top"></canvas>
    <canvas class="topic-grid-canvas bottom"></canvas>
    <svg class="embedding-svg"></svg>
  </div>
    */ 

    // output:
    /**
     * svg
     * svgFullSize
     * svgPadding
     * svgSize
     * pointCanvas
     * pointRegl
     */
    if(config.component === null){
      return ;
    }


    // Initialize the SVG
    config.svg = d3.select(config.component).select('#embedding-svg'); // embedding-svg is required

    config.svgFullSize = { width: 0, height: 0 };
    const svgBBox = config.svg.node()?.getBoundingClientRect();
    if (svgBBox !== undefined) {
      config.svgFullSize.width = svgBBox.width;
      config.svgFullSize.height = svgBBox.height;
    }

    // Fix the svg width and height
    config.svg
      .attr('width', `${config.svgFullSize.width}px`)
      .attr('height', `${config.svgFullSize.height}px`);

    config.svgPadding = {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    };

    // We keep the initial drawing region as a square
    const squareCanvasWidth = Math.min(
      config.svgFullSize.width - config.svgPadding.left - config.svgPadding.right,
      config.svgFullSize.height - config.svgPadding.top - config.svgPadding.bottom
    );

    config.svgSize = {
      width: squareCanvasWidth,
      height: squareCanvasWidth
    };

    // Initialize the SVG groups
    // this.initSVGGroups();

    // Initialize the top svg element
    // this.topSvg = this.initTopSvg();
    let translateX = (config.svgFullSize.width - config.svgSize.width) / 2;
    let translateY = (config.svgFullSize.height - config.svgSize.height) / 2;
    let scale: number = 1;

    config.curZoomTransform = d3.zoomIdentity.translate(translateX, translateY).scale(scale);
    // Initialize the canvases
    config.pointCanvas = d3
      .select(config.component)
      .select('#embedding-canvas')  // embedding-canvas is required
      .attr('width', `${config.svgFullSize.width}px`)
      .attr('height', `${config.svgFullSize.height}px`);
    config.pointRegl = createRegl(config.pointCanvas!.node() as HTMLCanvasElement);


}

export function initScalesFromGridData(config: any){
  // Input:
  // gridData, svgSize
  // Output:
  // xScale, yScale
      if(config.gridData === null || config.svgSize === null){
        console.log("initScalesFromGridData it is not initialized");
        return;
      }
     // Initialize the data scales
     const xRange = config.gridData.xRange;
     const yRange = config.gridData.yRange;
 
     // Force the plot to be a square
     let xLength = xRange[1] - xRange[0];
     let yLength = yRange[1] - yRange[0];
 
     if (!config.gridData.padded) {
       // Add padding for the data
       if (xLength < yLength) {
         yRange[0] -= yLength / 50;
         yRange[1] += yLength / 50;
         yLength = yRange[1] - yRange[0];
 
         xRange[0] -= (yLength - xLength) / 2;
         xRange[1] += (yLength - xLength) / 2;
       } else {
         // Add padding for the data
         xRange[0] -= xLength / 50;
         xRange[1] += xLength / 50;
         xLength = xRange[1] - xRange[0];
 
         yRange[0] -= (xLength - yLength) / 2;
         yRange[1] += (xLength - yLength) / 2;
       }
     }
 
     config.xScale = d3
       .scaleLinear()
       .domain(xRange)
       .range([0, config.svgSize.width]);
 
       config.yScale = d3
       .scaleLinear()
       .domain(yRange)
       .range([config.svgSize.height, 0]);
}

