import * as RenderInitUtils from './RenderingInitUtils';
import * as RenderWebGLUtils from './RenderingWebGLUtils';
import * as RenderTopicLabelsUtils from './RenderingTopicLabelsUtils';
import { computePosition, flip, shift, offset, arrow } from '@floating-ui/dom';
import {getBorderColor} from '../../../../helper';
const d3 = require("d3");

function invertCanvasToDataCoordinates(canvasX: any, canvasY: any, xScale: any, yScale: any, curZoomTransform: any){
    // Invert to the stage scale => invert to the data scale
    const dataX = xScale.invert(curZoomTransform.invertX(canvasX));
    const dataY = yScale.invert(curZoomTransform.invertY(canvasY));
    return {
      "x": dataX,
      "y": dataY
    }
  }
  function getRegion(startPoint: any, endPoint: any){
    let x_array = [startPoint.x, endPoint.x];
    let y_array = [startPoint.y, endPoint.y];
    let minx = d3.min(x_array);
    let maxx = d3.max(x_array);
    let miny = d3.min(y_array);
    let maxy = d3.max(y_array);
    return {
      "minx": minx,
      "maxx": maxx,
      "miny": miny,
      "maxy": maxy
    }
  }
  
  function determineXYInRegion(x: any, y: any, startPoint: any, endPoint: any){
    let region = getRegion(startPoint, endPoint);
    if(x >= region["minx"] && x <= region["maxx"] && y >= region["miny"] && y <= region["maxy"]){
      return true;
    }else{
      return false;
    }
  }
  
class PointDrawer{
    constructor(){

    }
    main_execute(render_config: any){
        this.initWebGLMatrices(render_config);
        this.initWebGLBuffers(render_config);
        this.drawScatterPlot(render_config);
    }
    initWebGLMatrices(render_config: any){
        RenderWebGLUtils.initWebGLMatrices(render_config);
    }
    initWebGLBuffers(render_config: any){
        RenderWebGLUtils.initWebGLBuffers(render_config);
    }
    updateWebGLBuffers(render_config: any){
        RenderWebGLUtils.initWebGLBuffers(render_config, false);
    }
    drawScatterPlot(render_config: any){
        RenderWebGLUtils.drawScatterPlot(render_config);
    }
    
 }
 class DataInitalize{
    constructor(){

    }
    main_execute(render_config: any){
        this.initSVGFromComponent(render_config);
        this.initScalesFromGridData(render_config);
        this.initGroupData(render_config);

        this.initTopSvg(render_config);
        this.initContentSvg(render_config);
        this.initBrushSvg(render_config);
    }
    initGroupData(render_config: any){
      if (render_config.gridData.groupGrids && render_config.gridData.groupNames) {
        render_config.groupNames = render_config.gridData.groupNames;
      }
    }
    // internal function
    initSVGFromComponent(render_config: any){
    
        // input:
        // component
        // It should have embedding-svg and embedding-canvas, otherwise it will fail. 
        // Make sure you have initialized svg and canvas first.

        // output:
        /**
         * svg
         * svgFullSize
         * svgPadding
         * svgSize
         * pointCanvas
         * pointRegl
         */
        RenderInitUtils.initSVGFromComponent(render_config);

    }

    initScalesFromGridData(render_config: any){
        // Input:
        // gridData, svgSize
        // Output:
        // xScale, yScale
        RenderInitUtils.initScalesFromGridData(render_config);
    }
    
    initTopSvg(config: any){
        // Input: component, svgFullSize, svgPadding
        // Output: topSvg
        const topSvg = d3
          .select(config.component)
          .select('#top-svg')
          .attr('width', `${config.svgFullSize.width}px`)
          .attr('height', `${config.svgFullSize.height}px`)
          .attr(
            'transform',
            `translate(${config.svgPadding.left}, ${config.svgPadding.top})`
          );
    
        const topGroup = topSvg.append('g').attr('class', 'top-group');
    
        topGroup
          .append('rect')
          .attr('class', 'mouse-track-rect')
          .attr('width', config.svgFullSize.width)
          .attr('height', config.svgFullSize.height);

        config.topSvg = topSvg;
      };

      initContentSvg(config: any){
        const contentSvg = d3
          .select(config.component)
          .select('#content-svg')
          .attr('width', `${config.svgFullSize.width}px`)
          .attr('height', `${config.svgFullSize.height}px`)
          .attr(
            'transform',
            `translate(${config.svgPadding.left}, ${config.svgPadding.top})`
          );

        const topGroup = contentSvg.append('g').attr('class', 'top-group');
    
        const topContent = topGroup.append('g').attr('class', 'top-content');

        topContent.append('g').attr('class', 'topics-bottom');
        topContent
        .append('g')
        .attr('class', 'topics');
        topContent.append('g').attr('class', 'topics-top');
        topContent.append('g').attr('class', 'highlights');

        config.contentSvg = contentSvg;
      }

      initBrushSvg(config: any){
        const brushSvg = d3
          .select(config.component)
          .select('#brush-svg')
          .attr('width', `${config.svgFullSize.width}px`)
          .attr('height', `${config.svgFullSize.height}px`)
          .attr(
            'transform',
            `translate(${config.svgPadding.left}, ${config.svgPadding.top})`
          );
    
        const topGroup = brushSvg.append('g').attr('class', 'brush-group');
    
        config.brushSvg = brushSvg;
      }

    
 }

 class ZoomManager{
    public point_drawer: any = null;
    public topicgrid_manager: any = null;
    constructor(point_drawer: any, topicgrid_manager: any){
        this.point_drawer = point_drawer;
        this.topicgrid_manager = topicgrid_manager;
    }   
   /**
   * Handler for each zoom event
   * @param e Zoom event
   */
   public async zoomed(e: any, config: any) {
    // config should consist of 
    // curZoomTransform, point_drawer

    // output: curZoomTransform
    const transform = e.transform;
    const scaleChanged = config.curZoomTransform.k !== transform.k;
    config.curZoomTransform = transform;

    // === Task (1) ===
    // Transform the SVG elements
    config.svg.select('.umap-group').attr('transform', `${transform.toString()}`);

    // Transform the top SVG elements

    config.topSvg
      .select('.top-group')
      .attr('transform', `${transform.toString()}`);
      config.contentSvg
      .select('.top-group')
      .attr('transform', `${transform.toString()}`);

    // Transform the visible canvas elements
    if (config.frontPositionBuffer && config.frontTextureCoordinateBuffer) {
        this.point_drawer.drawScatterPlot(config);    
    }
    this.topicgrid_manager.layoutTopicLabels(config, config.userMaxLabelNum, true);

  };
    /**
   * Event handler for zoom ended
   */
    zoomEnded = (config: any) => {
        // Update the points (the last call during zoomed() might be skipped)
    
        // Adjust the label size based on the zoom level
        this.topicgrid_manager.layoutTopicLabels(config, config.userMaxLabelNum, false);
      };
  initZoomOnTopSvg(config: any){
        // Input: svgSize, layout, topSvg.

        // New Output: zoom
        // Old Output: topSvg

        // Register zoom
        config.zoom = d3
        .zoom()
        .extent([
            [0, 0],
            [config.svgSize.width, config.svgSize.height]
        ])
        .scaleExtent([config.layout.zoomScale[0], config.layout.zoomScale[1]])
        .interpolate(d3.interpolate)
        .on('zoom', (g: any) => {
        (async () => {
            await this.zoomed(g, config);
        })();
        })
        .on('end', () => this.zoomEnded(config));

        config.topSvg.call(config.zoom).on('dblclick.zoom', null);  
        config.topSvg.call(config.zoom.transform, config.curZoomTransform);
        // config.topSvg.on(".zoom", null);

    }

    disableZoomOnTopSvg(config: any){
        config.topSvg.on(".zoom", null);
    }

 }

 class BrushManager{
    public point_drawer: any = null;
  
    public brushStart: any = null;
    public brushEnd: any = null;
    public isDragging: any = false;
    public brushed_callback: any = (selected_points: any) =>{};
    constructor(point_drawer: any){
        this.point_drawer = point_drawer;
    }   
    determinePointInDraggedRegion(dataX: any, dataY: any){
      if(this.isDragging){
        if(this.brushStart && this.brushEnd){
          let startPoint = this.brushStart.transformedDataXY;
          let endPoint = this.brushEnd.transformedDataXY;
          // console.log("dataX, dataY, startPoint, endPoint", dataX, dataY, startPoint, endPoint, determineXYInRegion(dataX, dataY, startPoint, endPoint));
          return determineXYInRegion(dataX, dataY, startPoint, endPoint);
  
        }else{
          return false;
        }
      }else{
        return false;
      }
    }
    updateDataBasedOnDragging(config: any){
      if(!this.isDragging) return;
      let promptPoints = config.promptPoints;
      for(let i = 0; i<promptPoints.length; i++){
        let point = promptPoints[i];
        let dragged = this.determinePointInDraggedRegion(point.x, point.y);
        point.dragged = dragged;
      }
    }
    getDraggedData(config: any, enable_group: any = true){
      let promptPoints = config.promptPoints;
      let dragged_promptPoints = [];
      let showPoints = config.showPoints;
      for(let i = 0; i<promptPoints.length; i++){
        let point = promptPoints[i];
        if(point.hasOwnProperty("dragged")){
          if(point.dragged === true){
            if(enable_group === true){
              if(showPoints[point.groupID] === true){
                dragged_promptPoints.push(point);
  
              }
            }else{
              dragged_promptPoints.push(point);
            }

          }
        }
      }
      return dragged_promptPoints;

    }
    getDraggedDataASR(config: any){
      let dragged_data = this.getDraggedData(config, false);
      let num_instances = 0;
      let success_instances = 0;
      for(let i = 0; i<dragged_data.length; i++){
        let groupID = dragged_data[i].groupID;
        if(groupID === 0 || groupID === 1){
          num_instances += 1;
          if(groupID === 1){
            success_instances += 1;
          }
        }
      }
      let asr = 0;
      if(num_instances > 0){
        asr = success_instances / num_instances;
      }
      return asr;
    }
    clearBrushRect(config: any){
      let brushSvg = config.brushSvg;
      if(brushSvg){
        brushSvg.selectAll("*").remove();

      }
    }
    requestBrushRedraw(config:any){
      // console.log("brush", this.brushStart, this.brushEnd, this.isDragging);
      let brushSvg = config.brushSvg;
  
      // if(this.isDragging){
          if(brushSvg && this.brushStart && this.brushEnd){
            // 1. delete all elements in brush-group 
            // 2. append rect
            let brushrect = brushSvg.selectAll(".brushrect").data([0]);
            let brushrect_enter = brushrect.enter().append("rect");
  
            let canvas_brushStart = this.brushStart.currentXY;
            let canvas_brushEnd = this.brushEnd.currentXY;
  
            let minx = d3.min([canvas_brushStart.x, canvas_brushEnd.x]);
            let maxx = d3.max([canvas_brushStart.x, canvas_brushEnd.x]);
            let miny = d3.min([canvas_brushStart.y, canvas_brushEnd.y]);
            let maxy = d3.max([canvas_brushStart.y, canvas_brushEnd.y]);
  
  
  
            brushrect.merge(brushrect_enter)
            .attr("class", "brushrect")
            .attr("fill", "#eeeeee")
            .style("fill-opacity", 0.3)
             .style("stroke-opacity", 1)
            .attr("stroke", "#bbbbbb")
            .attr("stroke-width", 3)
            .attr("rx", 5)
            .attr("ry", 5)
            .attr("x", minx)
            .attr("y", miny)
            .attr("width", maxx - minx)
            .attr("height", maxy - miny);

          }
            if(brushSvg && !this.isDragging){
              let brushrect = brushSvg.selectAll(".brushrect");
              let asr = this.getDraggedDataASR(config);
              let color = getBorderColor(asr);
              
              brushrect
              .attr("stroke", color);

            }
      /*}else{
        if(brushSvg){
          // brushSvg.selectAll("*").remove();
  
        }
  
      }*/
    }
    requestPointRedraw = (config: any) => {
      // console.log("promptPoints", config.promptPoints);
      this.point_drawer.updateWebGLBuffers(config);
      this.point_drawer.drawScatterPlot(config);
    }
    requestRedraw = (config: any, update_data_first: any = false)=>{
      if(update_data_first){
        this.updateDataBasedOnDragging(config);
        this.requestBrushRedraw(config);
        this.requestPointRedraw(config);
      }else{
        this.requestBrushRedraw(config);
        this.updateDataBasedOnDragging(config);
        this.requestPointRedraw(config);
      }
    }
    mousemoveHandler = (e: MouseEvent, config: any) => {
      // Show tooltip when mouse over a data point on canvas
      // We need to use color picking to figure out which point is hovered over
      const x = e.offsetX;
      const y = e.offsetY;
      let transformedDataXY = invertCanvasToDataCoordinates(x, y, config.xScale, config.yScale, config.curZoomTransform);
      return {
        "transformedDataXY": transformedDataXY,
        "currentXY": {
          "x": x,
          "y": y
        }
      }
    };
  
    handleMouseDown(event: MouseEvent, config: any) {
      let transformedDataXY = this.mousemoveHandler(event, config);
  
      this.brushStart = transformedDataXY;
      this.brushEnd = transformedDataXY;
      this.isDragging = true;
  
      this.requestRedraw(config);
  
    }
  
    handleMouseMove(event: MouseEvent, config: any) {
        if (!this.isDragging || !this.brushStart) return;
        let transformedDataXY = this.mousemoveHandler(event, config);
        this.brushEnd = transformedDataXY;
        this.requestRedraw(config);
    }
  
    handleMouseUp(event: MouseEvent, config: any) {
        this.isDragging = false;
        this.brushStart = null;
        this.brushEnd = null;
        this.requestRedraw(config);
        this.post_processed_brushed(config);
        // Optionally, process the selection here
    }

    post_processed_brushed(config: any){
       let dragged_promptPoints = this.getDraggedData(config);
       if(this.brushed_callback !== null){
        this.brushed_callback(dragged_promptPoints);
       }
    }

    initBrushOnTopSvg(config: any){
      // Disable zoom
      // config.topSvg.on(".zoom", null);
      // Add event listeners to the SVG element using D3
      config.topSvg
      .on('mousedown', (e:any)=>{
        this.handleMouseDown(e, config)
      })
      .on('mousemove', (e:any)=>{
        this.handleMouseMove(e, config)}
      );
      d3.select(window).on('mouseup', (e:any) => {
        this.handleMouseUp(e, config)
      });
  }
    initBrushCallback(callback: any){
      this.brushed_callback = callback;
    }
    disableBrushOnTopSvg(config: any){
      config.topSvg
      .on('mousedown', null)
      .on('mousemove', null)
      ;
      d3.select(window).on('mouseup', null);
      this.clearBrushRect(config);
    }
  
  }
  
  class ContourManager{
    constructor(){
  
    }
    /**
     * Initialize the groups to draw elements in the SVG.
     */
    initSVGGroups = (config: any) => {
      const umapGroup = config.svg
        .append('g')
        .attr('class', 'umap-group')
        .attr(
          'transform',
          `translate(${config.svgPadding.left}, ${config.svgPadding.top})`
        );
  
      umapGroup
        .append('g')
        .attr('class', 'contour-group')
  



    };

    initGroupContours = (config: any) => {
          // Create group related structures if the data has groups
      if (config.gridData.groupGrids && config.gridData.groupNames) {
        const umapGroup = config.svg.select('g.umap-group');

        // Adjust the first contour's name
        // this.showContours = [];
        // this.showPoints = [];
        config.groupContours = [];

        for (let i = 0; i < config.groupNames.length; i++) {
          // Add groups to the control states
          // (Default is to show the first group only)
          // this.showContours.push(i === 0);
          // this.showPoints.push(i === 0);

          // Add contour elements for other groups
          const name = config.groupNames[i];
          umapGroup
            .append('g')
            .attr('class', `contour-group-generic contour-group-${name}`)
            .attr("opacity", 0.8)
            .classed('hidden', !config.showContours[i]);

          // Drw the group contour
          const curContour = this.drawGroupContour(config, name);
          if (curContour !== null) {
            config.groupContours.push(curContour);
          }
        }
      }
    }
  
      /**
     * Draw the KDE contour in the background.
     */
      drawContour = (config: any) => {
        if (config.gridData == null) {
          console.log('drawContour Grid data not initialized');
          return null;
        }
        let gridData = config.gridData;
        const contourGroup = config.svg
          .select('.contour-group')
          // Hide the total contour if the user specifies groups
          .style(
            'display',
            gridData.groupGrids !== undefined &&
            gridData.groupNames !== undefined
              ? 'none'
              : 'unset'
          );
    
        const gridData1D: number[] = [];
        for (const row of gridData.grid) {
          for (const item of row) {
            gridData1D.push(item);
          }
        }
    
        // Linear interpolate the levels to determine the thresholds
        const levels = config.layout.contourLevels;
        const thresholds: number[] = [];
        const minValue = Math.min(...gridData1D);
        const maxValue = Math.max(...gridData1D);
        const step = (maxValue - minValue) / levels;
        for (let i = 0; i < levels; i++) {
          thresholds.push(minValue + step * i);
        }
    
        let contours = d3
          .contours()
          .thresholds(thresholds)
          .size([gridData.grid.length, gridData.grid[0].length])(
          gridData1D
        );
    
        // Convert the scale of the generated paths
        const contourXScale = d3
          .scaleLinear()
          .domain([0, gridData.grid.length])
          .range(gridData.xRange);
    
        const contourYScale = d3
          .scaleLinear()
          .domain([0, gridData.grid[0].length])
          .range(gridData.yRange);
    
        contours = contours.map((item: any) => {
          item.coordinates = item.coordinates.map((coordinates: any) => {
            return coordinates.map((positions: any) => {
              return positions.map((point: any) => {
                return [
                  config.xScale(contourXScale(point[0])),
                  config.yScale(contourYScale(point[1]))
                ];
              });
            });
          });
          return item;
        });
    
        // Create a new blue interpolator based on d3.interpolateBlues
        // (starting from white here)
        const blueScale = d3.interpolateLab(
          '#ffffff',
          config.layout['groupColors'][0]
        );
        const colorScale = d3.scaleSequential(
          d3.extent(thresholds) as number[],
          (d: any) => blueScale(d / 1)
        );
    
        // Draw the contours
        contourGroup
          .selectAll('path')
          .data(contours.slice(1))
          .join('path')
          .attr('fill', (d:any) => colorScale(d.value))
          .attr('d', d3.geoPath());
  
  
        config.contours = contours;
      };

  /**
   * Draw the contour for other groups
   */
  drawGroupContour = (config: any, group: string) => {
    if (config.gridData == null || config.gridData.groupGrids === undefined) {
      console.error('Grid data not initialized');
      return null;
    }
    const contourGroup = config.svg.select(
      `.contour-group-${group}`
    );
    if(!config.gridData.groupGrids.hasOwnProperty(group)){
      contourGroup.selectAll("*").remove();
      return null;
    }

    

    const gridData1D: number[] = [];
    const grid = config.gridData.groupGrids[group];
    for (const row of grid) {
      for (const item of row) {
        gridData1D.push(item);
      }
    }

    // Linear interpolate the levels to determine the thresholds
    const levels = config.layout.contourLevels;
    const thresholds: number[] = [];
    const minValue = Math.min(...gridData1D);
    const maxValue = Math.max(...gridData1D);
    const step = (maxValue - minValue) / levels;
    for (let i = 0; i < levels; i++) {
      thresholds.push(minValue + step * i);
    }

    let contours = d3
      .contours()
      .thresholds(thresholds)
      .size([grid.length, grid[0].length])(gridData1D);

    // Convert the scale of the generated paths
    const contourXScale = d3
      .scaleLinear()
      .domain([0, grid.length])
      .range(config.gridData.xRange);

    const contourYScale = d3
      .scaleLinear()
      .domain([0, grid[0].length])
      .range(config.gridData.yRange);

    contours = contours.map((item: any) => {
      item.coordinates = item.coordinates.map((coordinates: any) => {
        return coordinates.map((positions: any) => {
          return positions.map((point: any) => {
            return [
              config.xScale(contourXScale(point[0])),
              config.yScale(contourYScale(point[1]))
            ];
          });
        });
      });
      return item;
    });

    // Create a new color interpolator
    // (starting from white here)
    const colorScaleInterpolator = d3.interpolateLab(
      '#ffffff',
      config.layout['groupContourColors'][config.groupNames?.indexOf(group) || 0]
    );
    const colorScale = d3.scaleSequential(
      d3.extent(thresholds) as number[],
      (d: any) => colorScaleInterpolator(d / 1)
    );

    // Draw the contours
    contourGroup
      .selectAll('path')
      .data(contours.slice(1))
      .join('path')
      .attr('fill', (d:any) => colorScale(d.value))
      .attr('d', d3.geoPath());

    return contours;
  };


  }

  class TopicGridManager{
    constructor(){
  
    }
    /**
   * Show the topic labels at different zoom scales.
   */
    public layoutTopicLabels(
        config: any,
        maxLabels: number | null = null,
        canSkipLayout = true
    ){
          RenderTopicLabelsUtils.layoutTopicLabels(config, maxLabels, canSkipLayout);

    }

    public initTopicData(config: any){
        RenderTopicLabelsUtils.initTopicData(config);
    }
  }
  const HOVER_RADIUS = 3;


class HoverManager{

  constructor(){

  }
  disableHoverEvent(config: any){
    config.topSvg.on('pointermove', null);
  }
  initHoverEvent(config: any){
     config.topSvg
      .on('pointermove', (e: any) => this.mousemoveHandler(e, config))
      .on('mouseleave', () => {
        this.highlightPoint(config, { point: undefined, animated: false });
      })

  }
  initHoverConfig(config: any){
    // Only need this part.
    this.initQuadtree(config);
    this.updateQuadtree(config);
    config.tooltipTop = document.querySelector('#popper-tooltip-top')!;
    console.log("config.tooltipTop", config.tooltipTop);

  }
  /**
   * Initialize the quadtree
   * @param xRange [xMin, xMax]
   * @param yRange [yMin, yMax]
   */
  initQuadtree = (
    config: any,
  ) => {
    // Initialize the data scales
    const xRange = config.gridData.xRange;
    const yRange = config.gridData.yRange;

    config.groupTimeTreeMap = new Map<
      number,
      Map<string, d3.Quadtree<any>>
    >();

    // Initialize the quadtree contains all the points (group = '', time = '')
    this.initTimeQuadtrees(config, xRange, yRange, -1, ['']);

    // Initialize group trees (these trees are special time trees with the
    // time key set to '')
    if (config.groupNames.length > 0) {
      for(let i = 0; i<config.groupNames.length; i++){
        this.initTimeQuadtrees(config, xRange, yRange, i, ['']);

      }
    }
  };
  initTimeQuadtrees = (
    config: any,
    xRange: [number, number],
    yRange: [number, number],
    groupID: number,
    times: string[]
  ) => {
    // Find the correct time tree map under the group level
    let curTimeTreeMap: any;
    let groupTimeTreeMap = config.groupTimeTreeMap;
    if (groupTimeTreeMap.has(groupID)) {
      curTimeTreeMap = groupTimeTreeMap.get(groupID)!;
    } else {
      curTimeTreeMap = new Map<string, d3.Quadtree<any>>();
      groupTimeTreeMap.set(groupID, curTimeTreeMap);
    }

    for (const time of times) {
      const curTree = d3
        .quadtree()
        .x((d: any) => d.x)
        .y((d: any) => d.y)
        .cover(xRange[0], yRange[0])
        .cover(xRange[1], yRange[1]);
      curTimeTreeMap.set(time, curTree);
    }
  };
  /**
   * Add new points to the quadtree
   * @param points New points
   */
  updateQuadtree = (config: any) => {
    // Add these points to the quadtree after sending them to the main thread
    let points = config.promptPoints;
    const allTree = config.groupTimeTreeMap.get(-1)!.get('')!;

    for (const point of points) {
      // Add the point to the tree containing all points
      allTree.add(point);

      // Add the point to the group tree regardless time
      if (point.groupID >= 0) {
        if (config.groupTimeTreeMap.has(point.groupID)) {
          const curTree = config.groupTimeTreeMap.get(point.groupID)!.get('')!;
          curTree.add(point);
        }
      }
    }
  };
  quadtreeSearch = (
    config: any,
    x: number,
    y: number,
    time: string = "",
    groupID: number = -1
  ) => {
    let groupTimeTreeMap = config.groupTimeTreeMap;
    if (groupTimeTreeMap.has(groupID)) {
      if (groupTimeTreeMap.get(groupID)!.has(time)) {
        const curTree = groupTimeTreeMap.get(groupID)!.get(time)!;
  
        const closestPoint = curTree.find(x, y);
        if (closestPoint === undefined) {
          return null;
        }
        return closestPoint;
      }
    }
    return null;
  };
  allTrue = (items: boolean[]) => items.reduce((a, b) => a && b);
  calculate_distance = (x1: number, y1: number, x2: number, y2:number)=>{
    return ((x1-x2)*(x1-x2) + (y1-y2)*(y1-y2));

  }
  quadtreeSeachGroups = (
    config: any,
    x: number,
    y: number
  ) => {
    if (config.groupNames) {
      if (this.allTrue(config.showPoints)) {
        let closest_points = this.quadtreeSearch(config, x, y);
        return closest_points;
      } else {
        let closest_points = null;
        let closest_points_dis = +Infinity;

        for (let i = 0; i < config.showPoints.length; i++) {
          if (config.showPoints[i] === true) {
            let group_closest_points = this.quadtreeSearch(config, x, y, "", i);
            if(group_closest_points!==null){
              let dis = this.calculate_distance(x, y, group_closest_points.x, group_closest_points.y)
              if(dis < closest_points_dis){
                closest_points_dis = dis;
                closest_points = group_closest_points;
              }
            }

          }
        }
        return closest_points;
      }
    }
  }
    /**
   * Start a query for mouse overed point
   * @param x Mouse x coordinate
   * @param y Mouse y coordinate
   */
    mouseoverPoint = (config: any, x: number, y: number) => {
      // Invert to the stage scale => invert to the data scale
      const dataX = config.xScale.invert(config.curZoomTransform.invertX(x));
      const dataY = config.yScale.invert(config.curZoomTransform.invertY(y));
  
      let closest_points = this.quadtreeSeachGroups(config, dataX, dataY);
      if(closest_points !== null){
        // console.log("closest_points", closest_points);
        this.searchDataFinished(config, closest_points);
      }
    };
  
    /**
     * Event handler for mousemove
     * @param e Mouse event
     */
    mousemoveHandler = (e: any, config: any) => {
      // Show tooltip when mouse over a data point on canvas
      // We need to use color picking to figure out which point is hovered over
      const x = e.offsetX;
      const y = e.offsetY;
      config.lastMouseClientPosition = { x: x, y: y };
      // Show point highlight
      // if (anyTrue(this.showPoints) && !this.hideHighlights) {
        this.mouseoverPoint(config, x, y);
      // }
  
      // Show labels
      // if (!this.hideHighlights) {
      //  this.mouseoverLabel(x, y);
      // }
    };
  searchDataFinished(config: any, point:any){

    // Embedding.tsx Line 1131
    // case 'finishQuadtreeSearch': {
      if (config.lastMouseClientPosition === null) {
        config.log('lastMouseClientPosition is null');
        return;
      }
      // Check if the closest point is relatively close to the mouse
      const closestPoint = structuredClone(
          point
      );
      const screenPointX = config.curZoomTransform.applyX(
        config.xScale(closestPoint.x)
      );
      const screenPointY = config.curZoomTransform.applyY(
        config.yScale(closestPoint.y)
      );

      const distance = Math.max(
        Math.abs(screenPointX - config.lastMouseClientPosition.x),
        Math.abs(screenPointY - config.lastMouseClientPosition.y)
      );

      const highlightRadius = Math.max(
        10 / config.curZoomTransform.k,
        (config.layout.scatterDotRadius *
          Math.exp(Math.log(config.curZoomTransform.k) * 0.55)) /
          config.curZoomTransform.k
      );

      // Highlight the point if it is close enough to the mouse
      const curHoverRadius = Math.max(
        HOVER_RADIUS,
        highlightRadius * config.curZoomTransform.k
      );

      if (distance <= curHoverRadius) {
        this.highlightPoint(config, { point: closestPoint, animated: false });
      } else {
        this.highlightPoint(config, { point: undefined, animated: false });
      }
    // break;
    // }
  }


    /**
     * Update the popper tooltip for the highlighted prompt point
     * @param tooltip Tooltip element
     * @param anchor Anchor point for the tooltip
     * @param point The prompt point
     */
    public updatePopperTooltip = (
      tooltip: HTMLElement,
      anchor: HTMLElement,
      text: string,
      placement: 'bottom' | 'left' | 'top' | 'right'
    ) => {
      // console.log("updatePopeerTooltip", tooltip, anchor, text, placement);
      if(tooltip === null){
        return;
      }
      // Truncate the text if it is too long
      if (text.length > 300) {
        text = text.slice(0, 300);
        text = text.slice(0, text.lastIndexOf(' '));
        text = text.concat('...');
      }
      // console.log("updatePopeerTooltip DEBUG 1");

      const arrowElement = tooltip.querySelector('.popper-arrow')! as HTMLElement;
      const contentElement = tooltip.querySelector(
        '.popper-content'
      )! as HTMLElement;
      const htmlText = text.replace(/\n/g, "<br>");

      contentElement.innerHTML = htmlText;

      computePosition(anchor, tooltip, {
        placement: placement,
        middleware: [offset(6), flip(), shift(), arrow({ element: arrowElement })]
      }).then(({ x, y, placement, middlewareData }) => {
        tooltip.style.left = `${x}px`;
        tooltip.style.top = `${y}px`;

        const { x: arrowX, y: arrowY } = middlewareData.arrow!;
        let staticSide: 'bottom' | 'left' | 'top' | 'right' = 'bottom';
        if (placement.includes('top')) staticSide = 'bottom';
        if (placement.includes('right')) staticSide = 'left';
        if (placement.includes('bottom')) staticSide = 'top';
        if (placement.includes('left')) staticSide = 'right';

        arrowElement.style.left = arrowX ? `${arrowX}px` : '';
        arrowElement.style.top = arrowY ? `${arrowY}px` : '';
        arrowElement.style.right = '';
        arrowElement.style.bottom = '';
        arrowElement.style[staticSide] = '-4px';
      });
      // console.log("updatePopeerTooltip DEBUG 2");

    };


    public constructTooltipText(point: any, config: any){
      let text = "";
      let groupNames = ["Attack Fail", "Attack Success", "Past Jailbreak Prompt"];
      text += "Type: " + groupNames[point.groupID] + "\n";
      let groupId = point.groupID;
      if(groupId == 2){
        text += "JailbreakID: " + point.pastjailbreakprompt_id + "\n";
        text += "Text: " + point.pastjailbreakprompt_text + "\n";
      }else{
        text += "InstanceID: " + point.instances_id + "\n";
        text += "Text: " + point.instances_text + "\n";
      }
      return text;
    }

      /**
       * Highlight the point where the user hovers over
       * @param point The point that user hovers over
       */
      public highlightPoint(
        config: any,
        args: any
      ) {
        const { point, animated } = args;
        // if (!anyTrue(this.showPoints)) return;
        if (point === config.hoverPoint) return;
        // if (this.hideHighlights) return;

        // Draw the point on the top svg
        const group = config.contentSvg.select('g.top-content g.highlights');
        const oldHighlightPoint = group.select(
          'circle.highlight-point'
        );

        // Hovering empty space
        if (point === undefined) {
          // Clear the highlight and tooltip in a short delay
          config.hoverPoint = null;
          oldHighlightPoint.remove();
          config.tooltipTop.classList.add('hidden');

          return;
        }

        // Hovering over a point
        config.hoverPoint = point;

        const highlightRadius = Math.max(
          10 / config.curZoomTransform.k,
          (config.curPointWidth * Math.exp(Math.log(config.curZoomTransform.k) * 0.55)) /
          config.curZoomTransform.k
        );
        const highlightStroke = 1.2 / config.curZoomTransform.k;
        let curHighlightPoint: d3.Selection<
          SVGCircleElement,
          unknown,
          null,
          undefined
        >;

        // There is no point highlighted yet
        if (oldHighlightPoint.empty()) {
          curHighlightPoint = group
            .append('circle')
            .attr('class', 'highlight-point')
            .attr('cx', config.xScale(point.x))
            .attr('cy', config.yScale(point.y))
            .attr('r', highlightRadius)
            .style('stroke-width', highlightStroke);
        } else {
          // There has been a highlighted point already
          curHighlightPoint = oldHighlightPoint;

            curHighlightPoint
              .attr('cx', config.xScale(point.x))
              .attr('cy', config.yScale(point.y))
              .attr('r', highlightRadius)
              .style('stroke-width', highlightStroke);

            this.updatePopperTooltip(
              config.tooltipTop,
              curHighlightPoint.node()! as unknown as HTMLElement,
              this.constructTooltipText(point, config),
              'top'
            );
        }
        config.tooltipTop.classList.remove('hidden');

    }

  }


 export {
    PointDrawer,
    DataInitalize,
    ZoomManager,
    BrushManager,
    ContourManager,
    TopicGridManager,
    HoverManager
 }