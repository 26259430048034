import { DATASET_ID_CHANGE, DIALOGUE_ID_CHANGE, 
  FILTER_ID_CHANGE, SELECTED_CLUSTER_CHANGE, SELECTED_INSTANCES_CHANGE, PROJ_LAYOUT_CONFIG_CHANGE,
  TURN_ID_CHANGE, SELECTED_PAST_JAILBREAK_PROMPT_INDEX_CHANGE, HIGHLIGHTED_QUERY_CHANGE, 
  TRANSLATED_TEXT_CHANGE, PROMPT_BOOKMARK} from '../constants';
import {StoreState} from '../types';
const initial_state : StoreState = {
    dialogue_id :  -1,
    filter_id : null,
    dataset_id:  null,
    selected_cluster: [],
    selected_instance: [],
    proj_layout_config: null,
    selected_pastjailbreakprompt_index: 0,
    turn_id: 0,
    highlighted_query: {},
    translated_text: "",
    prompt_collection: [],
    selected_type: "N/A"
}
// 处理并返回 state 
export default (state = initial_state, action:any): StoreState => {
    switch (action.type) {
      case DIALOGUE_ID_CHANGE:
        // Change dialogue id
        return {
          ...state,
          dialogue_id: action.dialogue_id,
        };
        case FILTER_ID_CHANGE:
        // Change filter id
        return {
          ...state,
          filter_id: action.filter_id,
        };
        case DATASET_ID_CHANGE:
        // Change dataset id
        return {
          ...state,
          dataset_id: action.dataset_id,
        };
        case TURN_ID_CHANGE:
        // Change dataset id
        return {
          ...state,
          turn_id: action.turn_id,
        };
        case SELECTED_CLUSTER_CHANGE:
          return {
            ...state,
            selected_cluster: action.selected_cluster
          }
        case SELECTED_INSTANCES_CHANGE:
          return {
            ...state,
            selected_instance: action.selected_instance
          }
        case PROJ_LAYOUT_CONFIG_CHANGE:
          return {
            ...state,
            proj_layout_config: action.proj_layout_config
          }
        case SELECTED_PAST_JAILBREAK_PROMPT_INDEX_CHANGE:
          return {
            ...state,
            selected_pastjailbreakprompt_index: action.selected_pastjailbreakprompt_index
          }
        case HIGHLIGHTED_QUERY_CHANGE:
          return {
            ...state,
            highlighted_query: action.highlighted_query
          }
        case TRANSLATED_TEXT_CHANGE:
          return {
            ...state,
            translated_text: action.translated_text
          }
        case PROMPT_BOOKMARK:
          state.prompt_collection.push(action.prompt_config)
          console.log("promptcollection", state.prompt_collection);
          return {
            ...state,
            prompt_collection: structuredClone(state.prompt_collection)
          }
        
      default:
        return state
    }
}


//import { DECREMENT, INCREMENT } from '../constants';

