
// Define Change Model Constant
export const DIALOGUE_ID_CHANGE = "DIALOGUE_ID_CHANGE";
export type DIALOGUE_ID_CHANGE = typeof DIALOGUE_ID_CHANGE;

export const FILTER_ID_CHANGE = "FILTER_ID_CHANGE";
export type FILTER_ID_CHANGE = typeof FILTER_ID_CHANGE;

export const DATASET_ID_CHANGE = "DATASET_ID_CHANGE";
export type DATASET_ID_CHANGE = typeof DATASET_ID_CHANGE;

export const TURN_ID_CHANGE = "TURN_ID_CHANGE";
export type TURN_ID_CHANGE = typeof TURN_ID_CHANGE;

export const SELECTED_CLUSTER_CHANGE = "SELECTED_CLUSTER_CHANGE";
export type SELECTED_CLUSTER_CHANGE = typeof SELECTED_CLUSTER_CHANGE;

export const SELECTED_INSTANCES_CHANGE = "SELECTED_INSTANCES_CHANGE";
export type SELECTED_INSTANCES_CHANGE  = typeof SELECTED_INSTANCES_CHANGE;

export const PROJ_LAYOUT_CONFIG_CHANGE = "PROJ_LAYOUT_CONFIG_CHANGE";
export type PROJ_LAYOUT_CONFIG_CHANGE = typeof PROJ_LAYOUT_CONFIG_CHANGE;

export const SELECTED_PAST_JAILBREAK_PROMPT_INDEX_CHANGE = "SELECTED_PAST_JAILBREAK_PROMPT_INDEX_CHANGE";
export type SELECTED_PAST_JAILBREAK_PROMPT_INDEX_CHANGE = typeof SELECTED_PAST_JAILBREAK_PROMPT_INDEX_CHANGE;

export const HIGHLIGHTED_QUERY_CHANGE = "HIGHLIGHTED_QUERY_CHANGE";
export type HIGHLIGHTED_QUERY_CHANGE = typeof HIGHLIGHTED_QUERY_CHANGE;

export const TRANSLATED_TEXT_CHANGE = "TRANSLATED_TEXT_CHANGE";
export type TRANSLATED_TEXT_CHANGE = typeof TRANSLATED_TEXT_CHANGE;

export const PROMPT_BOOKMARK = "PROMPT_BOOKMARK";
export type PROMPT_BOOKMARK = typeof PROMPT_BOOKMARK;