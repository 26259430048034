
import * as React from "react";
import { Button, message, Tag, Input } from 'antd';
import Chat, { Bubble, useMessages } from '@chatui/core';
import './ChatGPTPanel.css'
import '@chatui/core/dist/index.css';
import { getMetaData, getDialogueInfo } from "../../../service/dataService";
import { set_updateDialogueInfo } from "../../../helper/callbackHook";
// Ref: https://github.com/alibaba/ChatUI
export interface IProps {
    height: number,
    width: number,
    dialogue_id: number
}
export interface IState {
    dialogue_results: any,
    messages: any,
    ID: any,
    model_name: any,
    turns: any,
    language: any,
    typing: boolean,
    mode: any
}
const { TextArea } = Input;

export default class ChatGPTPanel extends React.Component<IProps, IState>{

    constructor(props:IProps) {
        super(props);        
        this.state = {
            dialogue_results: {},
            messages: [],
            ID: 0,
            model_name: "",
            turns: 0,
            language: "",
            typing: false,
            mode: 1 // 1 ---> Chat, 2 ---> Text
        }
        this.appendMsg = this.appendMsg.bind(this);
        this.handleSend = this.handleSend.bind(this);
        this.clearChat = this.clearChat.bind(this);
        // this.handleSend = this.handleSend.bind(this);
        // Flow:
        // 1. Constructor
        // 2. componentWillMount()
        // 3. render()
        // 4. componentDidMount()
        // If props update:
        // 4.1 componentWillReceiveProps(nextProps : IProps), then goto 5.
        // If States update
        // 5. shouldComponentUpdate() if return false, then no rerendering.
        // 6. if True, then componentWillUpdate
        // 7. render()
        // 8. componentDidUpdate
        // If Unmount, then componentWillUnmount()
    }

    public componentWillMount(): void {
        // this.props.changeDebugCodeCallback(this.debugCode);
    }
    public componentDidMount(){
        /// Testing APIs
        set_updateDialogueInfo(this.updateDialogueInfo);
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState) {
      if(prevProps.dialogue_id != this.props.dialogue_id){
        this.updateDialogueInfo();

      }
    }
    updateStateWithDialogueInfo = (dialogue_info: any) => {
       let conversation = dialogue_info["conversation"];
       let messages: any = [];
       for(let i = 0; i<conversation.length; i++){
          let oneconversation = conversation[i];
          if(oneconversation["role"] == "user"){
            messages.push({
                role: 'user',
                type: 'text',
                content: { text: oneconversation["content"] },
                position: 'right',
            })
          }else if(oneconversation["role"] == "assistant"){
            messages.push({
              role: 'assistant',
              type: 'text',
              content: { text: oneconversation["content"] },
          })
          }
       }

       let ID = dialogue_info["ID"];
       let model_name = dialogue_info["model"];
       let turn = dialogue_info["turn"];
       let language = dialogue_info["language"];
       this.setState({
        dialogue_results: dialogue_info,
        messages: messages,
        ID: ID,
        model_name: model_name,
         turns: turn,
         language: language
       })
    }
    updateDialogueInfo = () => {
        getDialogueInfo(this.props.dialogue_id).then((results:any)=>{
          if(results["success"]){
            let dialogue_info = results["dialogue_info"];
            this.updateStateWithDialogueInfo(dialogue_info);
          }
          console.log("getDialogueInfo", results)
        }).catch((reason:any)=>{
          message.error(""+reason);
        });
    }
     setInitialMessages = (messages: any) => {
      this.setState({
        messages: messages,
        typing: false
      })
    }
    getInitialMessages = () => {
      return {
        "messages": this.state.messages
      }
    }
    public appendMsg(message: any){
        this.state.messages.push(message);
        console.log("nowmessages", this.state.messages);
        this.setState({
            messages: this.state.messages,
            typing: false
            }
        )
    }
    public setTyping(typing: boolean){
        this.setState({
            typing: typing
        })
    }



    public handleSend(type: any, val: any) {

      }
    
      public renderMessageContent(msg: any) {
        const { content } = msg;
        return <Bubble content={content.text} />;
      }
      public clearChat(){
        this.setState({
            "messages": [],
            
        })

      }
      showDatasetModal = () => {
      }
      showCodeVisModal = () => {
      }
      switchMode = (mode: number) => {
        this.setState({
          mode: mode
        })
      }
      public getText(messages: any){
        let str = "";
        for(let i = 0; i<messages.length; i++){
          let item = messages[i];
          str += "Index: " + i + " Role: " + item["role"] + "\n" + "Content: " + "\n" + item["content"]["text"] + "\n\n"
        }
        return str;
      }
    public render() {
        // const { messages, appendMsg, setTyping } = useMessages([]);
        let generateButton = () => {
          if(this.state.mode == 1){
            return <Button onClick={()=>this.switchMode(2)} size="small">Text</Button>
          }else{
            return <Button onClick={()=>this.switchMode(1)} size="small">Chat</Button>
          }
        }
        let generatePanel = () => {
          if(this.state.mode == 1){
            return <Chat
                messages={this.state.messages}
                renderMessageContent={this.renderMessageContent}
                placeholder=""
                onSend={this.handleSend}
                locale={"en-US"}
            />
          }else{
            return <TextArea rows={30} value={this.getText(this.state.messages)} />

          }
        }
        return (
            <div style={{width: "100%", height:""+(this.props.height - 10)+"px", overflowX: "hidden"}}>
                <div className="ViewTitle">Chat Panel
                    <div style={{float:'right'}}>
                      ID: <Tag>{this.state.ID+1}</Tag> 
                      Model Name: <Tag>{this.state.model_name}</Tag> 
                      Turns: <Tag>{this.state.turns}</Tag> 
                      Lang: <Tag>{this.state.language}</Tag>
                      {generateButton()}
                      
                    </div>
                </div>
                <div className="ViewBox" style={{"height": ""+(this.props.height - 30)+"px", "width": "100%"}}>
                    {generatePanel()}
                </div>
            </div>
        );

    }
}
