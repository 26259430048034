import InstanceView from '../components/DataRuns/InstanceView/InstanceView'
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

//import { decrement, increment } from '../actions';
import { StoreState } from '../types';
import { changeDialogueID, promptBookmark } from '../actions';


// 将 reducer 中的状态插入到组件的 props 中
const mapStateToProps = (state: StoreState) => ({
    dialogue_id: state.dialogue_id,
    selected_instance: state.selected_instance,
    highlighted_query: state.highlighted_query

})

// 将 对应action 插入到组件的 props 中
const mapDispatchToProps = (dispatch: Dispatch) => ({
    changeDialogueID: (dialogueID: number) => dispatch(changeDialogueID(dialogueID)),
    promptBookmark: (prompt_config: any) => dispatch(promptBookmark(prompt_config))
})

// 使用 connect 高阶组件对 Counter 进行包裹
export default connect(mapStateToProps, mapDispatchToProps)(InstanceView);



