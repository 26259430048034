import PastPrompt from '../components/DataRuns/RelationView/PastPrompt'
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

//import { decrement, increment } from '../actions';
import { StoreState } from '../types';
import {changeHighlightedQuery, changePastJailbreakPromptIndex} from '../actions';


// 将 reducer 中的状态插入到组件的 props 中
const mapStateToProps = (state: StoreState) => ({
    dialogue_id: state.dialogue_id,
    dataset_id: state.dataset_id,
    turn_id: state.turn_id
})

// 将 对应action 插入到组件的 props 中
const mapDispatchToProps = (dispatch: Dispatch) => ({
    changeHighlightQuery: (highlight_query: any) => dispatch(changeHighlightedQuery(highlight_query))
})

// 使用 connect 高阶组件对 Counter 进行包裹
export default connect(mapStateToProps, mapDispatchToProps)(PastPrompt);



