import * as React from 'react';
import FilterSelectorContainer from '../../../container/FilterSelectorContainer'
import DescriptionContainer from '../../../container/DescriptionContainer'
import {Row} from 'antd';

export interface ControlPanelProps {
    // dataset_id: number | null,   
}

export interface ControlPanelState {}

export default class ControlPanel extends React.Component<ControlPanelProps, ControlPanelState> {
    constructor(props: ControlPanelProps) {
        super(props);
        this.state = {
            
        };
    }
    public render() {
        return (
            <div>
            <div className="ViewTitle">Filters Panel</div>
            <div className="ViewBox">
                <Row>
                    <FilterSelectorContainer />
                </Row>
                {/* <Row>
                    <DescriptionContainer />
                </Row> */}
            </div>
            </div>
            
        );
    }
}
