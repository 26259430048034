
import * as React from "react";
import { mat4 } from 'gl-matrix';
import regl from 'regl';
import { RenderingManager } from "./RenderingManager";
import "./ProjectionPlaneWebGL.css";
export interface IProps {
    cluster_results: any,
    changeSelectedCluster: any,
    config: any
    proj_layout_config: any
}

export interface IState {

}



export default class ProjectionPlaneWebGL extends React.Component<IProps, IState>{
    private projectionRef: React.RefObject<HTMLDivElement>;
    private canvasRef: React.RefObject<HTMLCanvasElement>;
    public rendering_manager: any = null;
    public component_name: string = "#embedding";
    constructor(props: IProps) {
        super(props);
        this.projectionRef = React.createRef<HTMLDivElement>();
        this.canvasRef = React.createRef<HTMLCanvasElement>();
        this.rendering_manager = new RenderingManager();
        this.state = {
            dimensions: { width: 0, height: 0 },
        }

    }

    draggedCallback = (selected_promptPoints: any) => {
        console.log("draggedCallback", selected_promptPoints);
        this.props.changeSelectedCluster(selected_promptPoints);
    }

    public componentDidMount(): void {
        console.log("componentDidMount props", this.props);

        this.initAll();
    }   
    initAll = () => {
        let brushmode = this.props.config.brushmode;
        let proj_layout_config = this.props.proj_layout_config;

        this.rendering_manager.simple_execute_rendering(this.component_name, this.props.cluster_results);
        this.rendering_manager.changeBrushMode(brushmode);
        this.rendering_manager.changeProjLayoutConfig(proj_layout_config);
        this.rendering_manager.initDraggedCallback(this.draggedCallback);
    }
    public componentDidUpdate(prevProps: any, prevState: any){
        console.log("componentDidUpdate props", this.props);
        if(this.props.config.width !== prevProps.config.width || this.props.config.height !== prevProps.config.height){
            console.log("different width and height");
            this.initAll();
            return ;
        }
        if(prevProps.config !== this.props.config){
            let brushmode = this.props.config.brushmode;
            this.rendering_manager.changeBrushMode(brushmode);
        }
        if(prevProps.proj_layout_config !== this.props.proj_layout_config){
            let proj_layout_config = this.props.proj_layout_config;
            this.rendering_manager.changeProjLayoutConfig(proj_layout_config);
        }
        if(prevProps.cluster_results !== this.props.cluster_results){
            this.initAll();
            // this.rendering_manager.simple_execute_rendering(this.component_name, this.props.cluster_results)

        }

        // simple_execute_rendering("#embedding", this.props.cluster_results)
    }
    public render() {
        return (

            <div style={{ width: "100%", height: "100%"}} ref={this.projectionRef} id='projectionwebgl'>
                

                <div id="embedding">
                    <svg id="top-svg"></svg>
                    <svg id="legend-svg"></svg>
                    <svg id="brush-svg"></svg>
                    <svg id="content-svg"></svg>
                    <canvas id="embedding-canvas"></canvas>
                    <svg id="embedding-svg"></svg>
                </div>
            </div>
        );


    }
}

