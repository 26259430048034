import InstanceChart from '../components/DataRuns/InstanceView/InstanceChart'
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

//import { decrement, increment } from '../actions';
import { StoreState } from '../types';

import { changeTurnID } from '../actions';

// 将 reducer 中的状态插入到组件的 props 中
const mapStateToProps = (state: StoreState) => ({
    dialogue_id: state.dialogue_id,
    dataset_id: state.dataset_id,
    turn_id: state.turn_id,
    highlighted_query: state.highlighted_query
})

// 将 对应action 插入到组件的 props 中
const mapDispatchToProps = (dispatch: Dispatch) => ({
    setCurrentTurn: (turn_id: number) => dispatch(changeTurnID(turn_id)),
})

// 使用 connect 高阶组件对 Counter 进行包裹
export default connect(mapStateToProps, mapDispatchToProps)(InstanceChart);



